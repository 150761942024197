import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import DetectionList from './DetectionList';

import { Detection, ViewDetection, EdgeProcessConfig } from '../types';

interface ViewDetectionDetailProps {
	view: ViewDetection;
  processConfigs: { [id: number]: EdgeProcessConfig };
  onExpanded?: (d: Detection, expanded: boolean) => void;
}

const ViewDetectionDetail: React.FC<ViewDetectionDetailProps> = ({ view, processConfigs, onExpanded }) => {
	return (
		<Card
			sx={{
				width: '100%'
			}}
		>   
			<CardHeader
				title={view.name}
			/>
			<CardContent>
        <DetectionList detections={view.detections} onExpanded={onExpanded} has_raised_axle_model={view.has_raised_axle_model} measurement_scale={view.measurement_scale} display_columns={view.display_columns} processConfigs={processConfigs} />
			</CardContent>
		</Card>
	 );
};

export default ViewDetectionDetail;

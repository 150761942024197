import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getTrafficFlows } from '../utils/api';
import { TrafficFlowSummary } from '../types';

interface TrafficFlowSelectProps {
	site_id: number;
}

const TrafficFlowSelect: React.FC<TextFieldProps & TrafficFlowSelectProps> = ({ site_id, ...props }) => {
	const { data } = useQuery<TrafficFlowSummary[], Error>(['traffic_flows', site_id], () => getTrafficFlows(site_id));

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Traffic Flow" {...props}>
			{data.map((traffic_flow) => (
				<MenuItem key={traffic_flow.id} value={traffic_flow.id}>
					{traffic_flow.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default TrafficFlowSelect;

import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getModels } from '../utils/api';

const ModelSelect: React.FC<TextFieldProps> = (props: TextFieldProps) => {
	const { data } = useQuery('models', getModels);

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="ML Model" variant="standard" {...props}>
			{data.map((model) => (
				<MenuItem key={model.id} value={model.id}>
					{model.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default ModelSelect;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Header from '../views/Header';

interface Props {
	message?: string;
}

const ErrorPage: React.FC<Props> = ({ message }) => {
	return (
		<React.Fragment>
			<Header>Error</Header>
			<Box
				sx={{ 
					display: 'flex',
					'& > :not(style)': {
						m: 1,
						flexGrow: 1
					}
				}}
			>
			  <Typography>{message ?? 'There has been an error loading this page.'}</Typography>
			</Box>
    </React.Fragment>
	);
};

export default ErrorPage;

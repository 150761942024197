import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getSoftwareVersions } from '../utils/api';


const SoftwareVersionSelect: React.FC<TextFieldProps> = (props: TextFieldProps) => {
	const { data } = useQuery('software-versions', getSoftwareVersions);

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Software Version" variant="standard" {...props}>
			{data.map((version) => (
				<MenuItem key={version.id} value={version.id}>
					{version.version}
				</MenuItem>
			))}
		</TextField>
	);
};

export default SoftwareVersionSelect;

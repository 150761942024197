import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import CameraConfigDisplay from './CameraConfigDisplay';

import { getCameraConfigs } from '../utils/api';

interface CameraConfigSelectProps {
	camera_model_id: number;
}

const CameraConfigSelect: React.FC<TextFieldProps & CameraConfigSelectProps> = (props: TextFieldProps & CameraConfigSelectProps) => {
	const { data } = useQuery('camera-configs', getCameraConfigs);

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Config" variant="standard" {...props}>
			{data.map((config) => (
				<MenuItem key={config.id} value={config.id}>
					<CameraConfigDisplay config={config} />
				</MenuItem>
			))}
		</TextField>
	);
};

export default CameraConfigSelect;

import React from 'react';
import Box from '@mui/material/Box';
import { useQuery } from 'react-query';

import Header from '../views/Header';
import ArchiveView from '../views/ArchiveView';
import RecordingsView from '../views/RecordingsView';
import AuditView from '../views/AuditView';
import ProductionView from '../views/ProductionView';
import ComparisonView from '../views/ComparisonView';
import LiveView from '../views/LiveView';
import TrafficFlowView from '../views/TrafficFlowView';
import TrafficFlowArchiveView from '../views/TrafficFlowArchiveView';
import SetupView from '../views/SetupView';
import SiteViewToggle from '../components/SiteViewToggle';

import { Site, SiteView, EdgeProcessConfig } from '../types';
import { getProcessConfigsBySite } from '../utils/api';

interface Props {
	site: Site;
	siteView: SiteView;
}

const SitePage: React.FC<Props> = ({ site, siteView }) => {
  const enabled = ['live', 'archive', 'audit', 'production'].includes(siteView);
  const { data: configs } = useQuery<EdgeProcessConfig[], Error>(['site-config', site.id], () => getProcessConfigsBySite(site.id), { enabled } );
  const processConfigs: {[process_id: number]: EdgeProcessConfig } = (configs ?? []).reduce((pc, c) => ({ ...pc, [c.id]: c }), {});
	return (
		<React.Fragment>
			<Header>
        Site: {site.name}
        <SiteViewToggle siteId={site.id} siteType={site.type} siteView={siteView} />
      </Header>
			<Box
				sx={{
					display: 'flex',
          flexWrap: 'wrap',
					alignItems: 'start',
					'& > : not(style)': {
						m: 1
					}
				}}>
				{siteView === 'live' && <LiveView siteId={site.id} processConfigs={processConfigs} />}
				{siteView === 'production' && <ProductionView siteId={site.id} timezone={site.timezone} processConfigs={processConfigs} />}
				{siteView === 'archive' && <ArchiveView siteId={site.id} processConfigs={processConfigs} />}
				{siteView === 'recordings' && <RecordingsView siteId={site.id} processConfigs={processConfigs} />}
				{siteView === 'audit' && <AuditView site={site} tenantName={site.tenant_name} processConfigs={processConfigs} />}
				{siteView === 'traffic_flow' && <TrafficFlowView siteId={site.id} />}
				{siteView === 'traffic_flow_archive' && <TrafficFlowArchiveView siteId={site.id} />}
				{siteView === 'comparison' && <ComparisonView siteId={site.id} />}
        {siteView === 'setup' && <SetupView siteId={site.id} />}
			</Box>
		</React.Fragment>
	);
};

export default SitePage;

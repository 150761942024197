import React, { useState } from 'react';
import { Card, CardHeader, CardContent, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { Detection } from '../types';
import VehicleStats from './VehicleStats';
import VehicleStatsGraph from './VehicleStatsGraph';

interface Props {
  detection: Detection;
}

const ToggleVehicleStats : React.FC<Props> = ({ detection }) => {
  const [showGraph, setShowGraph] = useState(false);
  const toggleView = () => setShowGraph(!showGraph);

  return(
    <Card elevation={0}>
      <CardHeader
        style={{
          height: "13px",
          marginBottom: "0px"
        }}
        align="center"
        title={<span>Vehicle Detection Statistics</span>}
      />
      <CardContent style={{  height: '100%', paddingTop: '0px' }}>
        <ToggleButtonGroup
          value={showGraph ? "graph" : "table"}
          exclusive
          onChange={toggleView}
          sx={{
            backgroundColor: 'white'
          }}
        >
          <ToggleButton value="table">Numeric</ToggleButton>
          <ToggleButton value="graph">Graph</ToggleButton>
        </ToggleButtonGroup>

        {showGraph ? <VehicleStatsGraph detection={detection}/> : <VehicleStats detection={detection}/>}
      </CardContent>
    </Card>
  );
};

export default ToggleVehicleStats;

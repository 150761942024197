import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DetectionObservation, MatchType, VehicleType, View } from '../../types';
import { isMatched, isUnverified, isMissed } from '../../utils';
import { isSuperuser } from '../../utils/auth';

import { COLOR_UNVERIFIED_DETECTION, COLOR_NO_DETECTION, COLOR_NO_OBSERVATION, COLOR_MISSED_DETECTION } from '../../config';

interface Props {
  tenantName: string;
  matches: DetectionObservation[];
  view?: View;
}
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
// Rewrite the getTotalMissedAxleCountObserved() where the number of axles is stored in Observation.num_tires
const vt: (m: DetectionObservation) => VehicleType = m => !!m.detection ? (m.detection.verified_vehicle_class ?? m.detection.vehicle_class) as VehicleType : 'Vehicle';
const nt: (m: DetectionObservation) => number = m => !!m.detection ? (m.detection.verified_num_tires ?? Math.max(m.detection.num_tires, 2)) : (!!m.observation ? m.observation.num_tires : 0);
const nt_missed: (m: DetectionObservation) => number = m => !!m.detection && !!m.observation && !!m.detection.verified_num_tires ? (m.detection.verified_num_tires > m.observation.num_tires ? m.detection.verified_num_tires - m.observation.num_tires: 0): 0;
const nt_missed_observed: (m: DetectionObservation) => number = m => !!m.observation && !!m.detection && !!m.observation.num_tires ? (m.observation.num_tires > m.detection.num_tires ?(m.observation.num_tires - m.detection.num_tires): 0): 0;
const nt_observations: (m: DetectionObservation) => number = m => !!m.observation ? m.observation.num_tires : 0;
// nt_detections = Total number of axles
const nt_detections :  (m: DetectionObservation) => number = m => !!m.detection ?(m.detection.verified_num_tires ?? m.detection.num_tires)  : 0;
// nrt = Number of Raised Axles
const nrt: (m: DetectionObservation) => number = m => !!m.detection ? (m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires) : 0;
// nrtml = Number of Raised Axles (ML)
const nrtml: (m: DetectionObservation) => number = m => !!m.detection ? (m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires_ml ?? 0) : 0;
export const mt: (m: DetectionObservation) => MatchType = m => !!m.detection ? (!!m.observation ? (nt(m) === m.observation.num_tires ? 'Match' : 'Mismatch') : 'Detection') : 'Observation';

const getVehicleCount: (vehicleMatch: boolean, m: DetectionObservation[], n: number, t?: VehicleType, onlyObservations?: boolean) => number = (vehicleMatch, m, n, t, onlyObservations) => onlyObservations ? (m.filter(x => nt_observations(x) === n).length) : (vehicleMatch ? (m.filter(x => nt(x) === n && vt(x) === (t ?? vt(x))).length) : (m.filter(x => nt(x) === n).length));

const getTotalVehicleCount: (vehicleMatch: boolean, m: DetectionObservation[], t?: VehicleType, onlyObservations?: boolean) => number = (vehicleMatch, m, t, onlyObservations) => onlyObservations ? (m.filter(x => !!x.observation).length) : (vehicleMatch ? (m.filter(x => vt(x) === (t ?? vt(x))).length) : (m.length));


export const getTotalAxleCount: (vehicleMatch: boolean, m: DetectionObservation[], t?: VehicleType, onlyObserations?: boolean) => number = (vehicleMatch, m, t, onlyObserations) => vehicleMatch ? (m.filter(x => vt(x) === (t ?? vt(x))).reduce((t, r) => t + nt(r), 0)) : (onlyObserations ? (m.reduce((t, r) => t + nt_observations(r), 0)): (m.reduce((t, r) => t + nt(r), 0)));
export const getAxleCount: (vehicleMatch: boolean, m: DetectionObservation[], n: number, t?: VehicleType, onlyObserations?: boolean) => number = (vehicleMatch, m, n, t, onlyObserations) => vehicleMatch ? (m.filter(x => nt(x) === n && vt(x) === (t ?? vt(x))).reduce((t, r) => t + nt(r), 0)) : (onlyObserations ? (m.filter(x => nt_observations(x) === n).reduce((t, r) => t + nt_observations(r), 0)): (m.filter(x => nt(x) === n).reduce((t, r) => t + nt(r), 0)));
export const getRaisedAxleCount: (m: DetectionObservation[], n: number, t?: VehicleType) => number = (m, n, t) => m.filter(x => nt(x) === n && vt(x) === (t ?? vt(x))).reduce((t, r) => t + nrt(r), 0);
export const getRaisedAxleCountMl: (m: DetectionObservation[], n: number, t?: VehicleType) => number = (m, n, t) => m.filter(x => nt(x) === n && vt(x) === (t ?? vt(x))).reduce((t, r) => t + nrtml(r), 0);


export const getTotalLowerAxleCount: (m: DetectionObservation[], t?: VehicleType) => number = (m, t) => {
  return m.filter(x => vt(x) === (t ?? vt(x))).reduce((t, r) => t + (nt_detections(r) - nrt(r)), 0);
};
export const getTotalLowerAxleCountMl: (m: DetectionObservation[], t?: VehicleType) => number = (m, t) => {
  return m.filter(x => vt(x) === (t ?? vt(x))).reduce((t, r) => t + (nt_detections(r) - nrtml(r)), 0);
};
export const getTotalRaisedAxleCount: (m: DetectionObservation[], t?: VehicleType, onlyObserations?: boolean) => number = (m, t, onlyObserations) => !onlyObserations ? (m.filter(x => vt(x) === (t ?? vt(x))).reduce((t, r) => t + nrt(r), 0)) : 0;
export const getTotalRaisedAxleCountMl: (m: DetectionObservation[], t?: VehicleType, onlyObserations?: boolean) => number = (m, t, onlyObserations) => !onlyObserations ? (m.filter(x => vt(x) === (t ?? vt(x))).reduce((t, r) => t + nrtml(r), 0)) : 0;
export const getTotalMissedAxleCount: (m: DetectionObservation[], axleType: string) => number = (m, axleType) => axleType === 'lowered'? (m.reduce((t, r) => t + (nt_missed(r)|| nrt(r)) -nrt(r), 0)):(m.reduce((t, r) => t + nrt(r), 0));
export const getTotalMissedAxleCountMl: (m: DetectionObservation[], axleType: string) => number = (m, axleType) => axleType === 'lowered'? (m.reduce((t, r) => t + (nt_missed(r)|| nrtml(r)) -nrtml(r), 0)):(m.reduce((t, r) => t + nrtml(r), 0));

export const getTotalMissedAxleCountObserved: (m: DetectionObservation[], axleType: string) => number = (m, axleType) => axleType === 'lowered'? (m.reduce((t, r) => t + (nt_missed_observed(r)|| nrt(r)) -nrt(r), 0)):(m.reduce((t, r) => t + nrt(r), 0));
export const getTotalMissedAxleCountObservedMl: (m: DetectionObservation[], axleType: string) => number = (m, axleType) => axleType === 'lowered'? (m.reduce((t, r) => t + (nt_missed_observed(r)|| nrtml(r)) -nrtml(r), 0)):(m.reduce((t, r) => t + nrtml(r), 0));


export const getMissedAxleCount: (m: DetectionObservation[], n: number, axleType: string) => number = (m, n, axleType) => axleType === 'lowered'? (m.filter(x => nt(x) === n).reduce((t, r) => t + (nt_missed(r)|| nrt(r)) -nrt(r), 0)):(m.filter(x => nt(x) === n).reduce((t, r) => t + nrt(r), 0));
export const getMissedAxleCountMl: (m: DetectionObservation[], n: number, axleType: string) => number = (m, n, axleType) => axleType === 'lowered'? (m.filter(x => nt(x) === n).reduce((t, r) => t + (nt_missed(r)|| nrtml(r)) -nrtml(r), 0)):(m.filter(x => nt(x) === n).reduce((t, r) => t + nrtml(r), 0));

const Stats: React.FC<Props> = ({ tenantName, matches, view }) => {

  const [advanced, setAdvanced] = React.useState<boolean>(false);
  const handleClickAdvanced = () => {
    setAdvanced(!advanced)
  }

  const uniqueMatches = matches.filter(d => d.detection?.verified_association_type == null ||   
    (d.detection?.verified_vehicle_class != null && d.detection?.verified_association_type != null) 
  )

  const d = advanced? uniqueMatches.filter(m => mt(m) === 'Detection'): uniqueMatches.filter(m => !!m.detection);
  const o = advanced? uniqueMatches.filter(m => mt(m) === 'Observation'): uniqueMatches.filter(m => !!m.observation);
  const matched = uniqueMatches.filter(isMatched);
  const unverified = uniqueMatches.filter(isUnverified);
  const missed = uniqueMatches.filter(isMissed);

  const maxAxles = Math.max.apply(null, uniqueMatches.map(x => Math.max(x.detection?.verified_num_tires ?? x.detection?.num_tires ?? Number.MIN_SAFE_INTEGER, x.observation?.num_tires ?? Number.MIN_SAFE_INTEGER)));

  const minAxles = Math.min.apply(null, uniqueMatches.map(x => Math.min(x.detection?.verified_num_tires ?? x.detection?.num_tires ?? Number.MAX_SAFE_INTEGER, x.observation?.num_tires ?? Number.MAX_SAFE_INTEGER)));
  const axleColumns = Array.from(Array(maxAxles - minAxles + 1).keys()).map(x => x + minAxles);

  const missedRaisedAxleTitle = `total vehicles in which ${tenantName} miscounted axles`;
  return (
    <Box>
      <Typography sx={{m: 2}}>Discrepancy Table</Typography>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography sx={{ml: 4}}>Off</Typography>
          <AntSwitch
            checked={advanced}
            onChange={handleClickAdvanced}
            inputProps={{ 'aria-label': 'ant design' }} />
          <Typography>On</Typography>
        </Stack>
        <Box sx={{ flex: '1 1 0', m: '1rem' }}>
          <Table size="small" sx={{
            [`& .${tableCellClasses.root}`]: {
              border: "2px solid rgb(211, 211, 211)",
            }
          }}>
            <TableHead sx={{
              [`& .${tableCellClasses.root}`]: {
                fontWeight: "bold",
              }
            }}>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell rowSpan={2} sx={{textAlign: 'center'}}>Vehicle Class</TableCell>
                <TableCell rowSpan={2} sx={{textAlign: 'center'}}>Total # Axles</TableCell>
                <TableCell rowSpan={2} sx={{textAlign: 'center'}}>Total # Vehicles</TableCell>
                <TableCell colSpan={maxAxles - minAxles +1} sx={{textAlign: 'center', fontWeight: 'bold' }}># Vehicles per Axle Class</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{n}</TableCell>
                  )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} rowSpan={5}>AvenewAi</TableCell>
                <TableCell sx={{fontWeight: 'bold'}} >Car (A)</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(true,d, 'Car') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalAxleCount(true,d, 'Car') > 0 ? getTotalAxleCount(true,d, 'Car') : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,d, 'Car') > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getTotalVehicleCount(true,d, 'Car') > 0 ? getTotalVehicleCount(true,d, 'Car') : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,d, n, 'Car') > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getVehicleCount(true,d, n, 'Car') > 0 ? getVehicleCount(true,d, n, 'Car') : ''}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >Bus (B)</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(true,d, 'Bus') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalAxleCount(true,d, 'Bus') > 0 ? getTotalAxleCount(true,d, 'Bus') : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,d, 'Bus') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalVehicleCount(true,d, 'Bus') > 0 ? getTotalVehicleCount(true,d, 'Bus') : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,d, n, 'Bus') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getVehicleCount(true,d, n, 'Bus') > 0 ? getVehicleCount(true,d, n, 'Bus') : ''}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >Truck (C)</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(true,d, 'Truck') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalAxleCount(true,d, 'Truck') > 0 ? getTotalAxleCount(true,d, 'Truck') : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,d, 'Truck') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalVehicleCount(true,d, 'Truck') > 0 ? getTotalVehicleCount(true,d, 'Truck') : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,d, n, 'Truck') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getVehicleCount(true,d, n, 'Truck') > 0 ? getVehicleCount(true,d, n, 'Truck') : ''}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >Motorcycle (M)</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(true,d, 'Motorcycle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalAxleCount(true,d, 'Motorcycle') > 0 ? getTotalAxleCount(true,d, 'Motorcycle') : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,d, 'Motorcycle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalVehicleCount(true,d, 'Motorcycle') > 0 ? getTotalVehicleCount(true,d, 'Motorcycle') : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,d, n, 'Motorcycle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getVehicleCount(true,d, n, 'Motorcycle') > 0 ? getVehicleCount(true,d, n, 'Motorcycle') : ''}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >TOTAL</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(false, d, 'Vehicle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalAxleCount(false, d, 'Vehicle') > 0 ? getTotalAxleCount(false, d, 'Vehicle') : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(false, d, 'Vehicle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getTotalVehicleCount(false, d, 'Vehicle') > 0 ? getTotalVehicleCount(false, d, 'Vehicle') : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(false, d, n, 'Motorcycle') > 0 ? COLOR_NO_OBSERVATION : undefined) : undefined }}>{getVehicleCount(false, d, n, 'Motorcycle') > 0 ? getVehicleCount(false, d, n, 'Motorcycle') : ''}</TableCell>
                )}
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} colSpan={2}>{tenantName}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalAxleCount(false,o, 'Vehicle', true) > 0 ? COLOR_NO_DETECTION : undefined) : undefined }}>{getTotalAxleCount(false,o, 'Vehicle', true) > 0 ? getTotalAxleCount(false,o, 'Vehicle', true) : ''}</TableCell>
                <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(false,o, 'Vehicle', true) > 0 ? COLOR_NO_DETECTION : undefined) : undefined }}> {getTotalVehicleCount(false,o, 'Vehicle', true) > 0 ? getTotalVehicleCount(false,o, 'Vehicle', true) : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(false,o, n, 'Vehicle', true) > 0 ? COLOR_NO_DETECTION : undefined) : undefined }}>{getVehicleCount(false,o, n, 'Vehicle', true) > 0 ? getVehicleCount(false,o, n, 'Vehicle', true) : ''}</TableCell>
                )}
              </TableRow>
              {advanced && <React.Fragment>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} colSpan={2}>Matched</TableCell>
                  <TableCell >{getTotalAxleCount(true,matched) > 0 ? getTotalAxleCount(true,matched) : ''}</TableCell>
                  <TableCell>{getTotalVehicleCount(true,matched) > 0 ? getTotalVehicleCount(true,matched) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{getVehicleCount(true,matched, n) > 0 ? getVehicleCount(true,matched, n) : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} colSpan={2}>Unverified</TableCell>
                  <TableCell >&nbsp;</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,unverified) > 0 ? COLOR_UNVERIFIED_DETECTION : undefined) : undefined}}>{getTotalVehicleCount(true,unverified) > 0 ? getTotalVehicleCount(true,unverified) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,unverified, n) > 0 ? COLOR_UNVERIFIED_DETECTION : undefined) : undefined }}>{getVehicleCount(true,unverified, n) > 0 ? getVehicleCount(true,unverified, n) : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} title={missedRaisedAxleTitle} colSpan={2}>Verified Discrepancies</TableCell>
                  <TableCell >&nbsp;</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? (getTotalVehicleCount(true,missed) > 0 ? COLOR_MISSED_DETECTION : undefined) : undefined }}>{getTotalVehicleCount(true,missed) > 0 ? getTotalVehicleCount(true,missed) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getVehicleCount(true,missed, n) > 0 ? COLOR_MISSED_DETECTION : undefined) : undefined }}>{getVehicleCount(true,missed, n) > 0 ? getVehicleCount(true,missed, n) : ''}</TableCell>
                  )}
                </TableRow>
              </React.Fragment>}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ flex: '1 1 0', m: '1rem' }}>
          <Table size="small" sx={{
            [`& .${tableCellClasses.root}`]: {
              border: "2px solid rgb(211, 211, 211)",
            }
          }}>
          {advanced && <React.Fragment>
            <TableHead sx={{
              [`& .${tableCellClasses.root}`]: {
                fontWeight: "bold",
              }
            }}>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell rowSpan={2} colSpan={2} sx={{textAlign: 'center'}}>Axle Type</TableCell>
                <TableCell rowSpan={2} sx={{textAlign: 'center'}}>Total # Axles</TableCell>
                <TableCell colSpan={maxAxles - minAxles +1} sx={{textAlign: 'center', fontWeight: 'bold' }}># Axles per Axle Class</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{n}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} rowSpan={isSuperuser()? 7 : 5}>AvenewAi</TableCell>
              </TableRow>
              {(isSuperuser() || !view?.has_raised_axle_model) && <>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} rowSpan={isSuperuser() && view?.has_raised_axle_model ? 4 : 2 }>Missed by {tenantName}</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Raised</TableCell>
                  <TableCell title={`raised axles missed by ${tenantName}`} sx={{ backgroundColor: advanced? (getTotalMissedAxleCount(missed, 'raised') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getTotalMissedAxleCount(missed, 'raised') > 0 ? getTotalMissedAxleCount(missed, 'raised') : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getMissedAxleCount(missed, n, 'raised') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getMissedAxleCount(missed, n, 'raised') > 0 ? getMissedAxleCount(missed, n, 'raised') : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered</TableCell>
                  <TableCell title={`lowered axles undercounted by ${tenantName}`} sx={{ backgroundColor: advanced? (getTotalMissedAxleCount(missed, 'lowered') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getTotalMissedAxleCount(missed, 'lowered') > 0 ? getTotalMissedAxleCount(missed, 'lowered') : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getMissedAxleCount(missed, n, 'lowered') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getMissedAxleCount(missed, n, 'lowered') > 0 ? getMissedAxleCount(missed, n, 'lowered') : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              {view?.has_raised_axle_model && <>
                <TableRow>
                  {!isSuperuser() && <TableCell sx={{fontWeight: 'bold'}} rowSpan={2}>Missed by {tenantName}</TableCell>}
                  <TableCell sx={{fontWeight: 'bold'}}>Raised{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell title={`raised axles missed by ${tenantName}`} sx={{ backgroundColor: advanced? (getTotalMissedAxleCountMl(missed, 'raised') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getTotalMissedAxleCountMl(missed, 'raised') > 0 ? getTotalMissedAxleCountMl(missed, 'raised') : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getMissedAxleCountMl(missed, n, 'raised') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getMissedAxleCountMl(missed, n, 'raised') > 0 ? getMissedAxleCountMl(missed, n, 'raised') : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell title={`lowered axles undercounted by ${tenantName}`} sx={{ backgroundColor: advanced? (getTotalMissedAxleCountMl(missed, 'lowered') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getTotalMissedAxleCountMl(missed, 'lowered') > 0 ? getTotalMissedAxleCountMl(missed, 'lowered') : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getMissedAxleCountMl(missed, n, 'lowered') > 0 ? COLOR_MISSED_DETECTION : undefined): undefined }}>{getMissedAxleCountMl(missed, n, 'lowered') > 0 ? getMissedAxleCountMl(missed, n, 'lowered') : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              {(isSuperuser() || !view?.has_raised_axle_model) && <>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} rowSpan={2}>Unmatched</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Raised</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? (getTotalRaisedAxleCount(d) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getTotalRaisedAxleCount(d) > 0 ? getTotalRaisedAxleCount(d) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getRaisedAxleCount(d, n) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getRaisedAxleCount(d, n) > 0 ? getRaisedAxleCount(d, n) : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? ((getTotalAxleCount(true,d) - getTotalRaisedAxleCount(d)) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{(getTotalAxleCount(true,d) - getTotalRaisedAxleCount(d)) > 0 ? (getTotalAxleCount(true,d) - getTotalRaisedAxleCount(d)) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? ((getAxleCount(true,d, n) - getRaisedAxleCount(d, n)) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{(getAxleCount(true,d, n) - getRaisedAxleCount(d, n)) > 0 ? (getAxleCount(true,d, n) - getRaisedAxleCount(d, n)) : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              {view?.has_raised_axle_model && <>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}} rowSpan={2}>Unmatched</TableCell>
                  <TableCell sx={{fontWeight: 'bold'}}>Raised{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? (getTotalRaisedAxleCountMl(d) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getTotalRaisedAxleCountMl(d) > 0 ? getTotalRaisedAxleCountMl(d) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? (getRaisedAxleCountMl(d, n) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{getRaisedAxleCountMl(d, n) > 0 ? getRaisedAxleCountMl(d, n) : ''}</TableCell>
                  )}
                </TableRow>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell sx={{ backgroundColor: advanced? ((getTotalAxleCount(true,d) - getTotalRaisedAxleCountMl(d)) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{(getTotalAxleCount(true,d) - getTotalRaisedAxleCountMl(d)) > 0 ? (getTotalAxleCount(true,d) - getTotalRaisedAxleCountMl(d)) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n} sx={{ backgroundColor: advanced? ((getAxleCount(true,d, n) - getRaisedAxleCountMl(d, n)) > 0 ? COLOR_NO_OBSERVATION : undefined): undefined }}>{(getAxleCount(true,d, n) - getRaisedAxleCountMl(d, n)) > 0 ? (getAxleCount(true,d, n) - getRaisedAxleCountMl(d, n)) : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} rowSpan={3}>{tenantName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >Unmatched</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Lowered</TableCell>
                <TableCell sx={{ backgroundColor: advanced? ((getTotalAxleCount(advanced,o,'Vehicle',true)) > 0 ? COLOR_NO_DETECTION : undefined): undefined }}>{(getTotalAxleCount(advanced,o,'Vehicle',true)) > 0 ? (getTotalAxleCount(advanced,o,'Vehicle',true)) : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n} sx={{ backgroundColor: advanced? ((getAxleCount(advanced,o, n,'Vehicle',true)) > 0 ? COLOR_NO_DETECTION : undefined): undefined }}>{(getAxleCount(advanced,o, n,'Vehicle',true)) > 0 ? (getAxleCount(advanced,o, n,'Vehicle',true)) : ''}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </React.Fragment>}
          {!advanced && <React.Fragment>
            <TableHead sx={{
              [`& .${tableCellClasses.root}`]: {
                fontWeight: "bold",
              }
            }}>
              <TableRow>
                <TableCell rowSpan={2}>&nbsp;</TableCell>
                <TableCell rowSpan={2} colSpan={1} sx={{textAlign: 'center'}}>Axle Type</TableCell>
                <TableCell rowSpan={2} sx={{textAlign: 'center'}}>Total # Axles</TableCell>
                <TableCell colSpan={maxAxles - minAxles +1} sx={{textAlign: 'center', fontWeight: 'bold' }}># Axles per Axle Class</TableCell>
              </TableRow>
              <TableRow>
                {axleColumns.map(
                  n => <TableCell key={n}>{n}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} rowSpan={(isSuperuser() && view?.has_raised_axle_model) ? 5 : 3}>AvenewAi</TableCell>
              </TableRow>
              {(isSuperuser() || !view?.has_raised_axle_model) && <>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Raised</TableCell>
                  <TableCell>{getTotalRaisedAxleCount(d) > 0 ? getTotalRaisedAxleCount(d) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{getRaisedAxleCount(d, n) > 0 ? getRaisedAxleCount(d, n) : ''}</TableCell>
                  )}
                </TableRow >
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered</TableCell>
                  <TableCell>{(getTotalAxleCount(true,d) - getTotalRaisedAxleCount(d)) > 0 ? (getTotalAxleCount(true,d) - getTotalRaisedAxleCount(d)) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{(getAxleCount(true,d, n) - getRaisedAxleCount(d, n)) > 0 ? (getAxleCount(true,d, n) - getRaisedAxleCount(d, n)) : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              {view?.has_raised_axle_model && <>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Raised{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell>{getTotalRaisedAxleCountMl(d) > 0 ? getTotalRaisedAxleCountMl(d) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{getRaisedAxleCountMl(d, n) > 0 ? getRaisedAxleCountMl(d, n) : ''}</TableCell>
                  )}
                </TableRow >
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold'}}>Lowered{isSuperuser() ? ' (ML)' : ''}</TableCell>
                  <TableCell>{(getTotalAxleCount(true,d) - getTotalRaisedAxleCountMl(d)) > 0 ? (getTotalAxleCount(true,d) - getTotalRaisedAxleCountMl(d)) : ''}</TableCell>
                  {axleColumns.map(
                    n => <TableCell key={n}>{(getAxleCount(true,d, n) - getRaisedAxleCountMl(d, n)) > 0 ? (getAxleCount(true,d, n) - getRaisedAxleCountMl(d, n)) : ''}</TableCell>
                  )}
                </TableRow>
              </>}
              <TableRow>
                <TableCell sx={{fontWeight: 'bold'}} >{tenantName}</TableCell>
                <TableCell sx={{fontWeight: 'bold'}}>Lowered</TableCell>
                <TableCell>{(getTotalAxleCount(false,o,'Vehicle',true)) > 0 ? (getTotalAxleCount(false,o,'Vehicle',true)) : ''}</TableCell>
                {axleColumns.map(
                  n => <TableCell key={n}>{(getAxleCount(false,o, n,'Vehicle',true)) > 0 ? (getAxleCount(false,o, n,'Vehicle',true)) : ''}</TableCell>
                )}
              </TableRow>
            </TableBody>
          </React.Fragment>}
        </Table>
      </Box>
    </Box>
  );
};

export default Stats;

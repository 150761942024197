import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { Site } from '../types';
import { getSite } from '../utils/api';

import ErrorPage from './ErrorPage';
import LoadingPage from './LoadingPage';
import SitePage from './SitePage';
import { SiteView } from '../types';
import useSiteView from '../hooks/useSiteView';

const SiteRoute: React.FC = () => {
	const { site_id } = useParams();

  const siteId = site_id ? +site_id : -1; 

	const { isLoading: isSiteLoading, isError: isSiteError, data: site } = useQuery<Site, Error>(
		['sites', siteId],
    () => getSite(siteId),
		{ enabled: siteId !== -1 }
	);
  const siteView: SiteView = useSiteView(site ? site.type : 'ADS');

	if (siteId === -1) {
		return <ErrorPage message="No site specified." />;
	}

	if (isSiteError) {
		return <ErrorPage message="Unknown site." />;
	}

	if (isSiteLoading ) {
		return <LoadingPage title="Site" />;
	}

	if (!site) {
		return <ErrorPage message="Unable to load site." />;
	}

	return <SitePage site={site} siteView={siteView} />;
};

export default SiteRoute;

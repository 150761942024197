import React from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Header from '../views/Header';

const theme = createTheme();

const AccountInformationPage: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Header>Account Information</Header>
      <Box
        sx={{
          marginTop: 4,
          marginLeft: 5,
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          alignItems: 'left',
        }}
      >
        <AccountDetails/>
      </Box>
    </ThemeProvider>
  );
};

export default AccountInformationPage;

const AccountDetails: React.FC<{}> = () => {
  const email = localStorage.getItem('email');
  const has_mfa = localStorage.getItem('has_mfa') === 'true';
  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row'  }}>
          <div>
            <Typography>Email:</Typography>
          </div>
          <div>
            <Typography>{email}</Typography>
          </div>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <Typography>Is MFA Set Up:</Typography>
          </div>
          <div>
            <Typography>{has_mfa ? 'Yes' : 'No'}</Typography>
          </div>
        </Box>
        {!has_mfa && <Button>
          <Link to="/mfa-setup" style={{ color: 'inherit', textDecoration: 'none' }}>
            Setup MFA
          </Link>
        </Button>}
        {has_mfa && <Button>
          <Link to="/mfa-setup" style={{ color: 'inherit', textDecoration: 'none' }}>
            Remove MFA
          </Link>
        </Button>}
        {has_mfa && <Button>
          <Link to="/mfa-setup" style={{ color: 'inherit', textDecoration: 'none' }}>
            Reset MFA Token
          </Link>
        </Button>}
        <Button>
          <Link to="/password-reset" style={{ color: 'inherit', textDecoration: 'none' }}>
            Reset Password
          </Link>
        </Button>
      </Box>
    </Container>
  );
};

import { CameraLocation, CameraPosition, Comparison, Detection, DetectionObservation, View, MeasurementScale, DisplayColumns, Role } from './types';
import { callApi, parseDetection, getView } from './utils/api';
import { getRole, isSuperuser } from './utils/auth';

import { ASSET_URL, BASE_URL } from './config';

export const formatDate: (d: Date, dp?: number) => string = (d, dp = 0) => {
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  const minute = ('0' + d.getMinutes()).slice(-2);
  const second = ('0' + d.getSeconds()).slice(-2);
  let fraction = '';
  if (dp > 0) {
    fraction = '.' + ('0'.repeat(dp) + Math.round(d.getMilliseconds() / Math.pow(10, 3 - dp))).slice(-dp);
  }
  return `${d.getFullYear()}-${month}-${day} ${d.getHours()}:${minute}:${second}${fraction}`;
};

export const formatAsDate: (d: Date) => string = (d) => {
  const month = ('0' + (d.getMonth() + 1)).slice(-2);
  const day = ('0' + d.getDate()).slice(-2);
  return `${d.getFullYear()}-${month}-${day}`;
};

export const formatAsTime: (d: Date) => string = (d) => {
  const minute = ('0' + d.getMinutes()).slice(-2);
  const second = ('0' + d.getSeconds()).slice(-2);
  return `${d.getHours()}:${minute}:${second}`;
};

export const getPositionDisplay: (position: CameraPosition) => string = position => position.charAt(0).toUpperCase() + position.slice(1);
export const getLocationDisplay: (position: CameraLocation) => string = position => position.charAt(0).toUpperCase() + position.slice(1);

export const downloadDetections: (view_id: number, start_time?: string, end_time?: string, vehicle_class?: string, min_axles?: number, min_raised_axles?: number) => void = async (view_id: number, start_time?: string, end_time?: string, vehicle_class?: string, min_axles?: number, min_raised_axles?: number) => {
  let url = '/views/' + view_id + '/detections';
  let prefix = '?';
  if (start_time) {
    const start = new Date(start_time);
    const startstr = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).substr(-2) + '-' + ('0' + start.getDate()).substr(-2) + ' ' + ('0' + start.getHours()).substr(-2) + ':' + ('0' + start.getMinutes()).substr(-2) + ':00';
    url += prefix + 'start_stamp=' + encodeURIComponent(startstr);
    prefix = '&';
  }
  if (end_time) {
    const end = new Date(end_time);
    const endstr = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).substr(-2) + '-' + ('0' + end.getDate()).substr(-2) + ' ' + ('0' + end.getHours()).substr(-2) + ':' + ('0' + end.getMinutes()).substr(-2) + ':00';
    url += prefix + 'end_stamp=' + encodeURIComponent(endstr);
    prefix = '&';
  }
  if (vehicle_class) {
    url += prefix + 'vehicle_class=' + vehicle_class;
    prefix = '&';
  }
  if (min_axles) {
    url += prefix + 'min_axles=' + min_axles;
    prefix = '&';
  }
  if (min_raised_axles) {
    url += prefix + 'min_raised_axles=' + min_raised_axles;
    prefix = '&';
  }

  Promise.all([
    getView(view_id),
    callApi(url).then(result => result.map(parseDetection))
  ]).then(([view, detections]) => makeDetectionCsv(view, detections));
};


function getMode(distribution: number[], min: number = 85, gap: number = 1): number {
  return min + gap * distribution.lastIndexOf(Math.max(...distribution));
}



const makeDetectionCsv = (view: View, detections: Detection[]) => {
  const header = [
    'Vehicle Number',
    'Is Verified',
    'Associated Vehicle Numbers',
    'Association Type',
    'Vehicle Class',
    'Toll Class',
    'Start Date',
    'Start Time',
    'End Date',
    'End Time',
    'Duration',
    `Speed (${view.measurement_scale === 'imperial' ? 'mph' : 'km/h'})`,
    `Length (${view.measurement_scale === 'imperial' ? 'in' : 'm'})`,
    `Height (${view.measurement_scale === 'imperial' ? 'in' : 'm'})`,
    'Straddle',
    'Axle Count',
    'Raised Axle Count',
    'Detection URL',
    'Raw Video URL',
    'Minimum Mode Score Vehicle',
    'Minimum Mode Score Axles',
    'Lowest Maximum Score Vehicle',
    'Lowest Maximum Score Axles',
    'Minimum Score Vehicle',
    'Minimum Score Axles',
    'Original Vehicle Class',
    'Original Axle Count',
    'Original Raised Axle Count',
    'Verified Vehicle Class',
    'Verified Axle Count',
    'Verified Raised Axle Count'
  ];
  detections = detections.filter(d => d.verified_association_type == null ||   
    (d.verified_vehicle_class !== null && d.verified_association_type != null)
  )

  const rows = detections.map(d => [
    d.vehicle_number,
    isDetectionVerified(d),
    d.verified_associated_vehicle_numbers?.join(', '),
    d.verified_association_type,
    d.verified_vehicle_class ?? d.vehicle_class,
    d.vehicle_subclass ?? '',
    formatAsDate(d.start_time),
    formatAsTime(d.start_time),
    formatAsDate(d.end_time),
    formatAsTime(d.end_time),
    Math.ceil((d.end_time.valueOf() - d.start_time.valueOf()) / 1000),
    scaleSpeed(view.measurement_scale ?? 'metric', d.speed),
    scaleDistance(view.measurement_scale ?? 'metric', d.length),
    scaleDistance(view.measurement_scale ?? 'metric', d.height),
    d.straddle ?? '',
    d.verified_num_tires ?? d.num_tires,
    d.verified_num_raised_tires ?? (view.has_raised_axle_model ? (d.num_raised_tires_ml ?? 0) : d.num_raised_tires),
    `${BASE_URL}/detections/${d.id}`,
    `${ASSET_URL}/${d.process_id}/raw-snippet-${d.vehicle_number}.mp4`,
    getMode(d.statistics?.distribution ?? []),
    Math.min(...(d.statistics?.tires ?? []).map(t => getMode(t.distribution ?? []))),
    d.statistics?.maximum_score ? (d.statistics?.maximum_score * 100).toFixed(2) : '',
    Math.min(...(d.statistics?.tires ?? []).map(t => t?.maximum_score ?? Infinity)),
    d.minimum_vehicle_score,
    d.minimum_tire_score,
    d.vehicle_class,
    d.num_tires,
    view.has_raised_axle_model ? (d.num_raised_tires_ml ?? 0) : d.num_raised_tires,
    d.verified_vehicle_class,
    d.verified_num_tires,
    d.verified_num_raised_tires
  ]);
  const content = "data:text/csv;charset=utf-8," + (header.join(",") + "\n") + rows.map(r => r.join(",")).join("\n");
  const encodedUri = encodeURI(content);    
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'ArchiveExport.csv');
  document.body.appendChild(link);
  link.click();
};

export const downloadComparisons = (view: View, comparison: Comparison) => {
  const header = [
    'A. Vehicle Number',
    'Is Verified',
    'A. Associated Vehicle Numbers',
    'Association Type',
    'Vehicle Class',
    'Toll Class',
    'Detection URL',
    'A. Start Date',
    'A. Start Time',
    'A. End Date',
    'A. End Time',
    'Delta',
    `Speed (${view.measurement_scale === 'imperial' ? 'mph' : 'km/h'})`,
    `Length (${view.measurement_scale === 'imperial' ? 'in' : 'm'})`,
    `Height (${view.measurement_scale === 'imperial' ? 'in' : 'm'})`,
    'Straddle',
    'A. Total Axle Count',
    'A. Lowered Axle Count',
    'A. Raised Axle Count',
    'A. Original Total Axle Count',
    'A. Original Lowered Axle Count',
    'A. Original Raised Axle Count',
    'A. Verified Total Axle Count',
    'A. Verified Lowered Axle Count',
    'A. Verified Raised Axle Count',
    'Is Vehicle Class correct',
    'Axle Count correction',
    'Raised Axle Count correction',
    'Matching',
    'Vehicle Number',
    'Date',
    'Time',
    'Is Primary Time',
    'Axle Count',
  ];
  comparison.matches = comparison.matches.filter(d => d.detection?.verified_association_type == null ||   
    (d.detection?.verified_vehicle_class != null && d.detection?.verified_association_type != null) 
  )

  const rows = comparison.matches.map(d => {
    const verified_vehicle_class = d.detection?.verified_vehicle_class;
    const verified_num_tires = d.detection?.verified_num_tires;
    const verified_num_raised_tires = d.detection?.verified_num_raised_tires;
    
    const original_vehicle_class = d.detection?.vehicle_class;
    const original_num_tires = d.detection?.num_tires;
    const original_num_raised_tires = view.has_raised_axle_model ? (d.detection?.num_raised_tires_ml ?? 0) : d.detection?.num_raised_tires;
    
    const vehicle_class = verified_vehicle_class ?? original_vehicle_class;
    const num_tires = verified_num_tires ?? original_num_tires;
    const num_raised_tires = verified_num_raised_tires ?? original_num_raised_tires;

    const hasBoth = !!d.detection && !!d.observation;
    const end_time = d.detection?.end_time;
    const date_time = d.observation?.date_time;
    return [
      d.detection?.vehicle_number,
      (d.detection ? isDetectionVerified(d.detection) : ''),
      d.detection?.verified_associated_vehicle_numbers?.join(', '),
      d.detection?.verified_association_type,
      vehicle_class,
      d.detection?.vehicle_subclass ?? '',
      d.detection ? `${BASE_URL}/detections/${d.detection.id}`: '',
      (d.detection?.start_time) ? formatAsDate(d.detection.start_time) : '',
      (d.detection?.start_time) ? formatAsTime(d.detection.start_time) : '',
      (d.detection?.end_time) ? formatAsDate(d.detection.end_time) : '',
      (d.detection?.end_time) ? formatAsTime(d.detection.end_time) : '',
      hasBoth ? Math.ceil((date_time!.valueOf() - end_time!.valueOf()) / 1000) : '',
      scaleSpeed(view.measurement_scale ?? 'metric', d.detection?.speed),
      scaleDistance(view.measurement_scale ?? 'metric', d.detection?.length),
      scaleDistance(view.measurement_scale ?? 'metric', d.detection?.height),
      d.detection?.straddle ?? '',
      num_tires,
      d.detection ? num_tires! - num_raised_tires! : '',
      num_raised_tires,
      original_num_tires,
      d.detection ? original_num_tires! - original_num_raised_tires! : '',
      original_num_raised_tires,
      verified_num_tires,
      (d.detection && isDetectionVerified(d.detection)) ? verified_num_tires! - verified_num_raised_tires! : '',
      verified_num_raised_tires,
      (d.detection ? verified_vehicle_class === original_vehicle_class : ''),
      (d.detection ? num_tires! - original_num_tires! : ''),
      (d.detection ? num_raised_tires! - original_num_raised_tires! : ''),
      getMatchStatus(d),
      d.observation?.rank,
      (d.observation?.date_time) ? formatAsDate(d.observation.date_time) : '',
      (d.observation?.date_time) ? formatAsTime(d.observation.date_time) : '',
      (d.observation?.date_time) ? d.observation.is_primary_time : '',
      d.observation?.num_tires
    ];
  });
  const content = "data:text/csv;charset=utf-8," + (header.join(",") + "\n") + rows.map(r => r.join(",")).join("\n");
  const encodedUri = encodeURI(content);    
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'AuditExport.csv');
  document.body.appendChild(link);
  link.click();
};

const getMatchStatus = (m: DetectionObservation) => {
  if (isMatched(m)) {
    return 'Matched';
  }
  if (isUnmatchedDetection(m)) {
    return 'Unmatched Detection';
  }
  if (isUnmatchedObservation(m)) {
    return 'Unmatched upload';
  }
  if (isUnverified(m)) {
    return 'Unverified Mis-Match';
  }
  if (isMissed(m)) {
    return 'Missed Detection';
  }
  if (isVerified(m)) {
    return 'Verified Detection';
  }
  return '';
}

export const isDetectionVerified = (detection: Detection) => !!detection && detection.verified_vehicle_class !== null && detection.verified_num_tires !== null && detection.verified_num_raised_tires !== null;

export const DEFAULT_DURATION = 24; // in hours

const DEFAULT_INTERVAL: {[duration: number]: number} = {
  1: 60,
  2: 2 * 60,
  3: 5 * 60,
  4: 5 * 60,
  6: 15 * 60,
  12: 30 * 60,
  24: 60 * 60,
  168: 180 * 60,
  336: 360 * 60
};

// get default interval in seconds
export const getDefaultInterval: (duration: number) => number = (duration) => DEFAULT_INTERVAL[duration] ?? 60;

export const getDuration: (detection: Detection) => number = (detection) => Math.ceil((detection.end_time.valueOf() - detection.start_time.valueOf()) / 1000);

export const displayDuration: (duration: number) => string = (duration) => {
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration - hours * 3600) / 60);
  const seconds = Math.floor(duration - hours * 3600 - minutes * 60);
  return hours + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);
};

export const VEHICLE_CLASS_COLORS = {
  Truck: '#f48727',
  Car: '#1a611a',
  Bus: '#0097a7',
  Motorcycle: '#0e3c4d'
};

export const isMatched = (m: DetectionObservation) => !!m.detection && !!m.observation && m.detection.verified_num_tires == null && Math.max(m.detection.num_tires, 2) === m.observation.num_tires;
export const isUnverified = (m: DetectionObservation) => !!m.detection && !!m.observation && !m.detection.verified_num_tires && Math.max(m.detection.num_tires, 2) !== m.observation.num_tires;
export const isMissed = (m: DetectionObservation) => !!m.detection && !!m.observation && !!m.detection.verified_num_tires && m.detection.verified_num_tires !== m.observation.num_tires;
export const isVerified = (m: DetectionObservation) => !!m.detection && !!m.observation && !!m.detection.verified_vehicle_class;

const isUnmatchedDetection = (m: DetectionObservation) => !!m.detection && !m.observation;
export const isUnmatchedObservation = (m: DetectionObservation) => !!m.observation && !m.detection;

const DEFAULT_COLUMNS: DisplayColumns = {
  user: [
    'thumbnail',
    'vehicle_number',
    'verified_associated_vehicle_numbers',
    'verified_association_type',
    'vehicle_class',
    'vehicle_subclass',
    'start_time',
    'end_time',
    'audit_delta',
    'duration',
    'speed',
    'length',
    'height',
    'straddle',
    'axle_count',
    'lowered_count',
    'raised_count',
    'raised_axle_indices',
    'lowered_count_ml',
    'raised_count_ml',
    'raised_axle_indices_ml',
    'vehicle_stats',
    'axle_stats',
    'expand',
    'is_verified',
    'action'
  ],
  admin: [
    'thumbnail',
    'vehicle_number',
    'verified_associated_vehicle_numbers',
    'verified_association_type',
    'vehicle_class',
    'vehicle_subclass',
    'start_time',
    'end_time',
    'audit_delta',
    'duration',
    'speed',
    'length',
    'height',
    'straddle',
    'axle_count',
    'lowered_count',
    'raised_count',
    'raised_axle_indices',
    'lowered_count_ml',
    'raised_count_ml',
    'raised_axle_indices_ml',
    'vehicle_stats',
    'axle_stats',
    'expand',
    'is_verified',
    'action'
  ],
  superadmin: [
    'thumbnail',
    'vehicle_number',
    'verified_associated_vehicle_numbers',
    'verified_association_type',
    'vehicle_class',
    'vehicle_subclass',
    'start_time',
    'end_time',
    'audit_delta',
    'duration',
    'speed',
    'length',
    'height',
    'straddle',
    'axle_count',
    'lowered_count',
    'raised_count',
    'raised_axle_indices',
    'lowered_count_ml',
    'raised_count_ml',
    'raised_axle_indices_ml',
    'vehicle_stats',
    'axle_stats',
    'expand',
    'is_verified',
    'action'
  ]
};

export function getColumnNames(has_raised_axle_model: boolean, display_columns?: DisplayColumns, role?: Role): string[] {
  role = role ?? getRole();
  if (!role) {
    return [];
  }
  let columnNames = display_columns?.[role] ?? DEFAULT_COLUMNS[role] ?? [];
  if (has_raised_axle_model && !isSuperuser()) {
    columnNames = columnNames.filter(c => c !== 'lowered_count' && c !== 'raised_count' && c !== 'raised_axle_indices');
  } else if (!has_raised_axle_model) {
    columnNames =  columnNames.filter(c => c !== 'lowered_count_ml' && c !== 'raised_count_ml' && c !== 'raised_axle_indices_ml');
  }
  return columnNames;
}

export function scaleSpeed(measurement_scale: MeasurementScale, value?: number): string {
  if (value === undefined || value === null) {
    return '';
  }
  if (measurement_scale === 'metric') {
    return `${value}`;
  }
  // convert km/h to miles/hour
  return `${(value / 1.609344).toFixed(0)}`;
}

export function scaleDistance(measurement_scale: MeasurementScale, value?: number): string {
  if (value === undefined || value === null) {
    return '';
  }
  if (measurement_scale === 'metric') {
    return `${value}`;
  }
  // convert m to inches
  return `${(value * 39.37).toFixed(1)}`;
}
import React, { useState, ChangeEvent } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import VerifiedIcon from "@mui/icons-material/Verified";

import { Detection } from "../types";
import { updateDetectionVerification } from "../utils/api";
import { isDetectionVerified } from "../utils";

interface VerifyDetectionProps {
  detection: Detection;
  onVerified?: (d: Detection) => void;
}

const VerifyDetection: React.FC<VerifyDetectionProps> = ({ detection, onVerified }) => {
  const [verifiedVehicleClass, setVerifiedVehicleClass] = useState<string | null>(detection.verified_vehicle_class);
  const [verifiedNumTires, setVerifiedNumTires] = useState<number | null>(detection.verified_num_tires);
  const [verifiedNumRaisedTires, setVerifiedNumRaisedTires] = useState<number | null>(detection.verified_num_raised_tires);
  const [verifiedAssociatedVehicleNumbers, setVerifiedAssociatedVehicleNumbers] = useState<number[] | null>(detection.verified_associated_vehicle_numbers);
  const [verifiedAssociationType, setVerifiedAssociationType] = useState<string | null>(detection.verified_association_type);
  const [isDisqualified, setIsDisqualified] = React.useState<boolean>(verifiedAssociationType === "Disqualified");
  const isVerified = isDetectionVerified(detection);
  const canEdit =
    (detection.verified_vehicle_class !== null && detection.verified_association_type !== null) ||
    (detection.verified_vehicle_class === null && detection.verified_association_type !== "Duplicate" && detection.verified_association_type !== "Split") ||
    detection.verified_association_type === "Disqualified";
  const canVerify =
    verifiedVehicleClass !== null &&
    verifiedNumTires !== null &&
    verifiedNumRaisedTires !== null &&
    (verifiedAssociationType === "Unique" || !!verifiedAssociatedVehicleNumbers == !!verifiedAssociationType);
  const changed =
    verifiedVehicleClass !== detection.verified_vehicle_class ||
    verifiedNumTires !== detection.verified_num_tires ||
    verifiedNumRaisedTires !== detection.verified_num_raised_tires ||
    verifiedAssociatedVehicleNumbers !== detection.verified_associated_vehicle_numbers ||
    verifiedAssociationType !== detection.verified_association_type;
  const queryClient = useQueryClient();
  const verifyDetection = useMutation(updateDetectionVerification, {
    onSuccess: (data, variables, context) => {
      if (onVerified) {
        onVerified(data);
      }

      queryClient.invalidateQueries(["detections", detection.id]);
    },
  });
  const handleVerify = () => {
    if (canVerify) {
      console.log(verifiedAssociatedVehicleNumbers);
      verifyDetection.mutate({
        id: detection.id,
        verified_vehicle_class: verifiedVehicleClass,
        verified_num_tires: verifiedNumTires,
        verified_num_raised_tires: verifiedNumRaisedTires,
        verified_associated_vehicle_numbers: verifiedAssociatedVehicleNumbers,
        verified_association_type: verifiedAssociationType,
      });
    }
  };

  const [items, setItems] = useState<number[]>(verifiedAssociatedVehicleNumbers ?? [0]);
  const handleChangeVerifiedVehicleClass = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedVehicleClass(event.target.value);
  const handleChangeVerifiedNumTires = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedNumTires(+event.target.value);
  const handleChangeVerifiedNumRaisedTires = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedNumRaisedTires(+event.target.value);
  const handleChangeVerfiedAssociationType = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedAssociationType(event.target.value);

  const handleClickDisqualify = () => {
    setVerifiedVehicleClass(!isDisqualified ? detection.vehicle_class : null);
    setVerifiedNumTires(!isDisqualified ? detection.num_tires : null);
    setVerifiedNumRaisedTires(!isDisqualified ? detection.num_raised_tires : null);
    setVerifiedAssociationType(!isDisqualified ? "Disqualified" : null);
    setVerifiedAssociatedVehicleNumbers(!isDisqualified ? [] : null);
    setIsDisqualified(!isDisqualified);
    setItems([0]);
  };

  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const newValue = value === "" ? 0 : Number(value);
    const newItems = [...items];
    newItems[index] = newValue;
    const filteredItems = newItems.filter((value) => value !== 0);
    setItems(newItems);
    setVerifiedAssociatedVehicleNumbers(filteredItems.length === 0 ? null : filteredItems);
  };

  const addItem = () => {
    setItems([...items, 0]);
  };

  const removeItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  return (
    <Card
      sx={{
        width: "230px",
      }}
    >
      <CardHeader
        title={<span>Verify Detection {(isVerified || !canEdit || detection.verified_association_type === "Disqualified") && <VerifiedIcon color="success" />}</span>}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      />
      <CardContent>
        <Stack
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "20ch" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          autoComplete="off"
        >
          <Button
            size="medium"
            sx={{
              top: "-10px",
              color: "black",
              backgroundColor: isDisqualified ? "#d0f0c0" : "#ffb6c1",
              "&:hover": {
                backgroundColor: isDisqualified ? "#d0f0c0" : "#ffb6c1",
              },
            }}
            onClick={handleClickDisqualify}
          >
            {isDisqualified ? "Restore" : "Disqualify"}
          </Button>
          {!isDisqualified && (
            <>
              <TextField select label="Vehicle Class" value={verifiedVehicleClass ?? ""} onChange={handleChangeVerifiedVehicleClass} disabled={!canEdit}>
                <MenuItem></MenuItem>
                <MenuItem value="Car">Car</MenuItem>
                <MenuItem value="Truck">Truck</MenuItem>
                <MenuItem value="Bus">Bus</MenuItem>
                <MenuItem value="Motorcycle">Motorcycle</MenuItem>
              </TextField>
              <TextField label="# Axles" value={verifiedNumTires ?? ""} onChange={handleChangeVerifiedNumTires} disabled={!canEdit} />
              <TextField label="# Raised Axles" value={verifiedNumRaisedTires ?? ""} onChange={handleChangeVerifiedNumRaisedTires} disabled={!canEdit} />
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: "fit-content",
                  marginTop: "50px",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-35px",
                    backgroundColor: "white",
                    px: "8px",
                    lineHeight: 1,
                    whiteSpace: "nowrap",
                    fontSize: "17px",
                    textAlign: "center",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: "-40px",
                      bottom: "-5px",
                      width: "200%",
                      borderBottom: "1px solid #ccc",
                    },
                  }}
                >
                  {"Associated Vehicles"}
                </Typography>
                <TextField select label="Association Type" value={verifiedAssociationType ?? ""} onChange={handleChangeVerfiedAssociationType} disabled={!canEdit}>
                  <MenuItem></MenuItem>
                  <MenuItem value="Duplicate">Duplicate</MenuItem>
                  <MenuItem value="Split">Split</MenuItem>
                  <MenuItem value="Unique">Unique</MenuItem>
                </TextField>

                {verifiedAssociationType !== "Unique" &&
                  items.map((item, index) => (
                    <Box key={index} style={{ marginBottom: "-16px", display: "flex", alignItems: "center" }}>
                      <TextField
                        variant="outlined"
                        label={index == 0 ? "Associated ID(s)" : undefined}
                        value={item || ""}
                        onChange={(e) => handleChange(index, e)}
                        onBlur={() => {
                          if (items[index] === 0 && items.length > 1) {
                            removeItem(index);
                          }
                        }}
                        fullWidth
                        disabled={!canEdit}
                      />
                      {index === items.length - 1 && item !== 0 && (
                        <Button
                          variant="contained"
                          onClick={addItem}
                          sx={{
                            position: "absolute",
                            right: "-20px",
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "0",
                            textAlign: "center",
                            lineHeight: "20px",
                            fontSize: "24px",
                          }}
                        >
                          +
                        </Button>
                      )}
                    </Box>
                  ))}
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
      {canVerify && changed && (
        <CardActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              color: "white",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#1976d2",
              },
            }}
            onClick={handleVerify}
          >
            Confirm
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default VerifyDetection;

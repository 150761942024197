import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import CameraList from '../components/CameraList';
import CameraConfigSelect from '../components/CameraConfigSelect';
import CameraModelSelect from '../components/CameraModelSelect';
import ProcessorSelect from '../components/ProcessorSelect';

import { createCamera } from '../utils/api';
import { CameraPosition, CameraLocation } from '../types';

interface CamerasProps {
	siteId: number;
}

const Cameras: React.FC<CamerasProps> = ({ siteId }) => {
	const [adding, setAdding] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [processor_id, setProcessorId] = useState<number>(0);
	const [camera_config_id, setCameraConfigId] = useState<number>(0);
	const [camera_model_id, setCameraModelId] = useState<number>(0);
	const [position, setPosition] = useState<CameraPosition>('gantry');
	const [location, setLocation] = useState<CameraLocation>('north');
	const queryClient = useQueryClient();
	const addCamera = useMutation(createCamera, {
		onSuccess: () => {
			queryClient.invalidateQueries(['cameras', siteId]);
		}
	});

	const handleClickAdd = () => {
		setAdding(true);
		setName('');
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
	const handleChangeProcessorId = (event: React.ChangeEvent<HTMLInputElement>) => setProcessorId(+event.target.value);
	const handleChangeCameraModelId = (event: React.ChangeEvent<HTMLInputElement>) => setCameraModelId(+event.target.value);
	const handleChangeCameraConfigId = (event: React.ChangeEvent<HTMLInputElement>) => setCameraConfigId(+event.target.value);
	const handleChangeCameraPosition = (event: React.ChangeEvent<HTMLInputElement>) => setPosition(event.target.value as CameraPosition);
	const handleChangeCameraLocation = (event: React.ChangeEvent<HTMLInputElement>) => setLocation(event.target.value as CameraLocation);
	const handleCreate = () => {
		addCamera.mutate({ name, processor_id, camera_config_id, camera_model_id, position, location, site_id: siteId });
		setAdding(false);
	}

	return (
    <Card>
      <CardHeader
        title={adding ? "Create Camera" : "Cameras"}
        action={
					!adding && <IconButton aria-label="Create Camera" onClick={handleClickAdd}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
				{adding ?
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { m: 1, width: '20ch' }
						}}
						noValidate
						autoComplete="off"
					>
						<div>
					    <TextField variant="standard" label="Name" onChange={handleChangeName} value={name} placeholder="Camera Name" />
						</div>
						<div>
							<TextField select variant="standard" label="Location" onChange={handleChangeCameraLocation} value={location}>
								<MenuItem value="north">North</MenuItem>
								<MenuItem value="south">South</MenuItem>
								<MenuItem value="east">East</MenuItem>
								<MenuItem value="west">West</MenuItem>
							</TextField>
						</div>
						<div>
							<TextField select variant="standard" label="Postion" onChange={handleChangeCameraPosition} value={position}>
								<MenuItem value="curbside">Curbside</MenuItem>
								<MenuItem value="gantry">Gantry</MenuItem>
								<MenuItem value="pole">Pole</MenuItem>
							</TextField>
						</div>
						<div>
						  <ProcessorSelect siteId={siteId} variant="standard" onChange={handleChangeProcessorId} value={processor_id || ''} />
						</div>
						<div>
						  <CameraModelSelect variant="standard" onChange={handleChangeCameraModelId} value={camera_model_id || ''} />
						</div>
						<div>
						  <CameraConfigSelect variant="standard" onChange={handleChangeCameraConfigId} camera_model_id={camera_model_id} value={camera_config_id || ''} />
						</div>
					</Box>
				 :
        <CameraList siteId={siteId} />}
      </CardContent>
			{adding && <CardActions>
        <Button onClick={handleCreate}>Create</Button>
				<Button onClick={() => setAdding(false)}>Cancel</Button>
			</CardActions>}
    </Card>
  );
};

export default Cameras;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & {
  minCount?: number;
  maxCount?: number;
}

const AxleCountSelect: React.FC<Props> = ({ minCount = 1, maxCount = 10, ...props}: Props) => {
  const options = Array.from(Array(maxCount - minCount + 1).keys()).map(n => n + minCount);
  return (
    <TextField select label="# Axles" sx={{ minWidth: '10rem', mr: '0.5rem' }} {...props}>
      {options.map(
        o => <MenuItem key={o} value={`>=${o}`}>&gt;= {o}</MenuItem>
      )}
      {options.map(
        o => <MenuItem key={o} value={o}>{o}</MenuItem>
      )}
    </TextField>
  );
};

export default AxleCountSelect;

import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import LaneList from '../components/LaneList';

import { createLane } from '../utils/api';

interface LanesProps {
	siteId: number;
}

const Lanes: React.FC<LanesProps> = ({ siteId }) => {
	const [adding, setAdding] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const queryClient = useQueryClient();
	const addLane = useMutation(createLane, {
		onSuccess: () => {
			queryClient.invalidateQueries(['lanes', siteId]);
		}
	});

	const handleClickAdd = () => {
		setAdding(true);
		setName('');
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
	const handleCreate = () => {
		addLane.mutate({ name, site_id: siteId });
		setAdding(false);
	}

	return (
    <Card>
      <CardHeader
        title={adding ? "Create Lane" : "Lanes"}
        action={
					!adding && <IconButton aria-label="Create Lane" onClick={handleClickAdd}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
				{adding ?
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { m: 1, width: '20ch' }
						}}
						noValidate
						autoComplete="off"
					>
						<div>
					    <TextField variant="standard" label="Name" onChange={handleChangeName} value={name} />
						</div>
					</Box>
				 :
        <LaneList siteId={siteId} />}
      </CardContent>
			{adding && <CardActions>
        <Button onClick={handleCreate}>Create</Button>
				<Button onClick={() => setAdding(false)}>Cancel</Button>
			</CardActions>}
    </Card>
  );
};

export default Lanes;

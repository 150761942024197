import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getProcessorsBySite } from '../utils/api';

interface  ProcessorSelectProps {
	siteId: number;
}

const ProcessorSelect: React.FC<ProcessorSelectProps & TextFieldProps> = ({ siteId, ...props }) => {
	const { data } = useQuery(['processors', siteId], () => getProcessorsBySite(siteId));

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Processor" variant="standard" {...props}>
			{data.sort((a, b) => a.name.localeCompare(b.name)).map((processor) => (
				<MenuItem key={processor.id} value={processor.id}>
					{processor.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default ProcessorSelect;

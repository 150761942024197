import React, { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useMutation, useQueryClient } from "react-query";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import CircularProgress from '@mui/material/CircularProgress';
import Download from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';

import ComparisonList from '../components/ComparisonList';
import FileUpload from '../components/FileUpload';
import UploadSelect from '../components/UploadSelect';
import ViewSelect from '../components/ViewSelect';
import VehicleClassSelect from '../components/VehicleClassSelect';
import AxleCountSelect from '../components/AxleCountSelect';
import RaisedAxleCountSelect from '../components/RaisedAxleCountSelect';
import ComparisonTypeSelect from '../components/ComparisonTypeSelect';
import AuditSummary from '../components/audit/Summary';
import AuditStats from '../components/audit/Stats';

import { isMatched, isUnverified, isMissed, isVerified, downloadComparisons } from '../utils';
import { createComparison, associateObservationDetection, disassociateObservationDetection, getView, editSite } from '../utils/api';
import { isSuperuser } from '../utils/auth';

import { Comparison, Observation, Detection, DetectionObservation, View, EdgeProcessConfig, Site, SiteEdit } from '../types';
import { TextField } from '@mui/material';

const PAGE_SIZE = 100;

interface Props {
  site: Site;
  tenantName: string;
  processConfigs: { [id: number]: EdgeProcessConfig };
}

const AuditView: React.FC<Props> = ({ site, tenantName, processConfigs }) => {
  const siteId = site.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const view_id = searchParams.get('view_id') ?? '';
  const upload_id = searchParams.get('upload_id') ?? '';

  const viewId = view_id ? +view_id : undefined;
  const uploadId = upload_id ? +upload_id : undefined;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [comparison, setComparison] = useState<Comparison>();
  const [filteredComparison, setFilteredComparison] = useState<Comparison>();
  const [page, setPage] = useState<number>(0);
  const [vehicleClass, setVehicleClass] = useState<string>('all');
  const [numAxles, setNumAxles] = useState<string>('>=1');
  const [numRaisedAxles, setNumRaisedAxles] = useState<number>(0);
  const [numRaisedAxlesMl, setNumRaisedAxlesMl] = useState<number>(0);
  const [comparisonType, setComparisonType] = useState<string>('all');
  const { data: view } = useQuery<View, Error>(['view', viewId], () => getView(viewId ?? -1), { enabled: !!viewId });
  const [delta_before, setDeltaBefore] = useState<number>(site.delta_before);
  const [delta_after, setDeltaAfter] = useState<number>(site.delta_after);
  const [detection_window, setDetectionWindow] = useState<number>(site.detection_window);
  const [editConfigs, setEditConfigs] = useState<boolean>(false);

  const isFiltered = vehicleClass !== 'all' || numAxles !== '>=0' || numRaisedAxles !== 0 || numRaisedAxlesMl !== 0 || comparisonType !== 'all';
  const onClickPrevious = () => {
    setPage(page - 1);
    window.scrollTo({ top: 0 });
  };
  const onClickNext = () => {
    setPage(page + 1);
    window.scrollTo({ top: 0 });
  };

  const handleChangeView: (e: React.ChangeEvent<HTMLInputElement>) => void = e => setSearchParams({ view_id: e.target.value, upload_id });
  const handleChangeUpload: (e: React.ChangeEvent<HTMLInputElement>) => void = e => setSearchParams({ upload_id: e.target.value, view_id });
  const handleChangeVehicleClass: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    if (!!e) {
      setVehicleClass(e.target.value);
      setPage(0);
    }
  };
  const handleChangeMinAxles: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    if (!!e) {
      setNumAxles(e.target.value);
      setPage(0);
    }
  };
  const handleChangeMinRaisedAxles: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    if (!!e) {
      setNumRaisedAxles(+e.target.value);
      setPage(0);
    }
  };
  const handleChangeMinRaisedAxlesMl: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    if (!!e) {
      setNumRaisedAxlesMl(+e.target.value);
      setPage(0);
    }
  };
  const handleChangeComparisonType: (e: React.ChangeEvent<HTMLInputElement>) => void = e => {
    if (!!e) {
      setComparisonType(e.target.value);
      setPage(0);
    }
  };
  const handleChangeDeltaBefore = (event: React.ChangeEvent<HTMLInputElement>) => setDeltaBefore(+event.target.value);
  const handleChangeDeltaAfter = (event: React.ChangeEvent<HTMLInputElement>) => setDeltaAfter(+event.target.value);
  const handleChangeDetectionWindow = (event: React.ChangeEvent<HTMLInputElement>) => setDetectionWindow(+event.target.value);
  const handleClickModify = () => {
    setEditConfigs(true);
  }; 
  const handleClickCancel = () => {
    setEditConfigs(false);
    setDeltaBefore(site.delta_before);
    setDeltaAfter(site.delta_after);
    setDetectionWindow(site.detection_window);
  }; 

  const queryClient = useQueryClient();
	const editProcess = useMutation(
		async (site: SiteEdit) => editSite(siteId, site),
		{
			onSuccess: () => queryClient.invalidateQueries(['sites', siteId])
		}
	);

  const handleClickApply = () => {
    editProcess.mutate({
      delta_before: delta_before,
      delta_after: delta_after,
      detection_window: detection_window,
    });
    setEditConfigs(false);
    window.location.reload();
  };
  const editConfigsStyle = {
    width: '216px',
    '& .MuiInputBase-input': {
        color: 'black'
    },
    '& .MuiInputBase-root': {
        backgroundColor: editConfigs? 'inherit': '#f5f5f5'
    },
};

  const filterComparison = useCallback<(c: Comparison) => Comparison>(c => {
    const numAxlesString = String(numAxles);
    const minAxles = Number((numAxlesString).includes('>=') ? numAxlesString.replaceAll('>=', '') : numAxlesString);
    const maxAxles =  Number((numAxlesString).includes('>=') ? Number.MAX_VALUE : numAxlesString);
    return {
      ...c,
      matches: c.matches.filter(
        m => {
          if (m.detection && m.observation) {
            if ('all' !== vehicleClass) {
              if ((m.detection.verified_vehicle_class ?? m.detection.vehicle_class) !== vehicleClass) {
                return false;
              }
            }
            if ((m.detection.verified_num_tires ?? Math.max(m.detection.num_tires, 2)) < minAxles || (m.detection.verified_num_tires ?? Math.max(m.detection.num_tires, 2)) > maxAxles) {
              return false;
            }
            if (view?.has_raised_axle_model) {
              if ((m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires_ml ?? 0) < numRaisedAxlesMl) {
                return false;
              }
            }
            if (isSuperuser() || !view?.has_raised_axle_model) {
              if ((m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires) < numRaisedAxles) {
                return false;
              }
            }
            if (['unmatched-detections', 'unmatched-uploads', 'unmatched'].includes(comparisonType)) {
              return false;
            }
            if ('matched' === comparisonType && !isMatched(m)) {
              return false;
            }
            if ('unverified' === comparisonType && !isUnverified(m)) {
              return false;
            }
            if ('missed-detection' === comparisonType && !isMissed(m)) {
              return false;
            }
            if ('discrepancy' === comparisonType && isMatched(m)) {
              return false;
            }
            if ('verified' === comparisonType && !isVerified(m)) {
              return false;
            }
          } else if (m.detection) {
            if ('all' !== vehicleClass) {
              if ((m.detection.verified_vehicle_class ?? m.detection.vehicle_class) !== vehicleClass) {
                return false;
              }
            }
            if ((m.detection.verified_num_tires ?? Math.max(m.detection.num_tires, 2)) < minAxles || (m.detection.verified_num_tires ?? Math.max(m.detection.num_tires, 2)) > maxAxles) {
              return false;
            }
            if (view?.has_raised_axle_model) {
              if ((m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires_ml ?? 0) < numRaisedAxlesMl) {
                return false;
              }
            }
            if (isSuperuser() || !view?.has_raised_axle_model) {
              if ((m.detection.verified_num_raised_tires ?? m.detection.num_raised_tires) < numRaisedAxles) {
                return false;
              }
            }
            if ('unverified' === comparisonType && !!m.detection.verified_num_tires) {
              return false;
            }
            if (!['all', 'unmatched-detections', 'unmatched', 'discrepancy'].includes(comparisonType)) {
              return false;
            }
          } else if (m.observation) {
            if (m.observation.num_tires < minAxles || m.observation.num_tires > maxAxles) {
              return false;
            }
            if (numRaisedAxles > 0) {
              return false;
            }
            if (numRaisedAxlesMl > 0) {
              return false;
            }
            if (!['all', 'unmatched-uploads', 'unmatched', 'discrepancy'].includes(comparisonType)) {
              return false;
            }
          }
          return true;
        }
      )
    };
  }, [vehicleClass, numAxles, numRaisedAxles, numRaisedAxlesMl, comparisonType, view?.has_raised_axle_model]);

  const displayedComparison = !!filteredComparison ? {
    ...filteredComparison,
    matches: filteredComparison.matches.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE)
  } : undefined;

  const handleCompare = useCallback(async () => {
    if (viewId && uploadId) {
      setLoading(true);
      const c = await createComparison({ view_id: viewId, upload_id: uploadId });
      setComparison(c);
      setLoading(false);
      setPage(0);
    }
  }, [viewId, uploadId, setLoading, setComparison]);

  useEffect(() => {
    if (comparison) {
      setFilteredComparison(filterComparison(comparison));
    }
  }, [comparison, filterComparison]);

  useEffect(() => {
      handleCompare();
  }, [handleCompare]);

  const [selectedDetection, setSelectedDetection] = useState<Detection | undefined>();
  const [selectedObservation, setSelectedObservation] = useState<Observation | undefined>();

  const onUnlink: (o: Observation) => Promise<void> = async (o) => {
    try {
      if (o.detection_id) {
        const m = await disassociateObservationDetection(o.id);
        if (comparison) {
          const idx = comparison.matches.findIndex((mm: DetectionObservation) => mm.observation && mm.observation.id === o.id);
          if (idx > -1) {
            const newMatches: DetectionObservation[] = [
              ...comparison.matches.slice(0, idx),
              ...m,
              ...comparison.matches.slice(idx + 1)
            ]
            const c: Comparison = {
              ...comparison,
              matches: newMatches
            };
            setComparison(c);
          }
        }
      }
    } catch(e) {
    }
  };

  const onLinkObservation: (o: Observation) => Promise<void> = async (o) => {
    if (o === selectedObservation) {
      setSelectedObservation(undefined);
    } else {
      if (!!selectedDetection) {
        try {
          const m: DetectionObservation = await associateObservationDetection(o.id, selectedDetection.id);
          if (comparison) {
            const newMatches: DetectionObservation[] = comparison.matches.filter(
              mm => m.detection && (!mm.detection || mm.detection.id !== m.detection.id)
            ).map(
              mm => m.observation && (!mm.observation || mm.observation.id !== m.observation.id) ? mm : m
            );
            const c: Comparison = {
              ...comparison,
              matches: newMatches
            };
            setComparison(c);
          }
          setSelectedDetection(undefined);
        } catch (e) {
        }
      } else {
        setSelectedObservation(o);
      }
    }
  };

  const onLinkDetection: (d: Detection) => Promise<void> = async (d) => {
    if (d === selectedDetection) {
      setSelectedDetection(undefined);
    } else {
      if (!!selectedObservation) {
        try {
          const m: DetectionObservation = await associateObservationDetection(selectedObservation.id, d.id);
          if (comparison) {
            const newMatches: DetectionObservation[] = comparison.matches.filter(
              mm => m.detection && (!mm.detection || mm.detection.id !== m.detection.id)
            ).map(
              mm => m.observation && (!mm.observation || mm.observation.id !== m.observation.id) ? mm : m
            );
            const c: Comparison = {
              ...comparison,
              matches: newMatches
            };
            setComparison(c);
          }
          setSelectedDetection(undefined);
        } catch (e) {
        }
      } else {
        setSelectedDetection(d);
      }
    }
  };

  const onVerified: (d: Detection) => Promise<void> = async (d: Detection) => {
    if (comparison && !!viewId && !!uploadId) {
      const newComparison = {
        ...comparison,
        matches: comparison.matches.map(
          m => ({
            observation: m.observation,
            detection: m.detection && m.detection.id === d.id ? d : m.detection
          })
        )
      };
      setComparison(newComparison);
      const c = await createComparison({ view_id: viewId, upload_id: uploadId });
      setComparison(c);
    }
  };

  return (
    <Card
      sx={{
        width: '100%'
      }}
    >
      <CardContent>
        <Box sx={{ marginTop: '1rem' }}>
          <ViewSelect site_id={siteId} view_type="live" value={viewId ?? ''} onChange={handleChangeView} sx={{ minWidth: '10rem', marginRight: '1rem' }} />
          <UploadSelect site_id={siteId} value={uploadId ?? ''} onChange={handleChangeUpload} sx={{ minWidth: '10rem', marginRight: '1rem' }} />
          <Button variant="contained" disabled={!viewId || !uploadId} onClick={handleCompare}>Compare</Button>
          <FileUpload style={{ float: 'right' }} site_id={siteId} setLoading={setLoading} />
        </Box>
        <Box sx={{ marginTop: '1rem' }}>
          <VehicleClassSelect value={vehicleClass} onChange={handleChangeVehicleClass} />
          <AxleCountSelect value={numAxles} onChange={handleChangeMinAxles} />
          {(isSuperuser() || !view?.has_raised_axle_model) && <RaisedAxleCountSelect value={numRaisedAxles} onChange={handleChangeMinRaisedAxles} />}
          {(isSuperuser() || view?.has_raised_axle_model) && <RaisedAxleCountSelect label={"# Raised Axles" + (isSuperuser() ? " (ML)" : "")} value={numRaisedAxlesMl} onChange={handleChangeMinRaisedAxlesMl} />}
          <ComparisonTypeSelect value={comparisonType} onChange={handleChangeComparisonType} />
        </Box>
        <Box sx={{ marginTop: '1rem', display: 'flex', alignItems: 'center'}}>
          <TextField sx={editConfigsStyle} onChange={handleChangeDeltaBefore} label={'Delta Before'} value={!editConfigs? delta_before: delta_before || ''}   inputProps={{readOnly: !editConfigs,type: 'number', step: '0.1' }}/>
          <TextField sx={editConfigsStyle} style={{ marginLeft: '0.5rem'}} onChange={handleChangeDeltaAfter} label={'Delta After'} value={!editConfigs? delta_after: delta_after || ''} inputProps={{ readOnly: !editConfigs, type: 'number', step: '0.1'}}/>
          <TextField sx={editConfigsStyle} style={{ marginLeft: '0.5rem'}} onChange={handleChangeDetectionWindow} label={'Window'} value={!editConfigs? detection_window: detection_window || ''} inputProps={{ readOnly: !editConfigs, type: 'number', step: '0.1' }}/>
          {!editConfigs && <Button sx={{ marginLeft: '1rem' }} variant="contained" onClick={handleClickModify}>Modify</Button>}
          {editConfigs && 
          <>
          <Button sx={{ marginLeft: '1rem' }} variant="contained" onClick={handleClickApply}>Apply</Button>
          <Button sx={{ marginLeft: '1rem' }} variant="contained" onClick={handleClickCancel}>Cancel</Button>
          </>
          }
        </Box>
        {comparison && <Box sx={{ display: 'flex' }}>
          {filteredComparison && <Box>
            <AuditSummary tenantName={tenantName} matches={comparison.matches} filteredMatches={isFiltered ? filteredComparison.matches : undefined} view={view} />
          </Box>
          }
          <Box>
            <AuditStats tenantName={tenantName} matches={comparison.matches} view={view} />
          </Box>
        </Box>
        }
        <Box>
          <Button disabled={page === 0} onClick={onClickPrevious}>Previous</Button>
          <Button disabled={!!filteredComparison && page >= Math.ceil(filteredComparison.matches.length / PAGE_SIZE) - 1} onClick={onClickNext}>Next</Button>
        </Box>
        {isLoading && <CircularProgress />}
        {!!comparison &&
        <IconButton style={{float: 'right'}} onClick={() => {
          if (view) {
            downloadComparisons(view, comparison);
          }
        }}>
            <Download />
          </IconButton>
        }
        {!isLoading && displayedComparison && <ComparisonList
          comparison={displayedComparison}
          selectedDetection={selectedDetection}
          selectedObservation={selectedObservation}
          onLinkDetection={onLinkDetection}
          onLinkObservation={onLinkObservation}
          onUnlink={onUnlink}
          onVerified={onVerified}
          view={view}
        />}
      </CardContent>
      <CardActions>
        <Button disabled={page === 0} onClick={onClickPrevious}>Previous</Button>
        <Button disabled={!!filteredComparison && page >= Math.ceil(filteredComparison.matches.length / PAGE_SIZE) - 1} onClick={onClickNext}>Next</Button>
      </CardActions> 
    </Card>
  );
};

export default AuditView;

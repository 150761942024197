import React, { useState, useEffect } from 'react';
import { useSearchParams, useHref, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import ViewDetail from '../components/ViewDetail';
import VehicleClassSelect from '../components/VehicleClassSelect';
import AxleCountSelect from '../components/AxleCountSelect';
import RaisedAxleCountSelect from '../components/RaisedAxleCountSelect';
import ScoreCategorySelect from '../components/ScoreCategorySelect';

import { View, DetectionSummary, EdgeProcessConfig } from '../types';
import { getColumnNames } from '../utils';
import { getView, getDetectionByViewAndVehicle, getDetectionSummaryByView } from '../utils/api';
import { isSuperuser } from '../utils/auth';

import ViewSelect from '../components/ViewSelect';
import ArchiveSummary from '../components/archive/Summary';

interface Props {
  siteId: number;
  processConfigs: {[id: number]: EdgeProcessConfig};
}

const ArchiveView: React.FC<Props> = ({ siteId, processConfigs }) => {
  const today = new Date();
  const defaultStartTime = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).substr(-2) + '-' + ('0' + today.getDate()).substr(-2) + 'T00:00';
  const defaultEndTime = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).substr(-2) + '-' + ('0' + today.getDate()).substr(-2) + 'T23:59';
  const [searchParams, setSearchParams] = useSearchParams();
  const view_id = searchParams.get('view_id');
  const searchStart = searchParams.get('start_time');
  const searchEnd = searchParams.get('end_time');
  const vehicleClass = searchParams.get('vehicle_class') ?? "all";
  const numAxles = searchParams.get('num_tires') ?? ">=1";
  const numRaisedTires = searchParams.get('num_raised_tires') ?? "0";
  const numRaisedTiresMl = searchParams.get('num_raised_tires_ml') ?? "0";
  const raisedAxleMismatch = searchParams.get('raised_axle_mismatch') === 'true';
  const vehicleMin = searchParams.get('minimum_vehicle_score') ?? 'any';
  const axleMin = searchParams.get('minimum_tire_score') ?? 'any';
  const vehicleMinMode = searchParams.get('vehicle_minimum_mode') ?? 'any';
  const axleMinMode = searchParams.get('axle_minimum_mode') ?? 'any';
  const vehicleLowestMax = searchParams.get('vehicle_lowest_maximum') ?? 'any';
  const axleLowestMax = searchParams.get('axle_lowest_maximum') ?? 'any';
  const [startTime, setStartTime] = useState<string>(searchStart ?? defaultStartTime);
  const [endTime, setEndTime] = useState<string>(searchEnd ?? defaultEndTime);
  const [vehicleNumber, setVehicleNumber] = useState<string>('');
  const viewId = view_id ? +view_id : undefined;
  const { data: view } = useQuery<View, Error>(['view', viewId], () => getView(viewId ?? -1), { enabled: !!viewId });
  const { data: summaryData } = useQuery<DetectionSummary[]>(['archive-summary', viewId, startTime, endTime], () => getDetectionSummaryByView(viewId ?? -1, startTime, endTime));
  const offset = +(searchParams.get('offset') ?? 0);
  const sort_column = searchParams.get('sort_column') ?? undefined;
  const sort_direction = searchParams.get('sort_direction') === 'asc' ? 'asc' : 'desc';
  const location = useLocation();
  const href = useHref(location);
  const navigate = useNavigate();
  useEffect(() => {
    if (!searchStart) {
      setStartTime(defaultStartTime);
    }
  }, [searchStart, defaultStartTime, setStartTime]);
  useEffect(() => {
    if (!searchEnd) {
      setEndTime(defaultEndTime);
    }
  }, [searchEnd, defaultEndTime, setEndTime]);

  const handleChangeViewId = (event: React.ChangeEvent<HTMLInputElement>) => setSearchParams({ view_id: event.target.value });
  const handleChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setStartTime(event.target.value);
  const handleChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setEndTime(event.target.value);
  const handleChangeVehicleNumber = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setVehicleNumber(event.target.value);
  const handleChangeVehicleClass = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('all' === event.target.value) {
      delete params.vehicle_class;
    } else {
      params.vehicle_class = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeVehicleMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.minimum_vehicle_score;
    } else {
      params.minimum_vehicle_score = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeAxleMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.minimum_tire_score;
    } else {
      params.minimum_tire_score = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeVehicleMinMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.vehicle_minimum_mode;
    } else {
      params.vehicle_minimum_mode = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeAxleMinMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.axle_minimum_mode;
    } else {
      params.axle_minimum_mode = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeVehicleLowestMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.vehicle_lowest_maximum;
    } else {
      params.vehicle_lowest_maximum = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeAxleLowestMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('any' === event.target.value) {
      delete params.axle_lowest_maximum;
    } else {
      params.axle_lowest_maximum = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeMinAxles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('>=0' === event.target.value) {
      delete params.num_tires;
    } else {
      params.num_tires = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeMinRaisedAxles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('0' === event.target.value) {
      delete params.num_raised_tires;
    } else {
      params.num_raised_tires = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeMinRaisedAxlesMl = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if ('0' === event.target.value) {
      delete params.num_raised_tires_ml;
    } else {
      params.num_raised_tires_ml = event.target.value;
    }
    setSearchParams(params);
  };
  const handleChangeRaisedAxleMismatch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event) {
      return;
    }
    let params = getSearchParams();
    if (event.target.checked) {
      params.raised_axle_mismatch = 'true';
    } else {
      delete params.raised_axle_mismatch;
    }
    setSearchParams(params);
  };
  const getSearchParams = () => {
    let params: Record<string, string> = { offset: '0' };
    if (viewId) {
      params.view_id = '' + viewId;
    }
    if (startTime) {
      params.start_time = startTime;
    }
    if (endTime) {
      params.end_time = endTime;
    }
    if ('all' !== vehicleClass) {
      params.vehicle_class = vehicleClass;
    }
    if ('>=0' !== numAxles) {
      params.num_tires = numAxles;
    }
    if ('0' !== numRaisedTires) {
      params.num_raised_tires = numRaisedTires;
    }
    if ('0' !== numRaisedTiresMl) {
      params.num_raised_tires_ml = numRaisedTiresMl;
    }
    if (raisedAxleMismatch) {
      params.raised_axle_mismatch = 'true';
    }
    if ('any' !== vehicleMin) {
      params.minimum_vehicle_score = vehicleMin;
    }
    if ('any' !== axleMin) {
      params.minimum_tire_score = axleMin;
    }
    if ('any' !== vehicleMinMode) {
      params.vehicle_minimum_mode = vehicleMinMode;
    }
    if ('any' !== axleMinMode) {
      params.axle_minimum_mode = axleMinMode;
    }
    if ('any' !== vehicleLowestMax) {
      params.vehicle_lowest_maximum = vehicleLowestMax;
    }
    if ('any' !== axleLowestMax) {
      params.axle_lowest_maximum = axleLowestMax;
    }
    return params;
  };
  const handleClickSearch = () => {
    if (!viewId) {
      return;
    }
    const params = getSearchParams();
    setSearchParams(params);
  }
  const handleClickReset = () => {
    let params: Record<string, string> = {};
    if (viewId) {
      params.view_id = '' + viewId;
    }
    setSearchParams(params);
  };

  const handleClickGoToVehicle = async () => {
    if (view && vehicleNumber) {
      const detection = await getDetectionByViewAndVehicle(view.id, +vehicleNumber);
      if (detection) {
        navigate(`/detections/${detection.id}?return=${encodeURIComponent(href)}`);
      }
    }
  };

  const columnNames = getColumnNames(view?.has_raised_axle_model ?? true, view?.display_columns);
  const hasVehicleClassFilter = columnNames.includes('vehicle_class');
  const hasAxleCountFilter = columnNames.includes('axle_count');
  const hasRaisedAxleCountFilter = columnNames.includes('raised_count');
  const hasRaisedAxleCountMLFilter = columnNames.includes('raised_count_ml');
  const hasVehicleStatsFilters = columnNames.includes('vehicle_stats');
  const hasAxleStatsFilters = columnNames.includes('axle_stats');
  const hasBaseFilters = hasVehicleClassFilter || hasAxleCountFilter || hasRaisedAxleCountFilter || hasRaisedAxleCountMLFilter;
  const hasStatsFilters = hasVehicleStatsFilters || hasAxleStatsFilters;

  return (
    <Stack>
      <Box sx={{ marginTop: '1rem' }}>
        <ViewSelect site_id={siteId} view_type="live" sx={{ minWidth: '10rem', mr: '0.5rem' }} value={viewId ?? ''} onChange={handleChangeViewId} />
        <TextField type="datetime-local" label="Start Time" sx={{ mr: '0.5rem' }} value={startTime} onChange={handleChangeStartTime} />
        <TextField type="datetime-local" label="End Time" sx={{ mr: '0.5rem' }} value={endTime} onChange={handleChangeEndTime} />
        <Button size="large" variant="contained" sx={{ mr: '0.5rem' }} onClick={handleClickSearch}>Search</Button>
        <Button size="large" variant="contained" onClick={handleClickReset}>Reset</Button>
      </Box>
      {hasBaseFilters && <Box sx={{ marginTop: '1rem' }}>
        {hasVehicleClassFilter && <VehicleClassSelect value={vehicleClass} onChange={handleChangeVehicleClass} />}
        {hasAxleCountFilter && <AxleCountSelect value={numAxles} onChange={handleChangeMinAxles} />}
        {hasRaisedAxleCountFilter && <RaisedAxleCountSelect value={numRaisedTires} onChange={handleChangeMinRaisedAxles} />}
        {hasRaisedAxleCountMLFilter && <RaisedAxleCountSelect label={"# Raised Axles" + (isSuperuser() ? " (ML)" : "")} value={numRaisedTiresMl} onChange={handleChangeMinRaisedAxlesMl} />}
        {hasRaisedAxleCountFilter && hasRaisedAxleCountMLFilter &&
        <FormControlLabel
          control={<Checkbox checked={raisedAxleMismatch} onChange={handleChangeRaisedAxleMismatch} />}
          label="Raised Axle Mismatch"
        />}
      </Box>}
      {hasStatsFilters && <Box sx={{ marginTop: '1rem' }}>
        {hasVehicleStatsFilters && <ScoreCategorySelect value={vehicleMinMode} onChange={handleChangeVehicleMinMode} label="Vehicle Min Mode" />}
        {hasVehicleStatsFilters && <ScoreCategorySelect value={vehicleMin} onChange={handleChangeVehicleMin} label="Vehicle Min Score" />}
        {hasVehicleStatsFilters && <ScoreCategorySelect value={vehicleLowestMax} onChange={handleChangeVehicleLowestMax} label="Vehicle Lowest Max" />}
        {hasAxleStatsFilters && <ScoreCategorySelect value={axleMinMode} onChange={handleChangeAxleMinMode} label="Axle Min Mode" />}
        {hasAxleStatsFilters && <ScoreCategorySelect value={axleMin} onChange={handleChangeAxleMin} label="Axle Min Score" />}
        {hasAxleStatsFilters && <ScoreCategorySelect value={axleLowestMax} onChange={handleChangeAxleLowestMax} label="Axle  Lowest Max" />}
      </Box>}
      {view  && <Box sx={{ marginTop: '1rem' }}>
        <TextField size="small" type="text" label="Vehicle #" sx={{ marginLeft: '1rem', marginRight: '1rem' }} value={vehicleNumber}  onChange={handleChangeVehicleNumber} />
        <Button size="small" variant="contained" onClick={handleClickGoToVehicle}>Go</Button>
      </Box>}
      {!!summaryData && <ArchiveSummary detections={summaryData} has_raised_axle_model={view?.has_raised_axle_model} />}

      {view && <ViewDetail
        view={view}
        start_time={searchParams.get('start_time') ?? defaultStartTime}
        end_time={searchParams.get('end_time') ?? defaultEndTime}
        vehicle_class={vehicleClass !== 'all' ? vehicleClass : undefined}
        min_axles={Number(numAxles.includes('>=') ? numAxles.replaceAll('>=', '') : numAxles)}
        max_axles={numAxles.includes('>=') ? undefined : +numAxles}
        min_raised_axles={!!numRaisedTires ? +numRaisedTires : undefined}
        min_raised_axles_ml={!!numRaisedTiresMl ? +numRaisedTiresMl : undefined}
        raised_axle_mismatch={raisedAxleMismatch}
        minimum_vehicle_score={vehicleMin !== 'any' ? vehicleMin : undefined}
        minimum_tire_score={axleMin !== 'any' ? axleMin : undefined}
        vehicle_minimum_mode={vehicleMinMode !== 'any' ? vehicleMinMode : undefined}
        axle_minimum_mode={axleMinMode !== 'any' ? axleMinMode : undefined}
        vehicle_lowest_maximum={vehicleLowestMax !== 'any' ? vehicleLowestMax : undefined}
        axle_lowest_maximum={axleLowestMax !== 'any' ? axleLowestMax : undefined}
        limit={100}
        offset={offset}
        sort_column={sort_column}
        sort_direction={sort_direction}
        has_raised_axle_model={view.has_raised_axle_model}
        processConfigs={processConfigs}
      />}
    </Stack>
  );
};

export default ArchiveView;

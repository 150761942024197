import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getProcessesBySite } from '../utils/api';

interface  ProcessSelectProps {
	siteId: number;
  cameraId?: number;
  includeNone?: boolean;
}

const ProcessSelect: React.FC<ProcessSelectProps & TextFieldProps> = ({ siteId, cameraId, includeNone = false, ...props }) => {
	const { data } = useQuery(['processes', siteId], () => getProcessesBySite(siteId));

	if (!data) {
    return <span>None</span>;
  }

  const available = cameraId ? data.filter(p => p.camera_id === cameraId) : data;
  if (available.length === 0) {
    return <span>None</span>;
	}
  if (props.disabled) {
    const process = available.find(p => p.id === props.value);
    if (!process) {
      return <span>None</span>;
    }
    return <span>{process.number}: {process.name}</span>;
  }
	return (
		<TextField select label="Process" variant="standard" {...props}>
      {includeNone && (
        <MenuItem value={-1}>
          None
        </MenuItem>
      )}
			{available.sort((a, b) => a.number - b.number).map((process) => (
				<MenuItem key={process.id} value={process.id}>
          {process.number}: {process.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default ProcessSelect;

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routes, Route } from 'react-router-dom';

import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';

import SignInPage from './pages/SignInPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import AccountInformationPage from './pages/AccountInformationPage';
import MultifactorAuthenticationSetupPage from './pages/MultifactorAuthenticationSetupPage';
import DashboardPage from './pages/DashboardPage';
import SiteRoute from './pages/SiteRoute';
import DetectionRoute from './pages/DetectionRoute';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    }
  }
});

const App: React.FC = () => {
  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/login" element={<SignInPage />} />
          <Route path="/request-password-reset" element={<ForgotPasswordPage />} />
          <Route path="/password-reset" element={<ResetPasswordPage />} />
          <Route path="/mfa-setup" element={<MultifactorAuthenticationSetupPage />} />
          <Route path="/account-information" element={<AccountInformationPage />} />
          <Route path="/sites/:site_id" element={<SiteRoute />}>
            <Route path=":siteView" element={<SiteRoute />} />
            <Route path="*" element={<SiteRoute />} />
          </Route>
          <Route path="/detections/:detection_id" element={<DetectionRoute />} />
        </Routes>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

export default App;

import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getCameras } from '../utils/api';

type CameraSelectProps = { siteId?: number; } & TextFieldProps;


const CameraSelect: React.FC<CameraSelectProps> = (props: CameraSelectProps) => {
	const { data } = useQuery('cameras', getCameras);

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Camera" variant="standard" {...props}>
			{data.filter(
        c => !props.siteId || c.site_id === props.siteId
      ).sort(
        (a, b) => a.name.localeCompare(b.name)
      ).map(
        (camera) => (
  				<MenuItem key={camera.id} value={camera.id}>
  					{camera.name}
  				</MenuItem>
  			)
      )}
		</TextField>
	);
};

export default CameraSelect;

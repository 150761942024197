import React from 'react';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import CameraConfigDisplay from './CameraConfigDisplay';

import { Camera, CameraConfig, CameraModel, Processor } from '../types';
import { getCamerasBySite, getCameraModels, getCameraConfigs, getProcessorsBySite } from '../utils/api';
import { getPositionDisplay, getLocationDisplay } from '../utils';

interface CameraConfigMap {
	[id: number]: CameraConfig;
}

interface CameraModelMap {
	[id: number]: CameraModel;
}

interface ProcessorMap {
	[id: number]: Processor;
}

interface CameraListProps {
	siteId: number;
}

const CameraList: React.FC<CameraListProps> = ({ siteId }) => {
	const { isLoading, isError, data } = useQuery<Camera[], Error>(['cameras', siteId], () => getCamerasBySite(siteId));
	const { data: config_data } = useQuery<CameraConfig[], Error>('camera-configs', getCameraConfigs);
	const { data: model_data } = useQuery<CameraModel[], Error>('camera-models', getCameraModels);
	const { data: processor_data } = useQuery<Processor[], Error>(['processors', siteId], () => getProcessorsBySite(siteId));

	const configs: CameraConfigMap = config_data ? config_data.reduce((o, v) => ({ ...o, [v.id]: v}), {}) : {};
	const models: CameraModelMap = model_data ? model_data.reduce((o, v) => ({ ...o, [v.id]: v}), {}) : {};
	const processors: ProcessorMap = processor_data ? processor_data.reduce((o, v) => ({ ...o, [v.id]: v}), {}) : {};

	if (isLoading) {
		return <CircularProgress />;
	}
	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>;
	}
	if (!data || data.length === 0) {
		return <Typography>There are no cameras.</Typography>;
	}

	return (
		<Table aria-label="Cameras">
			<TableBody>
				{data.sort((a, b) => a.name.localeCompare(b.name)).map(camera => <TableRow key={camera.id}>
					  <TableCell>{camera.name}</TableCell>
					  <TableCell>{getLocationDisplay(camera.location)}</TableCell>
					  <TableCell>{getPositionDisplay(camera.position)}</TableCell>
						<TableCell>{processors[camera.processor_id]?.name}</TableCell>
						<TableCell>{models[camera.camera_model_id]?.model_number}</TableCell>
						<TableCell><CameraConfigDisplay config={configs[camera.camera_config_id]} /></TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default CameraList;

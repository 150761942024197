import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ReferenceLine, Tooltip, Label } from 'recharts';
import { Detection, BaseStats } from '../types';

interface Props {
  detection: Detection;
}

function getMode(distribution: number[], gap: number = 1): number {
  const min = 100 - distribution.length;
  return min + gap * distribution.lastIndexOf(Math.max(...distribution));
}

function getIndices(distribution: number[]): [[number, number], [number, number], [number, number]] {
  const floor = Math.floor(distribution.length / 3);
  return [[0, floor], [floor, distribution.length - floor], [distribution.length - floor, distribution.length]];
}

function getLowMidHigh(distribution: number[]): [number, number, number] {
  const [low, mid, high] = getIndices(distribution);
  return [
    distribution.slice(...low).reduce((a, b) => a + b, 0),
    distribution.slice(...mid).reduce((a, b) => a + b, 0),
    distribution.slice(...high).reduce((a, b) => a + b, 0)
  ];
}

interface ChartData {
  name: string;
  range: number;
  value: string;
  fill: string;
}

function getChartData(stats: BaseStats): [ChartData, ChartData, ChartData] {
  const [low, mid, high] = getLowMidHigh(stats.distribution);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [low_indices, mid_indices, high_indices] = getIndices(stats.distribution);
  const low_lower = 100 - stats.distribution.length;
  const low_upper = 100 - stats.distribution.length + low_indices[1];
  const high_lower = 100 - stats.distribution.length + high_indices[0];
  const high_upper = 100;
  const count = stats.detection_count ?? 0;
  return [{
    name: `(Low)`,
    range: (low_upper - low_lower) / 2 + low_lower,
    value: count ? (low / count * 100).toFixed(0) : '0',
    fill: "lightBlue"
  }, {
    name: `(Medium)`,
    range: (high_lower - low_upper) / 2 + low_upper,
    value: count ? (mid / count * 100).toFixed(0) : '0',
    fill: "#FFD580"
  }, {
    name: `(High)`,
    range: (high_upper - high_lower) / 2 + high_lower,
    value: count ? (high / count * 100).toFixed(0) : '0',
    fill: "lightGreen"
  }];
}

const Legend = () => (
  <div
    style={{
      display: "flex",
      position: "absolute",
      left: "250px",
      justifyContent: "space-between",
      top: "-40px",
      fontSize: "small",
      width: "50%"
    }}
  > 
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}> 
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: 'lightBlue',
          marginRight: '5px'
        }}
      /> 
      <span>(Low)</span> 
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}> 
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: '#FFD580',
          marginRight: '5px'
        }}
      /> 
      <span>(Medium)</span> 
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}> 
      <div
        style={{
          width: "20px",
          height: "20px",
          backgroundColor: 'lightGreen',
          marginRight: '5px'
        }}
      /> 
      <span>(High)</span> 
    </div>
  </div>
);

const VehicleStatsGraph: React.FC<Props> = ({ detection }) => {
  if (!detection.statistics) {
    return <div>Loading the statistics</div>;
  }

  const vehicleStats = detection.statistics;
  const vehicleMode = getMode(vehicleStats.distribution);
  const chartData = getChartData(vehicleStats);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
  
      if (label === vehicleStats.mean_score * 100) {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '1px' }}>
            <p>Mean</p>
          </div>
        );
      } else if (label === vehicleStats.median_score * 100) {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '1px' }}>
            <p>Median</p>
          </div>
        );
      } else {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '1px' }}>
            <p>{data.value}%</p>
          </div>
        );
      }
    }
    return null;
  };

  // const domain = [100 - vehicleStats.distribution, 100];

  return (
    <Card elevation={0}>
      <CardContent >
        <Typography
          align="left"
          style={{
            fontSize: "small",
            margin: "10px",
            padding: "0px",
            color: "black"
          }}
        >
          <b>Number of frames: </b>
          <span
            style={{ 
              fontSize: "small",
              margin: "0px",
              padding: "0px",
              color: "#609966"
            }}
          >
            {vehicleStats.frame_count}
          </span>
        </Typography>
        <Typography
          align="left"
          style={{
            fontSize: "small",
            margin: "10px",
            marginBottom: "40px",
            padding: "0px",
            color: "black"
          }}
        >
          <b>Vehicle Detections: </b>
          <span
            style={{ 
              fontSize: "small",
              margin: "0px",
              padding: "0px",
              color: "#609966"
            }}
          >
            <b>{vehicleStats.detection_count}</b>
          </span>
        </Typography>
        <BarChart
          width={950}
          height={150}
          data={chartData}  
          margin={{
            top: 20,
            right: 0,
            left: 0,
            bottom: 0
          }}
        >
          <CartesianGrid strokeDasharray="4" />
          <Bar barSize={650} dataKey="value" />
          <XAxis
            dataKey="range"
            type="number"
            domain={[85, 100]}
            ticks={[85, 90, 91, 92, 93, 94, 95, 96, 97 ,98, 99, 100]}
          />
          <YAxis domain={[0, 100]} />
          <ReferenceLine
            x={vehicleStats.mean_score * 100}
            stroke ="red"
            strokeWidth={2}
          >
            <Label value="Mean" position="insideTopRight" offset={5}/>
          </ReferenceLine>
          <ReferenceLine
            x={vehicleMode}
            stroke="blue"
            strokeWidth={2}
          >
            <Label value="Mode" position="insideTopLeft"/>
          </ReferenceLine>
          <ReferenceLine
            x={vehicleStats.maximum_score * 100}
            stroke ="black"
            strokeWidth={2}
          >
            <Label value="Highest" position="top" offset={10}/>
          </ReferenceLine>
          <ReferenceLine
            x={vehicleStats.median_score * 100}
            stroke ="green"
            strokeWidth={2}
          >
            <Label value="Median" position="top" offset={2}/>
          </ReferenceLine>
          <Tooltip cursor={{ visibility: "default", width:'10px' }} content={<CustomTooltip />} />
          <Legend />
        </BarChart>
        <Typography
          align="center"
          style={{fontSize:'small', margin:'0 10em', color:'black'}}
        >
          Vehicle
        </Typography>
        {detection.statistics.tires.map((tireStats, index) => (
          <TireBarChart
            key={index}
            tireStats={tireStats}
            tireNumber={index + 1}
          />
        ))}
      </CardContent>
    </Card>
  );
};

export default VehicleStatsGraph;

const TireBarChart: React.FC<{ tireStats: BaseStats; tireNumber: number }> = ({ tireStats, tireNumber }) => {
  const tireMode = getMode(tireStats.distribution);
  const tireData = getChartData(tireStats);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      if (label === tireStats.mean_score * 100) {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '1px' }}>
            <p>Mean</p>
          </div>
        );
      } else if (label === tireStats.median_score * 100) {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '1px' }}>
            <p>Median</p>
          </div>
        );
      } else {
        return (
          <div style={{ backgroundColor: '#FFFF8D', padding: '0px' }}>
            <p>{data.value}%</p>
          </div>
        );
      }
    }
    return null;
  };

  return (
    <Card elevation={0}>
      <CardContent style={{ height: '100%', paddingTop: '0px', padding: '0px' }}>
        <Typography
          align="left"
          style={{
            fontSize: 'small',
            margin: "5px",
            padding: '0px',
            color: 'black'
          }}
        >
          <b>Axle {tireNumber} Detections: </b>
          <span
            style={{
              fontSize: 'small',
              margin: "0px",
              padding: '0px',
              color: '#609966'
            }}
          >
            <b> {tireStats.detection_count}</b>
          </span>
        </Typography>
        <BarChart
          width={950}
          height={120}
          data={tireData}
          margin={{ top: 30, right: 0, left: 0, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="4" />
          <Bar barSize={650} dataKey="value" fill="#8884d8" />
          <XAxis
            dataKey="range"
            type="number"
            domain={[85, 100]}
            ticks={[85, 90 ,91, 92, 93, 94, 95, 96, 97, 98, 99, 100]}
          />
          <YAxis domain={[0, 100]} />
          <ReferenceLine
            x={tireStats.mean_score * 100}
            stroke ="red"
            strokeWidth={2}
          >
            <Label value="Mean" position="insideTopRight" offset={5}/>
          </ReferenceLine>
          <ReferenceLine
            x={tireMode}
            stroke ="blue"
            strokeWidth={2}
          >
            <Label value="Mode" position="insideBottomLeft" />
          </ReferenceLine>
          <ReferenceLine
            x={tireStats.maximum_score * 100}
            stroke ="black"
            strokeWidth={2}
          >
            <Label value="Highest" position="top" offset={15}/>
          </ReferenceLine>
          <ReferenceLine
            x={tireStats.median_score * 100}
            stroke ="green"
            strokeWidth={2}
          >
            <Label value="Median" position="top" offset={2}/>
          </ReferenceLine>
          <Tooltip cursor={{ visibility: "default", width:'10px' }} content={<CustomTooltip />} />
        </BarChart>
        <Typography
          align="center"
          style={{
            fontSize: 'small',
            padding: '0px',
            color: 'black'
          }}
        >
          
        </Typography>
      </CardContent>
    </Card>
  );
};

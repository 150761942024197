import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { DetectionDetail } from '../types';
import { getDetection } from '../utils/api';

import ErrorPage from './ErrorPage';
import LoadingPage from './LoadingPage';
import DetectionPage from './DetectionPage';

const DetectionRoute: React.FC = () => {
	const { detection_id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchParams, _] = useSearchParams();
	const detectionId = detection_id ? +detection_id : -1; 

	const { isLoading: isDetectionLoading, isError: isDetectionError, data: detection } = useQuery<DetectionDetail, Error>(
		['detections', detectionId],
		() => getDetection(detectionId),
		{ enabled: detectionId !== -1, refetchInterval: 30 * 1000 }
	);

	if (detectionId === -1) {
		return <ErrorPage message="No detection specified." />;
	}

	if (isDetectionError) {
		return <ErrorPage message="Unknown detection." />;
	}

	if (isDetectionLoading) {
		return <LoadingPage title="Detection" />;
	}

	if (!detection) {
		return <ErrorPage message="Unable to load detection." />;
	}

	const return_url = searchParams.get('return') ?? `/sites/${detection.site_id}`;

	return <DetectionPage detection={detection} return_url={return_url} />;
};

export default DetectionRoute;

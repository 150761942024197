import React from 'react';

type Callback = () => void;

const useInterval = (callback: Callback, delay: number) => {
  const savedCallback = React.useRef<Callback>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
			if (savedCallback.current) {
        savedCallback.current();
			}
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;

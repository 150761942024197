import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Detection } from '../../types';
import { formatDate } from '../../utils';

interface ListProps {
  view1Detections: Detection[];
  view2Detections: Detection[];
  offset: number;
}

interface RowProps {
  detection: Detection;
  index: number; 
};

const VIEW_COLORS = ['#daf5e4', '#dae4f5'];

const Row: React.FC<RowProps> = ({
  detection,
  index
}) => {
  const sx = {
    backgroundColor: VIEW_COLORS[index % 2]
  };

  return (
    <TableBody>
      <TableRow>
        <TableCell sx={sx}>{detection.vehicle_number}</TableCell>
        <TableCell sx={sx}>{detection.vehicle_class}</TableCell>
        <TableCell sx={sx}>{formatDate(detection.start_time)}</TableCell>
        <TableCell sx={sx}>{formatDate(detection.end_time)}</TableCell>
        <TableCell sx={sx}>{detection.num_tires}</TableCell>
      </TableRow>
    </TableBody>
  );
};

const List: React.FC<ListProps> = ({
  view1Detections,
  view2Detections,
  offset
}) => {
  const useOffset: {[id: number]: boolean} = view2Detections.reduce(
    (o, d) => ({ ...o, [d.id]: true }),
    {}
  );
  const detections: Detection[] = view1Detections.concat(view2Detections);
  detections.sort((a, b) => {
    let aValue: number = a.start_time.valueOf();
    let bValue: number = b.start_time.valueOf();
    if (useOffset[a.id]) {
      aValue += offset * 60000;
    }
    if (useOffset[b.id]) {
      bValue += offset * 60000;
    }
    return aValue - bValue;
  });
  return (
    <Table aria-label="Compare Detections">
      <TableHead>
        <TableRow>
          <TableCell>Vehicle ID</TableCell>
          <TableCell>Class</TableCell>
          <TableCell>Start</TableCell>
          <TableCell>End</TableCell>
          <TableCell># Axles</TableCell>
        </TableRow>
      </TableHead>
      {detections.map(
        d => <Row key={d.id} detection={d} index={useOffset[d.id] ? 1 : 0} />
      )}
    </Table>
  );
};

export default List;

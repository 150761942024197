import React, { useState, ChangeEvent } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { Box, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import VerifiedIcon from "@mui/icons-material/Verified";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { Detection, Site } from "../types";
import { updateDetectionVerification, getSite } from "../utils/api";
import { isDetectionVerified } from "../utils";

interface VerifyDetectionProps {
  detection: Detection;
  onVerified?: (d: Detection) => void;
}

const VerifyDetection: React.FC<VerifyDetectionProps> = ({ detection, onVerified }) => {

  const siteId = detection.site_id;

	const { isLoading: isSiteLoading, isError: isSiteError, data: site } = useQuery<Site, Error>(
		['sites', siteId],
    () => getSite(siteId),
		{ enabled: siteId !== -1 }
	);

  const [verifiedVehicleClass, setVerifiedVehicleClass] = useState<string | null>(detection.verified_vehicle_class);
  const [verifiedVehicleSubClass, setVerifiedVehicleSubClass] = useState<string | null>(detection.verified_vehicle_subclass);
  const [verifiedNumTires, setVerifiedNumTires] = useState<number | null>(detection.verified_num_tires);
  const [verifiedNumRaisedTires, setVerifiedNumRaisedTires] = useState<number | null>(detection.verified_num_raised_tires);
  const [verifiedAssociatedVehicleNumbers, setVerifiedAssociatedVehicleNumbers] = useState<number[] | null>(detection.verified_associated_vehicle_numbers?.length !== 0 ? detection.verified_associated_vehicle_numbers : [0] );
  const [verifiedAssociationType, setVerifiedAssociationType] = useState<string | null>(detection.verified_association_type);
  const [isDisqualified, setIsDisqualified] = React.useState<boolean>(verifiedAssociationType === "Disqualified");
  const isVerified = isDetectionVerified(detection);
  const hasVehicleClass = detection.vehicle_class !== "Vehicle";
  const hasVehicleSubClass = site && Array.isArray(site.vehicle_subclasses) && site.vehicle_subclasses ? site.vehicle_subclasses.length > 0 : false;
  const canEdit =
  ((detection.verified_vehicle_class != null || detection.verified_vehicle_subclass !== null) && detection.verified_num_tires != null) ||
    (detection.verified_vehicle_class === null &&
      detection.verified_vehicle_subclass === null &&
      detection.verified_association_type !== "Duplicate" &&
      detection.verified_association_type !== "Split") ||
    detection.verified_association_type === "Disqualified";
  const canVerify =
    hasVehicleClass == !!verifiedVehicleClass &&
    hasVehicleSubClass == !!verifiedVehicleSubClass &&
    verifiedNumTires !== null &&
    verifiedNumRaisedTires !== null &&
    (verifiedAssociationType === "Unique" || (verifiedAssociatedVehicleNumbers != null && verifiedAssociatedVehicleNumbers[0] != 0 && verifiedAssociationType != null));
  const changed =
    verifiedVehicleClass !== detection.verified_vehicle_class ||
    verifiedVehicleSubClass !== detection.verified_vehicle_subclass ||
    verifiedNumTires !== detection.verified_num_tires ||
    verifiedNumRaisedTires !== detection.verified_num_raised_tires ||
    verifiedAssociatedVehicleNumbers !== detection.verified_associated_vehicle_numbers ||
    verifiedAssociationType !== detection.verified_association_type;
  const queryClient = useQueryClient();
  const verifyDetection = useMutation(updateDetectionVerification, {
    onSuccess: (data, variables, context) => {
      if (onVerified) {
        onVerified(data);
      }

      queryClient.invalidateQueries(["detections", detection.id]);
    },
  });
  const handleVerify = () => {
    if (canVerify) {
      verifyDetection.mutate({
        id: detection.id,
        verified_vehicle_class: verifiedVehicleClass,
        verified_vehicle_subclass: verifiedVehicleSubClass,
        verified_num_tires: verifiedNumTires,
        verified_num_raised_tires: verifiedNumRaisedTires,
        verified_associated_vehicle_numbers: verifiedAssociatedVehicleNumbers,
        verified_association_type: verifiedAssociationType,
      });
    }
  };

  const [items, setItems] = useState<number[]>(verifiedAssociatedVehicleNumbers ?? [0]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleChangeVerifiedVehicleClass = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedVehicleClass(event.target.value);
  const handleChangeVerifiedVehicleSubClass = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedVehicleSubClass(event.target.value);
  const handleChangeVerifiedNumTires = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedNumTires(+event.target.value);
  const handleChangeVerifiedNumRaisedTires = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedNumRaisedTires(+event.target.value);
  const handleChangeVerfiedAssociationType = (event: React.ChangeEvent<HTMLInputElement>) => setVerifiedAssociationType(event.target.value);

  const handleClickDisqualify = () => {
    setVerifiedVehicleClass(!isDisqualified ? detection.vehicle_class : null);
    setVerifiedVehicleSubClass(!isDisqualified ? detection.vehicle_subclass : null);
    setVerifiedNumTires(!isDisqualified ? detection.num_tires : null);
    setVerifiedNumRaisedTires(!isDisqualified ? detection.num_raised_tires : null);
    setVerifiedAssociationType(!isDisqualified ? "Disqualified" : null);
    setVerifiedAssociatedVehicleNumbers(!isDisqualified ? [] : null);
    setIsDisqualified(!isDisqualified);
    setItems([0]);
  };

  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.target.value;
    const newValue = value === "" ? 0 : Number(value);
    const newItems = [...items];
    newItems[index] = newValue;
    const filteredItems = newItems.filter((value) => value !== 0);
    setItems(newItems);
    setVerifiedAssociatedVehicleNumbers(filteredItems.length === 0 ? null : filteredItems);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const addItem = () => {
    setItems([...items, 0]);
  };

  const removeItem = (index: number) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  return (
    <Card
      sx={{
        width: "230px",
      }}
    >
      <CardHeader
        title={<span style={{ display: "flex", alignItems: "center" }}> Verify Detection {(isVerified || !canEdit || detection.verified_association_type === "Disqualified") && <VerifiedIcon color="success" />}</span>}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          pt: 0,
        }}
      />
      <CardContent sx={{ pt: 1 }}>
        <Stack
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "20ch" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          autoComplete="off"
        >
          <Button
            size="small"
            sx={{
              top: "-10px",
              color: "black",
              fontSize: "12px",
              backgroundColor: isDisqualified ? "#d0f0c0" : "#ffb6c1",
              "&:hover": {
                backgroundColor: isDisqualified ? "#d0f0c0" : "#ffb6c1",
              },
            }}
            onClick={handleClickDisqualify}
          >
            {isDisqualified ? "Restore" : "Disqualify"}
          </Button>
          {!isDisqualified && (
            <>
              {hasVehicleClass && <TextField
                select
                label={verifiedVehicleClass != null ? "Vehicle Class" : undefined}
                helperText={verifiedVehicleClass == null ? "Vehicle Class" : undefined}
                value={verifiedVehicleClass ?? ""}
                onChange={handleChangeVerifiedVehicleClass}
                disabled={!canEdit}
                InputProps={{
                  style: { height: "35px", fontSize: "14px" },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: "14px", marginTop: "-28px" },
                }}
              >
                <MenuItem value="Car" sx={{ fontSize: "14px" }}>
                  Car
                </MenuItem>
                <MenuItem value="Truck" sx={{ fontSize: "14px" }}>
                  Truck
                </MenuItem>
                <MenuItem value="Bus" sx={{ fontSize: "14px" }}>
                  Bus
                </MenuItem>
                <MenuItem value="Motorcycle" sx={{ fontSize: "14px" }}>
                  Motorcycle
                </MenuItem>
              </TextField>
              }
              {site && hasVehicleSubClass && (
                <TextField
                  select
                  label={verifiedVehicleSubClass != null ? "Toll Class" : undefined}
                  helperText={verifiedVehicleSubClass == null ? "Toll Class" : undefined}
                  value={verifiedVehicleSubClass ?? ""}
                  onChange={handleChangeVerifiedVehicleSubClass}
                  disabled={!canEdit}
                  InputProps={{
                    style: { height: "35px", fontSize: "14px" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px" },
                  }}
                  FormHelperTextProps={{
                    style: { fontSize: "14px", marginTop: "-28px" },
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                          overflow: 'auto',
                        },
                      },
                    },
                  }}
                >
                  {site.vehicle_subclasses.map((subclass) => (
                    <MenuItem key={subclass.name} value={subclass.name} sx={{ fontSize: "14px" }}>
                      {subclass.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}

              <TextField
                label={verifiedNumTires != null ? "# Axles" : undefined}
                helperText={verifiedNumTires == null ? "# Axles" : undefined}
                value={verifiedNumTires ?? ""}
                onChange={handleChangeVerifiedNumTires}
                disabled={!canEdit}
                InputProps={{
                  style: { height: "35px", fontSize: "14px" },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: "14px", marginTop: "-28px" },
                }}
              />
              <TextField
                label={verifiedNumRaisedTires != null ? "# Raised Axles" : undefined}
                helperText={verifiedNumRaisedTires == null ? "# Raised Axles" : undefined}
                value={verifiedNumRaisedTires ?? ""}
                onChange={handleChangeVerifiedNumRaisedTires}
                disabled={!canEdit}
                InputProps={{
                  style: { height: "35px", fontSize: "14px" },
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: "14px" },
                }}
                FormHelperTextProps={{
                  style: { fontSize: "14px", marginTop: "-28px" },
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  display: "inline-block",
                  width: "fit-content",
                  marginTop: "50px",
                }}
              >
                <Typography
                  sx={{
                    position: "absolute",
                    top: "-35px",
                    backgroundColor: "white",
                    px: "8px",
                    lineHeight: 1,
                    whiteSpace: "nowrap",
                    fontSize: "15px",
                    textAlign: "center",
                    left: "6%",
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      left: "-40px",
                      bottom: "-5px",
                      width: "200%",
                      borderBottom: "1px solid #ccc",
                    },
                  }}
                >
                  {"Associated Vehicles"}
                </Typography>
                <TextField
                  select
                  label={verifiedAssociationType != null ? "Association Type" : undefined}
                  helperText={verifiedAssociationType == null ? "Association Type" : undefined}
                  value={verifiedAssociationType ?? ""}
                  onChange={handleChangeVerfiedAssociationType}
                  disabled={!canEdit}
                  InputProps={{
                    style: { height: "35px", fontSize: "14px" },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: "14px" },
                  }}
                  FormHelperTextProps={{
                    style: { fontSize: "14px", marginTop: "-28px" },
                  }}
                >
                  <MenuItem></MenuItem>
                  <MenuItem value="Duplicate" sx={{ fontSize: "14px" }}>
                    Duplicate
                  </MenuItem>
                  <MenuItem value="Split" sx={{ fontSize: "14px" }}>
                    Split
                  </MenuItem>
                  <MenuItem value="Unique" sx={{ fontSize: "14px" }}>
                    Unique
                  </MenuItem>
                </TextField>

                {verifiedAssociationType !== "Unique" && (
                  <>
                    {isVerified && !changed && !expanded ? (
                      <Box style={{ display: "flex", alignItems: "center" }} sx={{ marginBottom: "-21px" }}>
                        <TextField
                          variant="outlined"
                          label="Associated ID(s)"
                          value={items[0] || ""}
                          onChange={(e) => handleChange(0, e)}
                          fullWidth
                          disabled={!canEdit}
                          InputProps={{
                            style: { height: "35px", fontSize: "14px" },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: { fontSize: "14px" },
                          }}
                        />
                        {items.length > 1 && (
                          <Button
                            variant="contained"
                            onClick={handleExpand}
                            sx={{
                              position: "absolute",
                              right: "-20px",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "0",
                              justifyContent: "center",
                              lineHeight: "24px",
                              fontSize: "24px",
                            }}
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        )}
                      </Box>
                    ) : (
                      items.map((item, index) => (
                        <Box key={index} style={{ marginBottom: "-16px", display: "flex", alignItems: "center" }}>
                          <TextField
                            variant="outlined"
                            label={index === 0 && !(items.length === 1 && items[0] === 0) ? "Associated ID(s)" : undefined}
                            helperText={items.length === 1 && items[0] === 0 ? "Associated ID(s)" : undefined}
                            value={item || ""}
                            onChange={(e) => handleChange(index, e)}
                            onBlur={() => {
                              if (items[index] === 0 && items.length > 1) {
                                removeItem(index);
                              }
                            }}
                            fullWidth
                            disabled={!canEdit}
                            InputProps={{
                              style: { height: "35px", fontSize: "14px" },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: { fontSize: "14px" },
                            }}
                            FormHelperTextProps={{
                              style: { fontSize: "14px", marginTop: index === 0 ? "-28px" : undefined },
                            }}
                          />
                          {index === items.length - 1 && item !== 0 && (
                            <Button
                              variant="contained"
                              onClick={changed ? addItem : handleExpand}
                              sx={{
                                position: "absolute",
                                right: "-20px",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                minWidth: "auto",
                                padding: "0",
                                justifyContent: "center",
                                lineHeight: "24px",
                                fontSize: "24px",
                              }}
                            >
                              {changed ? "+" : <ArrowDropUpIcon />}
                            </Button>
                          )}
                        </Box>
                      ))
                    )}
                  </>
                )}
              </Box>
            </>
          )}
        </Stack>
      </CardContent>
      {canVerify && changed && (
        <CardActions
          sx={{
            justifyContent: "center",
          }}
        >
          <Button
            size="small"
            sx={{
              fontsize: "12px",
              color: "white",
              backgroundColor: "#1976d2",
              "&:hover": {
                backgroundColor: "#1976d2",
              },
            }}
            onClick={handleVerify}
          >
            Confirm
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

export default VerifyDetection;

import React from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';



import { DetectionObservation, View } from '../../types';

import { isMatched, isUnverified, isVerified } from '../../utils';
import { isSuperuser } from '../../utils/auth';

import { COLOR_UNVERIFIED_DETECTION, COLOR_VERIFIED_DETECTION, COLOR_NO_DETECTION, COLOR_NO_OBSERVATION, COLOR_AXLE_MISMATCH } from '../../config';

import {
  getTotalAxleCount,
  getTotalRaisedAxleCount,
  getTotalMissedAxleCount,
  getTotalRaisedAxleCountMl,
  getTotalMissedAxleCountMl
} from './Stats'

interface Props {
  tenantName: string;
  matches: DetectionObservation[];
  filteredMatches?: DetectionObservation[];
  view?: View;
}

const Summary: React.FC<Props> = ({ tenantName, matches, filteredMatches, view }) => {
  
  const uniqueMatches = matches.filter(d => d.detection?.verified_association_type == null ||   
    (d.detection?.verified_vehicle_class != null && d.detection?.verified_association_type != null) 
  )

  let matched: string = '' + uniqueMatches.filter(isMatched).length;
  let unverified: string = '' + uniqueMatches.filter(isUnverified).length;
  let verified: string = '' + uniqueMatches.filter(isVerified).length;
  let detected: string = '' + uniqueMatches.filter(m => !m.observation).length;
  let observed: string = '' + uniqueMatches.filter(m => !m.detection).length;

  let totalUploaded: string = '' + uniqueMatches.filter(m => !!m.observation).length;
  let totalDetected: string = '' + uniqueMatches.filter(m => !!m.detection).length;

  const allDetections = uniqueMatches.filter(m => !!m.detection);
  const allObservations = uniqueMatches.filter(m => !!m.observation);
  const matchedDetections = uniqueMatches.filter(isMatched);
  const detectedDetections = uniqueMatches.filter(m => !m.detection);
  const observedDetections = uniqueMatches.filter(m => !m.observation);
  const verifiedDetections = uniqueMatches.filter(isVerified);

  const unverifiedDetections = uniqueMatches.filter((isUnverified));
  const undercountedLoweredAxleTitle = `lowered axles undercounted by ${tenantName}`;
  const overcountedLoweredAxleTitle = `lowered axles overcounted by ${tenantName}`
  const missedRaisedAxleTitle = `raised axles missed by ${tenantName}`;
  const verifiedDetectionsTitle = `Human validated`;
  const unmatchedTitle = 'Cannot find a reported vehicle within time range';
  const unverifiedDetectionsTitle = 'Found a reported vehicle within a time range but do not mutually agree on total axles';
  const matchedTitle = 'Found a reported vehicle within a time range and mutually agree on total axles';

  const observationUndercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_tires ?? 0) > (m.observation?.num_tires ?? 0)
  ).reduce(
    (t, m) => t + (m.detection?.verified_num_tires ?? 0) - (m.observation?.num_tires ?? 0),
    0
  );
  const observationOvercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_tires ?? 0) < (m.observation?.num_tires ?? 0)
  ).reduce(
    (t, m) => t + (m.observation?.num_tires ?? 0) - (m.detection?.verified_num_tires ?? 0),
    0
  );

  let detectionLowerUndercount = verifiedDetections.filter(
    m => ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) > ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires ?? 0))
  ).reduce(
    (t, m) => t + ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) - ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires ?? 0)),
    0
  );

  let detectionLowerOvercount = verifiedDetections.filter(
    m => ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) < ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires ?? 0))
  ).reduce(
    (t, m) => t + ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires ?? 0)) - ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)),
    0
  );

  let detectionRaisedUndercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_raised_tires ?? 0) > (m.detection?.num_raised_tires ?? 0)
  ).reduce(
    (t, m) => t + (m.detection?.verified_num_raised_tires ?? 0) - (m.detection?.num_raised_tires ?? 0),
    0
  );

  let detectionRaisedOvercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_raised_tires ?? 0) < (m.detection?.num_raised_tires ?? 0)
  ).reduce(
    (t, m) => t + (m.detection?.num_raised_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0),
    0
  );

  if (view?.has_raised_axle_model) { 
    detectionLowerUndercount = verifiedDetections.filter(
    m => ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) > ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires_ml ?? 0))
  ).reduce(
    (t, m) => t + ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) - ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires_ml ?? 0)),
    0
  );

  detectionLowerOvercount = verifiedDetections.filter(
    m => ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)) < ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires_ml ?? 0))
  ).reduce(
    (t, m) => t + ((m.detection?.num_tires ?? 0) - (m.detection?.num_raised_tires_ml ?? 0)) - ((m.detection?.verified_num_tires ?? 0) - (m.detection?.verified_num_raised_tires ?? 0)),
    0
  );

  detectionRaisedUndercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_raised_tires ?? 0) > (m.detection?.num_raised_tires_ml ?? 0)
  ).reduce(
    (t, m) => t + (m.detection?.verified_num_raised_tires ?? 0) - (m.detection?.num_raised_tires_ml ?? 0),
    0
  );

  detectionRaisedOvercount = verifiedDetections.filter(
    m => (m.detection?.verified_num_raised_tires ?? 0) < (m.detection?.num_raised_tires_ml ?? 0)
  ).reduce(
    (t, m) => t + (m.detection?.num_raised_tires_ml ?? 0) - (m.detection?.verified_num_raised_tires ?? 0),
    0
  );
  }

  const [verifiedAdvanced, setverifiedAdvanced] = React.useState<boolean>(false);
  const handleClickVerifiedAdvanced = () => {
    setverifiedAdvanced(!verifiedAdvanced)
  }

  const StyledButton = styled(Button)({
    textTransform: 'capitalize',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: 'inherit',
    background: 'none',
    border: 'none',
    padding: 0,
  });
  return (
    <Box sx={{ flex: '1 1 0', mt: '1rem' }}>
      <Table sx={{
        [`& .${tableCellClasses.root}`]: {
          textAlign: "center",
          
        }
      }}>
        <TableHead sx={{
          [`& .${tableCellClasses.root}`]: {
            fontWeight: "bold",
          }
        }}>
          <TableRow >
            <TableCell colSpan={2} >&nbsp;</TableCell>
            <TableCell>Total </TableCell>
            <TableCell title={matchedTitle}>Matched</TableCell>
            <TableCell title={unverifiedDetectionsTitle}>Unverified</TableCell>
            { verifiedAdvanced && <TableCell> Verified Undercount</TableCell> }
            <TableCell title={verifiedDetectionsTitle}>
          <StyledButton
            onClick={handleClickVerifiedAdvanced}>
            Verified
          </StyledButton>
      </TableCell>
            { verifiedAdvanced && <TableCell> Verified Overcount</TableCell> }
            <TableCell title={unmatchedTitle}>Unmatched</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>AvenewAi</TableCell>
            <TableCell rowSpan = {2} sx={{ fontWeight: "bold" }}> Vehicles</TableCell>
            <TableCell>{totalDetected}</TableCell>
            <TableCell>{matched}</TableCell>
            <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{unverified}</TableCell>
            {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}> &nbsp; </TableCell>}
            <TableCell sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{verified}</TableCell>
            {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}> &nbsp; </TableCell>}
            <TableCell sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>{detected}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{tenantName}</TableCell>
            <TableCell>{totalUploaded}</TableCell>
            <TableCell>{matched}</TableCell>
            <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{unverified}</TableCell>
            {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}> &nbsp; </TableCell>}
            <TableCell sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{verified}</TableCell>
            {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}> &nbsp; </TableCell>}
            <TableCell sx={{ backgroundColor: COLOR_NO_DETECTION }}>{observed}</TableCell>
          </TableRow>
          {(isSuperuser() || !view?.has_raised_axle_model) && <>
            <TableRow>
              <TableCell rowSpan = {2} sx={{ fontWeight: "bold" }}>AvenewAi</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Raised Axles</TableCell>
              <TableCell>{getTotalRaisedAxleCount(allDetections)}</TableCell>
              <TableCell >{getTotalRaisedAxleCount(matchedDetections)}</TableCell>
              <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalRaisedAxleCount(unverifiedDetections)}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionRaisedUndercount}</TableCell>}
              <TableCell  title={missedRaisedAxleTitle} sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{getTotalMissedAxleCount(verifiedDetections, 'raised')}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionRaisedOvercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>{getTotalRaisedAxleCount(observedDetections)}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Lowered Axles</TableCell>
              <TableCell>{getTotalAxleCount(true,allDetections)-getTotalRaisedAxleCount(allDetections)}</TableCell>
              <TableCell>{getTotalAxleCount(true,matchedDetections)-getTotalRaisedAxleCount(matchedDetections)}</TableCell>
              <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalAxleCount(true,unverifiedDetections)-getTotalRaisedAxleCount(unverifiedDetections)}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionLowerUndercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{(getTotalAxleCount(true,verifiedDetections)-getTotalRaisedAxleCount(verifiedDetections))}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionLowerOvercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>{getTotalAxleCount(true,observedDetections)-getTotalRaisedAxleCount(observedDetections)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{tenantName}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Total Axles</TableCell>
              <TableCell>{getTotalAxleCount(false,allObservations,'Vehicle',true)}</TableCell>
              <TableCell>{getTotalAxleCount(true,matchedDetections)-getTotalRaisedAxleCount(matchedDetections)}</TableCell>
              <TableCell  sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalAxleCount(false,unverifiedDetections,'Vehicle',true)}
             </TableCell>
             {verifiedAdvanced && <TableCell title={undercountedLoweredAxleTitle} sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{observationUndercount}</TableCell>}
             <TableCell sx = {{backgroundColor: COLOR_VERIFIED_DETECTION}}> &nbsp; </TableCell>
             {verifiedAdvanced && <TableCell title={overcountedLoweredAxleTitle} sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{observationOvercount} </TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_DETECTION }}>{(getTotalAxleCount(true,detectedDetections)-getTotalRaisedAxleCount(detectedDetections))}</TableCell>
            </TableRow>
          </>}
          {view?.has_raised_axle_model && <>
            <TableRow>
              <TableCell rowSpan = {2} sx={{ fontWeight: "bold" }}>AvenewAi</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}> Raised Axles{isSuperuser() ? ' (ML)' : ''}</TableCell>
              <TableCell>{getTotalRaisedAxleCountMl(allDetections)}</TableCell>
              <TableCell >{getTotalRaisedAxleCountMl(matchedDetections)}</TableCell>
              <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalRaisedAxleCountMl(unverifiedDetections)}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionRaisedUndercount}</TableCell>}
              <TableCell title={missedRaisedAxleTitle} sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{getTotalMissedAxleCountMl(verifiedDetections, 'raised')}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionRaisedOvercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>{getTotalRaisedAxleCountMl(observedDetections)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}> Lowered Axles{isSuperuser() ? ' (ML)' : ''}</TableCell>
              <TableCell>{getTotalAxleCount(true,allDetections)-getTotalRaisedAxleCountMl(allDetections)}</TableCell>
              <TableCell>{getTotalAxleCount(true,matchedDetections)-getTotalRaisedAxleCountMl(matchedDetections)}</TableCell>
              <TableCell sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalAxleCount(true,unverifiedDetections)-getTotalRaisedAxleCountMl(unverifiedDetections)}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionLowerUndercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_VERIFIED_DETECTION }}>{(getTotalAxleCount(true,verifiedDetections)-getTotalRaisedAxleCountMl(verifiedDetections))}</TableCell>
              {verifiedAdvanced && <TableCell sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{detectionLowerOvercount}</TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>{getTotalAxleCount(true,observedDetections)-getTotalRaisedAxleCountMl(observedDetections)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell  sx={{ fontWeight: "bold" }}>{tenantName}</TableCell>
              <TableCell rowSpan = {2} sx={{ fontWeight: "bold" }}> Total Axles</TableCell>
              <TableCell>{getTotalAxleCount(false,allObservations,'Vehicle',true)}</TableCell>
              <TableCell>{getTotalAxleCount(true,matchedDetections)-getTotalRaisedAxleCountMl(matchedDetections)}</TableCell>
              <TableCell  sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>{getTotalAxleCount(false,unverifiedDetections,'Vehicle',true)}</TableCell>
              {verifiedAdvanced && <TableCell title={undercountedLoweredAxleTitle} sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{observationUndercount}</TableCell>}
              <TableCell sx = {{backgroundColor: COLOR_VERIFIED_DETECTION}}> &nbsp; </TableCell>
              {verifiedAdvanced && <TableCell title={overcountedLoweredAxleTitle} sx={{ backgroundColor: COLOR_AXLE_MISMATCH }}>{observationOvercount} </TableCell>}
              <TableCell sx={{ backgroundColor: COLOR_NO_DETECTION }}>{(getTotalAxleCount(true,detectedDetections)-getTotalRaisedAxleCountMl(detectedDetections))}</TableCell>
            </TableRow>
          </>}
        </TableBody>
      </Table>
    </Box>
  );
};

export default Summary;

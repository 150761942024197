import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { COLOR_UNVERIFIED_DETECTION, COLOR_VERIFIED_DETECTION, COLOR_NO_DETECTION, COLOR_NO_OBSERVATION, COLOR_MISSED_DETECTION } from '../config';

const ComparisonTypeSelect: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField select label="Detections" sx={{ minWidth: '10rem', mr: '0.5rem' }} {...props}>
      <MenuItem value="all">All</MenuItem>
      <MenuItem value="matched">Matched</MenuItem>
      <MenuItem value="unmatched-detections" sx={{ backgroundColor: COLOR_NO_OBSERVATION }}>Unmatched</MenuItem>
      <MenuItem value="unmatched-uploads" sx={{ backgroundColor: COLOR_NO_DETECTION }}>Unmatched From Upload</MenuItem>
      <MenuItem value="missed-detection" sx={{ backgroundColor: COLOR_MISSED_DETECTION }}>Missed</MenuItem>
      <MenuItem value="unverified" sx={{ backgroundColor: COLOR_UNVERIFIED_DETECTION }}>Unverified Mis-match</MenuItem>
      <MenuItem value="verified" sx={{ background: `linear-gradient(90deg, ${COLOR_VERIFIED_DETECTION} 50%, ${COLOR_MISSED_DETECTION} 50%)` }}>Verified</MenuItem>
      <MenuItem value="unmatched" sx={{ background: `linear-gradient(90deg, ${COLOR_NO_OBSERVATION} 50%, ${COLOR_NO_DETECTION} 50%)` }}>Unmatched</MenuItem>
      <MenuItem value="discrepancy" sx={{ background: `linear-gradient(90deg, ${COLOR_NO_OBSERVATION} 0, ${COLOR_NO_OBSERVATION} 25%, ${COLOR_NO_DETECTION} 25%, ${COLOR_NO_DETECTION} 50%, ${COLOR_UNVERIFIED_DETECTION} 50%, ${COLOR_UNVERIFIED_DETECTION} 75%, ${COLOR_MISSED_DETECTION} 75%, ${COLOR_MISSED_DETECTION} 100%)` }}>Discrepancy</MenuItem>
    </TextField>
  );
};

export default ComparisonTypeSelect;

import React from 'react';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Processor, SoftwareVersion } from '../types';
import { getProcessorsBySite, getSoftwareVersions } from '../utils/api';

interface SoftwareVersionMap {
        [id: number]: SoftwareVersion;
}

interface ProcessorListProps {
	siteId: number;
}

const ProcessorList: React.FC<ProcessorListProps> = ({ siteId }) => {
	const { isLoading, isError, data } = useQuery<Processor[], Error>(['processors', siteId], () => getProcessorsBySite(siteId));
        const { data: software_version_data } = useQuery<SoftwareVersion[], Error>('software-versions', getSoftwareVersions);

        const software_versions: SoftwareVersionMap = software_version_data ? software_version_data.reduce((o, v) => ({ ...o, [v.id]: v }), {}) : {};

	if (isLoading) {
		return <CircularProgress />;
	}

	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>;
	}

	if (!data || data.length === 0) {
		return <Typography>There are no processors.</Typography>;
	}

	return (
		<Table aria-label="Processors">
			<TableBody>
				{data.map(processor => <TableRow hover key={processor.id}>
				  	<TableCell>{processor.name}</TableCell>
                                        <TableCell>{software_versions[processor.version_id]?.version || '?'}</TableCell>
				  </TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default ProcessorList;

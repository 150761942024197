import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Download from '@mui/icons-material/Download';

import { Detection, MeasurementScale, DisplayColumns, EdgeProcessConfig } from '../types';
import { getDetectionsByView } from '../utils/api';
import { downloadDetections } from '../utils';

import DetectionList from './DetectionList';

const PAGE_SIZE = 100;

interface DetectionViewProps {
  title: string;
  view_id: number;
  start_time?: string;
  end_time?: string;
  vehicle_class?: string;
  min_axles?: number;
  max_axles?: number;
  min_raised_axles?: number;
  min_raised_axles_ml?: number;
  raised_axle_mismatch?: boolean;
  minimum_vehicle_score?: string;
  minimum_tire_score?: string;
  vehicle_minimum_mode?: string;
  axle_minimum_mode?: string;
  vehicle_lowest_maximum?: string;
  axle_lowest_maximum?: string;
  limit?: number;
  offset?: number;
  sort_column?: string;
  sort_direction?: "asc" | "desc";
  has_raised_axle_model?: boolean;
  measurement_scale?: MeasurementScale;
  display_columns?: DisplayColumns;
  processConfigs: {[id: number]: EdgeProcessConfig };
}

const DetectionView: React.FC<DetectionViewProps> = ({
  title,
  view_id,
  start_time,
  end_time,
  vehicle_class,
  min_axles,
  max_axles,
  min_raised_axles,
  min_raised_axles_ml,
  minimum_vehicle_score,
  minimum_tire_score,
  vehicle_minimum_mode,
  axle_minimum_mode,
  vehicle_lowest_maximum,
  axle_lowest_maximum,
  raised_axle_mismatch = false,
  limit = PAGE_SIZE,
  offset = 0,
  sort_column = 'start_time',
  sort_direction = 'desc',
  has_raised_axle_model = false,
  measurement_scale = 'metric',
  display_columns,
  processConfigs,
}) => {
  const { isLoading, isError, data } = useQuery<Detection[], Error>(['detections', view_id, start_time, end_time, vehicle_class, min_axles, max_axles, min_raised_axles, min_raised_axles_ml, raised_axle_mismatch, minimum_vehicle_score, minimum_tire_score, vehicle_minimum_mode, axle_minimum_mode, vehicle_lowest_maximum, axle_lowest_maximum, limit, offset, sort_column, sort_direction], () => getDetectionsByView(view_id ?? -1, start_time, end_time, vehicle_class, min_axles, max_axles, min_raised_axles, min_raised_axles_ml, raised_axle_mismatch, minimum_vehicle_score, minimum_tire_score, vehicle_minimum_mode, axle_minimum_mode, vehicle_lowest_maximum, axle_lowest_maximum, limit, offset, sort_column, sort_direction));
  const [detections, setDetections] = useState<Detection[]>([] as Detection[]);
  const [searchParams, setSearchParams] = useSearchParams();
  const onClickPrevious = () => {
    searchParams.set('offset', Math.max(0, offset - limit).toString());
    setSearchParams(searchParams);
    window.scrollTo({ top: 0 });
  }
  const onClickNext = () => {
    searchParams.set('offset', (offset + limit).toString());
    setSearchParams(searchParams);
    window.scrollTo({ top: 0 });
  }

  useEffect(() => {
    if (data) {
      setDetections(data);
    }
  }, [data]);


  const onVerified: (d: Detection) => void = (d: Detection) => {
    setDetections(detections.map(dd => dd.id === d.id ? d : dd));
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <Typography>There has been an error loading your data.</Typography>
  }

  if (detections.length === 0) {
    return <Typography>There are no detections.</Typography>
  }
  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        title={title}
        action={
          <IconButton onClick={() => downloadDetections(view_id, start_time, end_time, vehicle_class, min_axles, min_raised_axles)}>
            <Download />
          </IconButton>
        }
      />
      <CardContent>
        <DetectionList detections={detections} sort_column={sort_column} sort_direction={sort_direction} onVerified={onVerified} has_raised_axle_model={has_raised_axle_model} measurement_scale={measurement_scale} display_columns={display_columns} processConfigs={processConfigs} />
      </CardContent>
      <CardActions>
        <Button disabled={offset === 0} onClick={onClickPrevious}>Previous</Button>
        <Button disabled={detections.length < PAGE_SIZE} onClick={onClickNext}>Next</Button>
      </CardActions>
    </Card>
  )
};

export default DetectionView;

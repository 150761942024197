import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getViews } from '../utils/api';
import { View, ViewType } from '../types';

interface ViewSelectProps {
	site_id: number;
  view_type: ViewType;
}

const ViewSelect: React.FC<TextFieldProps & ViewSelectProps> = ({ site_id, view_type, ...props }) => {
	const { data } = useQuery<View[], Error>(['views', site_id, view_type], () => getViews(site_id, view_type));

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="View" {...props}>
			{data.sort(
        (a, b) => a.name.localeCompare(b.name)
      ).map((view) => (
				<MenuItem key={view.id} value={view.id}>
					{view.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default ViewSelect;

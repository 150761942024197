import React from 'react';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Camera, ProcessConfig, Model, Process } from '../types';
import { getProcessesBySite, getCameras, getProcessConfigs, getModels } from '../utils/api';

interface CameraMap {
	[id: number]: Camera;
}

interface ProcessConfigMap {
	[id: number]: ProcessConfig;
}

interface ModelMap {
	[id: number]: Model;
}

interface ProcessListProps {
	siteId: number;
}

const ProcessList: React.FC<ProcessListProps> = ({ siteId }) => {
	const { isLoading, isError, data } = useQuery<Process[], Error>(['processes', siteId], () => getProcessesBySite(siteId));
	const { data: camera_data } = useQuery<Camera[], Error>('cameras', getCameras);
	const { data: process_config_data } = useQuery<ProcessConfig[], Error>('process-configs', getProcessConfigs);
	const { data: model_data } = useQuery<Model[], Error>('models', getModels);

	const cameras: CameraMap = camera_data ? camera_data.reduce((o, v) => ({ ...o, [v.id]: v }), {}) : {};
	const process_configs: ProcessConfigMap = process_config_data ? process_config_data.reduce((o, v) => ({ ...o, [v.id]: v }), {}) : {};
	const models: ModelMap = model_data ? model_data.reduce((o, v) => ({ ...o, [v.id]: v }), {}) : {};

	if (isLoading) {
		return <CircularProgress />;
	}

	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>
	}

	if (!data || data.length === 0) {
		return <Typography>There are no processes.</Typography>
	}
	return (
		<Table aria-label="Processs">
			<TableBody>
				{data.sort((a, b) => a.id - b.id).map(process => <TableRow key={process.id}>
					<TableCell>{process.number}: {process.name}</TableCell>
					<TableCell>{cameras[process.camera_id]?.name || '?'}</TableCell>
					<TableCell>{process_configs[process.config_id]?.name || '?'}</TableCell>
					<TableCell>{models[process.model_id]?.name || '?'}</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	);
};

export default ProcessList;

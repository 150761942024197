import React from 'react';

import DetectionView from './DetectionView';

import { View, EdgeProcessConfig } from '../types';

interface ViewDetailProps {
  view: View;
  start_time?: string;
  end_time?: string;
  vehicle_class?: string;
  min_axles?: number;
  max_axles?: number;
  min_raised_axles?: number;
  min_raised_axles_ml?: number;
  raised_axle_mismatch?: boolean;
  minimum_vehicle_score?: string;
  minimum_tire_score?: string;
  vehicle_minimum_mode?: string;
  axle_minimum_mode?: string;
  vehicle_lowest_maximum?: string;
  axle_lowest_maximum?: string;
  limit?: number;
  offset?: number;
  sort_column?: string;
  sort_direction?: "asc" | "desc";
  has_raised_axle_model?: boolean;
  processConfigs: { [id: number]: EdgeProcessConfig };
}

const ViewDetail: React.FC<ViewDetailProps> = ({
  view,
  start_time,
  end_time,
  vehicle_class,
  min_axles,
  max_axles,
  min_raised_axles,
  min_raised_axles_ml,
  raised_axle_mismatch,
  minimum_vehicle_score,
  minimum_tire_score,
  vehicle_minimum_mode,
  axle_minimum_mode,
  vehicle_lowest_maximum,
  axle_lowest_maximum,
  limit = 100,
  offset = 0,
  sort_column = 'start_time',
  sort_direction = 'desc',
  has_raised_axle_model = false,
  processConfigs
}) => {
  return (
    <DetectionView
      title={view.name}
      view_id={view.id}
      start_time={start_time}
      end_time={end_time}
      vehicle_class={vehicle_class}
      min_axles={min_axles}
      max_axles={max_axles}
      min_raised_axles={min_raised_axles}
      min_raised_axles_ml={min_raised_axles_ml}
      raised_axle_mismatch={raised_axle_mismatch}
      minimum_vehicle_score={minimum_vehicle_score}
      minimum_tire_score={minimum_tire_score}
      vehicle_minimum_mode={vehicle_minimum_mode}
      axle_minimum_mode={axle_minimum_mode}
      vehicle_lowest_maximum={vehicle_lowest_maximum}
      axle_lowest_maximum={axle_lowest_maximum}
      limit={limit}
      offset={offset}
      sort_column={sort_column}
      sort_direction={sort_direction}
      has_raised_axle_model={has_raised_axle_model}
      measurement_scale={view.measurement_scale}
      display_columns={view.display_columns}
      processConfigs={processConfigs}
    />
  );
};

export default ViewDetail;

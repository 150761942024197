import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Site } from '../types';
import { getSites } from '../utils/api';

const SiteList: React.FC = () => {
	const navigate = useNavigate();
	const { isLoading, isError, data } = useQuery<Site[], Error>('sites', getSites);

	if (isLoading) {
		return <CircularProgress />;
	}

	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>;
	}

	if (!data || data.length === 0) {
		return <Typography>There are no sites.</Typography>;
	}

	return (
		<Table aria-label="Sites">
			<TableBody>
				{data.map(site => <TableRow hover key={site.id} onClick={() => navigate(`/sites/${site.id}/${'TFM' === site.type ? 'traffic_flow' : 'live'}`)}>
					<TableCell>{site.name}</TableCell>
					<TableCell>{site.type}</TableCell>
					</TableRow>
				)}
			</TableBody>
		</Table>
	);
};

export default SiteList;

import decodeJwt from 'jwt-decode';

import { BASE_URL } from '../config';
import { callApi } from './api';
import { Role } from '../types';

export const isAuthenticated = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return false;
  }
  return permissions === 'user' || permissions === 'admin' ? true : false;
};

export const getRole: () => Role | undefined = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return;
  }
  if ('admin' === permissions) {
    return 'superadmin';
  }
  const role = localStorage.getItem('role');
  if (!role) {
    return;
  }
  if ('user' === role) {
    return 'user';
  }
  if ('admin' === role) {
    return 'admin';
  }
  return;
};

export const isSuperuser = () => {
  const permissions = localStorage.getItem('permissions');
  if (!permissions) {
    return false;
  }
  return permissions === 'admin';
}

export const login = async (email: string, password: string) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error('Email or password was not provided');
  }
  const formData = new FormData();
  // OAuth2 expects form data, not JSON data
  formData.append('username', email);
  formData.append('password', password);

  const request = new Request(BASE_URL + '/api/token', {
    method: 'POST',
    body: formData,
  });

  const response = await fetch(request);

  if (response.status === 500) {
    throw new Error('Internal server error');
  }

  const data = await response.json();

  if (response.status >= 400) {
    if (data.detail) {
      throw data.detail;
    }
    throw data;
  }

  if ('access_token' in data) {
    const decodedToken: any = decodeJwt(data['access_token']);
    localStorage.setItem('token', data['access_token']);
    localStorage.setItem('permissions', decodedToken.permissions);
    localStorage.setItem('role', decodedToken.role);
    localStorage.setItem('has_mfa', decodedToken.has_mfa);
    localStorage.setItem('email', email);
  }

  return data;
};

export const logout = () => {
  localStorage.removeItem('email');
  localStorage.removeItem('token');
  localStorage.removeItem('permissions');
  localStorage.removeItem('role');
  localStorage.removeItem('has_mfa');
};


export const forgotPassword = async (email: string) => {
  if (!(email.length > 0)) {
    throw new Error('Email was not provided');
  }

  return await callApi('/password-reset-request', 'POST', JSON.stringify({ email }));
};

export const resetPassword = async (email: string, password: string, confirm_password: string, verification: string) => {
  // Assert email or password is not empty
  if (
    !(email.length > 0) ||
    !(password.length > 0) ||
    !(confirm_password.length > 0) ||
    !(verification.length > 0)
  ) {
    throw new Error('Email or password was not provided');
  }

  var valid_password_characters = /[~!@#$%^&*_]/;
  if (
    (password.length < 8) ||
    !(/[A-Z]/.test(password)) ||
    !(valid_password_characters.test(password)) ||
    !(/[0-9]/.test(password))
  ) {
    throw new Error('Password must contain one special character (~,!,@,#,$,%,^,&,*,_), one number and one uppercase');
  }
  if (!(password === confirm_password)) {
    throw new Error('Passwords do not match');
  }
  const data = await callApi('/password-reset', 'POST', JSON.stringify({ email, password, verification }));
  return data;
};

export const setupMFA = async (password: string) => {
  const email = localStorage.getItem('email');
  const data = await callApi('/mfa-setup', 'POST', JSON.stringify({ email, password }));
  return data;
};

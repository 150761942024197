import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ViewSelect from '../components/ViewSelect';
import View from '../components/comparison/View';

interface ComparisonViewProps {
  siteId: number;
}

const MAX_OFFSET = 60;

const ComparisonView: React.FC<ComparisonViewProps> = ({ siteId }) => {
  const today = new Date();
  const defaultStartTime = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).substr(-2) + '-' + ('0' + today.getDate()).substr(-2) + ' 00:00';
  const defaultEndTime = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).substr(-2) + '-' + ('0' + today.getDate()).substr(-2) + ' 23:59';

  const [view1Id, setView1Id] = useState<number | null>(null);
  const [view2Id, setView2Id] = useState<number | null>(null);
  const [startTime, setStartTime] = useState<string>(defaultStartTime);
  const [endTime, setEndTime] = useState<string>(defaultEndTime);
  const [offset, setOffset] = useState<number>(0);

  const [activeView1, setActiveView1] = useState<number | null>(null);
  const [activeView2, setActiveView2] = useState<number | null>(null);
  const [activeStartTime, setActiveStartTime] = useState<string>(startTime);
  const [activeEndTime, setActiveEndTime] = useState<string>(endTime);

  const handleChangeView1 = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setView1Id(+event.target.value);
  const handleChangeView2 = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setView2Id(+event.target.value);
  const handleChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setStartTime(event.target.value);
  const handleChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => !!event && setEndTime(event.target.value);
  const handleChangeOffset = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOffset = +event.target.value;
    if (newOffset < 0 || newOffset > MAX_OFFSET) {
      return;
    }
    setOffset(newOffset);
  };
  const handleClick = () => {
    setActiveView1(view1Id);
    setActiveView2(view2Id);
    setActiveStartTime(startTime);
    setActiveEndTime(endTime);
  };

  const isCompareChanged = view1Id !== activeView1 || view2Id !== activeView2 || startTime.valueOf() !== activeStartTime.valueOf() || endTime.valueOf() !== activeEndTime.valueOf();

  const isCompareDisabled = !view1Id || !view2Id || !startTime || !endTime || !Date.parse(startTime) || !Date.parse(endTime) || Date.parse(startTime) >= Date.parse(endTime);

  const isDisplayed = activeView1 !== null && activeView2 !== null && !!activeStartTime && !!activeEndTime && !!Date.parse(activeStartTime) && !!Date.parse(activeEndTime) && Date.parse(activeStartTime) < Date.parse(activeEndTime);

  return (
    <Stack>
      <Box sx={{ marginTop: '2rem' }}>
        <ViewSelect site_id={siteId} view_type="live" value={view1Id ?? ''} onChange={handleChangeView1} label="View 1" />
        <ViewSelect site_id={siteId} view_type="live" value={view2Id ?? ''} onChange={handleChangeView2} label="View 2" />
        <TextField type="datetime-local" label="Start Time" sx={{ mr: '0.5rem' }} value={startTime} onChange={handleChangeStartTime} />
        <TextField type="datetime-local" label="End Time" sx={{ mr: '0.5rem' }} value={endTime} onChange={handleChangeEndTime} />
        <Button disabled={isCompareDisabled || !isCompareChanged} onClick={handleClick}>Compare</Button>
        <TextField type="number" label="Offset (minutes)" value={offset} onChange={handleChangeOffset} />
      </Box>
      {isDisplayed && <View view1Id={+activeView1} view2Id={+activeView2} since={new Date(Date.parse(activeStartTime))} until={new Date(Date.parse(activeEndTime))} width={1000} height={100} offset={offset} />}
    </Stack>
  );
}

export default ComparisonView;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';

import { Detection } from '../../types';
import { getDetectionsByView } from '../../utils/api';
import { getDefaultInterval, formatDate } from '../../utils';

import Histogram from '../traffic-flow/Histogram';
import VideoPlayer from '../VideoPlayer';
import List from './List';

import { ASSET_URL } from '../../config';

interface ViewProps {
  view1Id: number;
  view2Id: number;
  since: Date;
  until: Date;
  width: number;
  height: number;
  offset: number;
}

const compareDate = (a: Date, b: Date) => {
  if (a > b) {
    return 1;
  } else if (b > a) {
    return -1;
  }
  return 0;
}

const View: React.FC<ViewProps> = ({
  view1Id,
  view2Id,
  since,
  until,
  width,
  height,
  offset
}) => {
  const interval = (until.valueOf() - since.valueOf()) <= 3600000 ? 60 : 3600;
  const [start, setStart] = useState<Date>(since);
  const [end, setEnd] = useState<Date>(until);
  const [view1Detections, setView1Detections] = useState<Detection[]>([]);
  const [view2Detections, setView2Detections] = useState<Detection[]>([]);
  const [view1Detection, setView1Detection] = useState<Detection | null>(null);
  const [view2Detection, setView2Detection] = useState<Detection | null>(null);
  const [currentInterval, setCurrentInterval] = useState<number>(interval);
  const { data: view1Data } = useQuery<Detection[], Error>(
    ['view-detection', view1Id, since, until],
    () => getDetectionsByView(view1Id, formatDate(since), formatDate(until))
  );
  const { data: view2Data } = useQuery<Detection[], Error>(
    ['view-detection', view2Id, since, until],
    () => getDetectionsByView(view2Id, formatDate(since), formatDate(new Date(until.valueOf() + 3600000)))
  );
  useEffect(() => {
    setStart(since);
    setEnd(until);
    setCurrentInterval(interval);
  }, [interval, since, until]);
  useEffect(() => {
    if (view1Data) {
      setView1Detections(view1Data);
    }
  }, [view1Data]);

  useEffect(() => {
    if (view2Data) {
      setView2Detections(view2Data);
    }
  }, [view2Data]);

  const onClickView1: (date: Date) => void = (date) => {
    const currentDuration = end.valueOf() - start.valueOf();
    // If we are working with 1 hour, then select a vehicle
    if (currentDuration <= 3600000) {
      const newEnd = new Date(date.valueOf() + currentInterval * 1000);
      const selected = view1Detections.filter(d => d.start_time >= date && d.start_time <= newEnd);
      selected.sort((a, b) => compareDate(a.start_time, b.start_time));
      if (selected.length > 0) {
        setView1Detection(selected[0]);
      }
    } else if (currentDuration > 24 * 60 * 60 * 1000) {
      // new duration = 24 hours
      let newEnd = new Date(date.valueOf() + 24 * 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 24 * 60 * 60 * 1000);
      const newInterval = Math.min(currentInterval, getDefaultInterval(24));
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    } else {
      // new duration = 1 hour
      let newEnd = new Date(date.valueOf() + 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 60 * 60 * 1000);
      const newInterval = 60;
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    }
  };

  const onClickNextView1: () => void = () => {
    if (!view1Detection) {
      return;
    }
    const selected = view1Detections.filter(d => d.start_time > view1Detection.start_time);
    selected.sort((a, b) => compareDate(a.start_time, b.start_time));
    if (selected.length > 0) {
      setView1Detection(selected[0]);
    }
  };
  const onClickPreviousView1: () => void = () => {
    if (!view1Detection) {
      return;
    }
    const selected = view1Detections.filter(d => d.start_time < view1Detection.start_time);
    selected.sort((a, b) => compareDate(b.start_time, a.start_time));
    if (selected.length > 0) {
      setView1Detection(selected[0]);
    }
  };

  const onClickNextView2: () => void = () => {
    if (!view2Detection) {
      return;
    }
    const selected = view2Detections.filter(d => d.start_time > view2Detection.start_time);
    selected.sort((a, b) => compareDate(a.start_time, b.start_time));
    if (selected.length > 0) {
      setView2Detection(selected[0]);
    }
  };
  const onClickPreviousView2: () => void = () => {
    if (!view2Detection) {
      return;
    }
    const selected = view2Detections.filter(d => d.start_time < view2Detection.start_time);
    selected.sort((a, b) => compareDate(b.start_time, a.start_time));
    if (selected.length > 0) {
      setView2Detection(selected[0]);
    }
  };

  const onClickView2: (date: Date) => void = (date) => {
    const currentDuration = end.valueOf() - start.valueOf();
    // If we are working with 1 hour, then select a vehicle
    if (currentDuration <= 3600000) {
      const newEnd = new Date(date.valueOf() + currentInterval * 1000);
      const selected = view2Detections.filter(d => d.start_time >= date && d.start_time <= newEnd);
      selected.sort((a, b) => compareDate(a.start_time, b.start_time));
      if (selected.length > 0) {
        setView2Detection(selected[0]);
      }
    } else if (currentDuration > 24 * 60 * 60 * 1000) {
      // new duration = 24 hours
      let newEnd = new Date(date.valueOf() + 24 * 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 24 * 60 * 60 * 1000);
      const newInterval = Math.min(currentInterval, getDefaultInterval(24));
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    } else {
      // new duration = 1 hour
      let newEnd = new Date(date.valueOf() + 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 60 * 60 * 1000);
      const newInterval = 60;
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    }
  };

  const onReset: () => void = () => {
    setEnd(until);
    setStart(since);
    setCurrentInterval(interval);
  }
  const isZoomed = interval !== currentInterval || start.valueOf() !== since.valueOf() || end.valueOf() !== until.valueOf();

  return (
    <Card>
      <CardContent>
        <Box>        
          {isZoomed && <IconButton aria-label="Reset" onClick={onReset}><CancelIcon /></IconButton>}
        </Box>        
        <Histogram
          detections={view1Detections.filter(d => d.start_time >= start && d.start_time < end)}
          width={width}
          height={height}
	  interval={currentInterval}
	  startTime={start}
	  endTime={end}
          selectedTime={view1Detection ? view1Detection.start_time : undefined}
	  onClick={onClickView1}
        />
        <Histogram
          detections={view2Detections.filter(d => d.start_time.valueOf() >= start.valueOf() + offset * 60000 && d.start_time.valueOf() < end.valueOf() * 60000 )}
          width={width}
          height={height}
	  interval={currentInterval}
	  startTime={new Date(start.valueOf() + offset * 60000)}
	  endTime={new Date(end.valueOf() + offset * 60000)}
          selectedTime={view2Detection ? view2Detection.start_time : undefined}
	  onClick={onClickView2}
        />
        <Grid container>
          <Grid item sx={{ m: 1 }}>
	    {view1Detection ? <div>
              <VideoPlayer
                key={view1Detection.id}
                url={`${ASSET_URL}/${view1Detection.process_id}/snippet-${view1Detection.vehicle_number}.mp4`}
                rawUrl={`${ASSET_URL}/${view1Detection.process_id}/raw-snippet-${view1Detection.vehicle_number}.mp4`}
                trainingUrl={!!view1Detection.has_training_images ? `${ASSET_URL}/${view1Detection.process_id}/training-${view1Detection.vehicle_number}.tgz` : undefined}
                fps={30}
                frame={0}
                width={640}
                height={360}
              />
              <Button onClick={onClickPreviousView1}>Previous</Button>
              <Button onClick={onClickNextView1}>Next</Button>
            </div>: <Typography>No detection selected</Typography>}
          </Grid>
          <Grid item sx={{ m: 1 }}>
	    {view2Detection ? <div>
              <VideoPlayer
                key={view2Detection.id}
                url={`${ASSET_URL}/${view2Detection.process_id}/snippet-${view2Detection.vehicle_number}.mp4`}
                rawUrl={`${ASSET_URL}/${view2Detection.process_id}/raw-snippet-${view2Detection.vehicle_number}.mp4`}
                trainingUrl={!!view2Detection.has_training_images ? `${ASSET_URL}/${view2Detection.process_id}/training-${view2Detection.vehicle_number}.tgz` : undefined}
                fps={30}
                frame={0}
                width={640}
                height={360}
              />
              <Button onClick={onClickPreviousView2}>Previous</Button>
              <Button onClick={onClickNextView2}>Next</Button>
            </div>: <Typography>No detection selected</Typography>}
          </Grid>
        </Grid>
        <Box>
          <List
            view1Detections={view1Detections.filter(d => d.start_time >= start && d.start_time < end)}
            view2Detections={view2Detections.filter(d => d.start_time.valueOf() >= start.valueOf() + offset * 60000 && d.start_time.valueOf() < end.valueOf() * 60000 )}
            offset={offset}
	  />
        </Box>
      </CardContent>
    </Card>
  );
};

export default View;

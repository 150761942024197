import React from 'react';
import Box from '@mui/material/Box';

import Header from '../views/Header';
import Sites from '../views/Sites';

const DashboardPage = () => {
	return (
		<React.Fragment>
			<Header>Dashboard</Header>
			<Box
				sx={{ 
					display: 'flex',
					'& > :not(style)': {
						m: 1,
						flexGrow: 1
					}
				}}
			>
				<Sites />
			</Box>
    </React.Fragment>
	);
};

export default DashboardPage;

import React from 'react';

import Box from '@mui/material/Box';

import VideoPlayer from './VideoPlayer';
import VerifyDetection from './VerifyDetection';
import ToggleVehicleStats from './ToggleVehicleStats';

import { Detection } from '../types';
import { ASSET_URL } from '../config';
import { formatDate } from '../utils';

interface Props {
  detection: Detection;
  onVerified?: (d: Detection) => void;
}

const InlineDetection: React.FC<Props> = ({ detection, onVerified }) => {
  const getDownloadFileName: (time: number) => string = (time) => {
    return (detection.vehicle_number + '-' + formatDate(detection.start_time).replace(' ', '-') + '-' + time).replace(/ /g, '').replace(/:/g, '').replace(/\./g, '') + '.jpg';
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={{ flex: '1 1 0' }}>
        <VideoPlayer
          url={`${ASSET_URL}/${detection.process_id}/snippet-${detection.vehicle_number}.mp4`}
          rawUrl={`${ASSET_URL}/${detection.process_id}/raw-snippet-${detection.vehicle_number}.mp4`}
          trainingUrl={!!detection.has_training_images ? `${ASSET_URL}/${detection.process_id}/training-${detection.vehicle_number}.tgz` : undefined}
          fps={30}
          frame={0}
          getDownloadFileName={getDownloadFileName}
        />
      </Box>
      <Box sx={{ flex: '1 1 0', margin: '5px' }}>
        <VerifyDetection detection={detection} onVerified={onVerified} />
      </Box>
      <Box sx={{ flex: '1 1 0' }}>
        <ToggleVehicleStats detection={detection} />
      </Box>
    </Box>
  );
};

export default InlineDetection;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';


const VehicleClassSelect: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <TextField select label="Vehicle Class" sx={{ minWidth: '10rem', mr: '0.5rem' }} {...props}>
      <MenuItem value="all">All</MenuItem>
      <MenuItem value="Car">Car</MenuItem>
      <MenuItem value="Truck">Truck</MenuItem>
      <MenuItem value="Bus">Bus</MenuItem>
      <MenuItem value="Motorcycle">Motorcycle</MenuItem>
    </TextField>
  );
};

export default VehicleClassSelect;

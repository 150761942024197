import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import Header from '../views/Header';

interface Props {
	title?: string;
}

const LoadingPage: React.FC<Props> = ({ title }) => {
	return (
		<React.Fragment>
			<Header>{title ?? 'Loading'}</Header>
			<Box
				sx={{ 
					display: 'flex',
					'& > :not(style)': {
						m: 1,
						flexGrow: 1
					}
				}}
			>
			<Typography><CircularProgress /></Typography>
			</Box>
    </React.Fragment>
	);
};

export default LoadingPage;

import React from 'react';
import { useQuery } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { Lane } from '../types';
import { getLanesBySite } from '../utils/api';

interface LaneListProps {
	siteId: number;
}

const LaneList: React.FC<LaneListProps> = ({ siteId }) => {
	const { isLoading, isError, data } = useQuery<Lane[], Error>(['lanes', siteId], () => getLanesBySite(siteId));

	if (isLoading) {
		return <CircularProgress />
	}

	if (isError) {
		return <Typography>There has been an error loading your data.</Typography>;
	}

	if (!data || data.length === 0) {
		return <Typography>There are no lanes.</Typography>;
	}

	return (
		<Table aria-label="Lanes">
			<TableBody>
				{data && data.sort((a, b) => a.name.localeCompare(b.name)).map(lane => <TableRow key={lane.id}>
					<TableCell>{lane.name}</TableCell>
				</TableRow>)}
			</TableBody>
		</Table>
	);
};

export default LaneList;

import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getLanesBySite } from '../utils/api';
import { Lane } from '../types';

interface Props {
  site_id: number;
}

const LaneSelect: React.FC<Props & TextFieldProps> = ({ site_id, ...props }: Props & TextFieldProps) => {
	const { data } = useQuery<Lane[], Error>(['lanes', site_id], () => getLanesBySite(site_id));

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Lane" variant="standard" {...props}>
			{data.map((lane) => (
				<MenuItem key={lane.id} value={lane.id}>
					{lane.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default LaneSelect;

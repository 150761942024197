import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import ProcessorList from '../components/ProcessorList';
import SoftwareVersionSelect from '../components/SoftwareVersionSelect';

import { createProcessor } from '../utils/api';

interface ProcessorsProps {
	siteId: number;
}

const Processors: React.FC<ProcessorsProps> = ({ siteId }) => {
	const [adding, setAdding] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
        const [version_id, setSoftwareVersionId] = useState<number>(0);

	const queryClient = useQueryClient();
	const addProcessor = useMutation(createProcessor, {
		onSuccess: () => {
			queryClient.invalidateQueries(['processors', siteId]);
		}
	});

	const handleClickAdd = () => {
		setAdding(true);
		setName('');
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
        const handleChangeSoftwareVersionId = (event: React.ChangeEvent<HTMLInputElement>) => setSoftwareVersionId(+event.target.value);

	const handleCreate = () => {
		addProcessor.mutate({ name, site_id: siteId, version_id });
		setAdding(false);
	}

	return (
    <Card>
      <CardHeader
        title={adding ? "Create Processor" : "Processors"}
        action={
					!adding && <IconButton aria-label="Create Processor" onClick={handleClickAdd}>
            <AddIcon />
          </IconButton>
        }
      />
      <CardContent>
				{adding ?
					<Box
						component="form"
						sx={{
							'& .MuiTextField-root': { m: 1, width: '20ch' }
						}}
						noValidate
						autoComplete="off"
					>
						<div>
					    <TextField variant="standard" label="Name" onChange={handleChangeName} value={name} />
						</div>
                                                <div>
                                                  <SoftwareVersionSelect variant="standard" onChange={handleChangeSoftwareVersionId} value={version_id || ''} />
                                                </div>
					</Box>
				 :
        <ProcessorList siteId={siteId} />}
      </CardContent>
			{adding && <CardActions>
        <Button onClick={handleCreate}>Create</Button>
				<Button onClick={() => setAdding(false)}>Cancel</Button>
			</CardActions>}
    </Card>
  );
};

export default Processors;

import React from 'react';
import { useQuery } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { getUploads } from '../utils/api';
import { ObservationUpload } from '../types';

interface UploadSelectProps {
	site_id: number;
}

const UploadSelect: React.FC<TextFieldProps & UploadSelectProps> = ({ site_id, ...props }) => {
	const { data } = useQuery<ObservationUpload[], Error>(['uploads', site_id], () => getUploads(site_id));

	if (!data || data.length === 0) {
		return null;
	}
	return (
		<TextField select label="Upload" {...props}>
			{data.map((upload) => (
				<MenuItem key={upload.id} value={upload.id}>
					{upload.name}
				</MenuItem>
			))}
		</TextField>
	);
};

export default UploadSelect;

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import { TrafficFlow } from '../types';
import { getTrafficFlow } from '../utils/api';
import { DEFAULT_DURATION, getDefaultInterval } from '../utils';
import TrafficFlowSelect from '../components/TrafficFlowSelect';
import View from '../components/traffic-flow/View';

interface TrafficFlowViewProps {
  siteId: number;
}

const TrafficFlowView: React.FC<TrafficFlowViewProps> = ({ siteId }) => {
  const [duration, setDuration] = useState<number>(DEFAULT_DURATION);
  const [interval, setInterval] = useState<number>(getDefaultInterval(DEFAULT_DURATION));
  const [searchParams, setSearchParams] = useSearchParams();
  const traffic_flow_id = searchParams.get('traffic_flow_id');
  const trafficFlowId = traffic_flow_id ? +traffic_flow_id : undefined;
  const { data: traffic } = useQuery<TrafficFlow, Error>(
    ['traffic_flow', trafficFlowId, duration, interval],
    () => getTrafficFlow(trafficFlowId ?? -1),
    { enabled: !!trafficFlowId }
  );

  const handleChangeTrafficFlowId = (event: React.ChangeEvent<HTMLInputElement>) => setSearchParams(
    { traffic_flow_id: event.target.value }
  );
  const handleChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = +e.target.value;
    setDuration(value);
    if (
      (value === 1 && interval !== 60) ||
      (value < 6 && interval > 10800) ||
      (value < 12 && interval > 21600) ||
      (value < 24 && interval > 43200)
    ) {
      setInterval(getDefaultInterval(value));
    }
  };
  const handleChangeInterval = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Math.floor(+e.target.value);
    setInterval(value);
  };

  return (
    <Stack>
      <Box sx={{ marginTop: '2rem' }}>
        <TrafficFlowSelect site_id={siteId} sx={{ minWidth: '10rem' }} value={trafficFlowId} onChange={handleChangeTrafficFlowId} />
        <TextField select label="Duration" value={duration} onChange={handleChangeDuration}>
          <MenuItem value={1}>1 Hour</MenuItem>
          <MenuItem value={2}>2 Hours</MenuItem>
          <MenuItem value={3}>3 Hours</MenuItem>
          <MenuItem value={4}>4 Hours</MenuItem>
          <MenuItem value={6}>6 Hours</MenuItem>
          <MenuItem value={12}>12 Hours</MenuItem>
          <MenuItem value={24}>24 Hours</MenuItem>
          <MenuItem value={168}>1 Week</MenuItem>
          <MenuItem value={336}>2 Weeks</MenuItem>
        </TextField>
        <TextField select label="Interval" value={interval} onChange={handleChangeInterval}>
          <MenuItem value={60}>1 Minute</MenuItem>
	  {duration > 1 && <MenuItem value={300}>5 Minutes</MenuItem>}
	  {duration > 1 && <MenuItem value={600}>10 Minutes</MenuItem>}
	  {duration > 1 && <MenuItem value={900}>15 Minutes</MenuItem>}
	  {duration > 1 && <MenuItem value={1800}>30 Minutes</MenuItem>}
	  {duration > 1 && <MenuItem value={3600}>1 Hour</MenuItem>}
	  {duration > 1 && <MenuItem value={7200}>2 Hours</MenuItem>}
	  {duration > 1 && <MenuItem value={10800}>3 Hours</MenuItem>}
	  {duration > 6 && <MenuItem value={14400}>4 Hours</MenuItem>}
	  {duration > 6 && <MenuItem value={21600}>6 Hours</MenuItem>}
	  {duration > 12 && <MenuItem value={43200}>12 Hours</MenuItem>}
	  {duration > 24 && <MenuItem value={86400}>1 Day</MenuItem>}
        </TextField>
      </Box>
      {traffic &&
        <Grid container sx={{ width: '100%' }} spacing={1}>
          <Grid item style={{ flexGrow: 1 }}>
            <View
	      key={traffic.entrance.id}
	      direction="Entrance"
              view={traffic.entrance}
              duration={duration}
	      interval={interval}
	      width={600}
	      height={100}
	    />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <View
	      key={traffic.exit.id}
	      direction="Exit"
              view={traffic.exit}
              duration={duration}
	      interval={interval}
	      width={600}
	      height={100}
	    />
          </Grid>
        </Grid>}
    </Stack>
  );
};

export default TrafficFlowView;

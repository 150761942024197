import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';

import { Detection } from '../types';
import { formatDate } from '../utils';



interface DetectionCardProps {
  detection: Detection;
  title?: string;
  showVehicleNumber?: boolean;
}

const DetectionCard: React.FC<DetectionCardProps> = ({ detection, title, showVehicleNumber = false }) => {
  const duration = Math.ceil((detection.end_time.valueOf() - detection.start_time.valueOf()) / 1000);
  const target = `/detections/${detection.id}`;
  return (
    <Card>
      {title && <CardHeader title={title} />}
      <CardContent>
        {showVehicleNumber && <Typography>Vehicle: <a href={target} target="_blank" rel="noreferrer">{detection.vehicle_number}</a></Typography>}
        <Typography>Vehicle Class: {detection.vehicle_class}</Typography>
        <Typography>Start: {formatDate(detection.start_time, 2)}</Typography>
        <Typography>End: {formatDate(detection.end_time, 2)}</Typography>
        <Typography>Duration: {duration} second{duration > 1 && 's'}</Typography>
        <Typography>Axle Count: {detection.num_tires}</Typography>
        <Typography>Raised Axle Indices: {detection.num_raised_tires === 0 ? 'None' : detection.raised_tires.join(', ')}</Typography>
      </CardContent>
    </Card>
  );
};

export default DetectionCard;

import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Detection } from '../../types';
import { VEHICLE_CLASS_COLORS } from '../../config';

import {
  Bar,
  BarChart,
  Tooltip,
  XAxis,
  YAxis,
  Rectangle
} from 'recharts';

interface VehicleCounts {
  Truck: number;
  Car: number;
  Bus: number;
  Motorcycle: number;
  Vehicle: number;
}

const getDefaultVehicleCounts: () => VehicleCounts = () => {
  return {
    Truck: 0,
    Car: 0,
    Bus: 0,
    Motorcycle: 0,
    Vehicle: 0
  };
};

interface HistogramProps {
  detections: Detection[];
  width: number;
  height: number;
  interval: number; // interval in seconds
  startTime: Date;
  endTime: Date;
  selectedTime?: Date;
  onClick?: (date: Date) => void;
  onReset?: () => void;
}

interface HistogramData extends VehicleCounts {
  time: string;
  fullName: string;
  startTime: Date;
  endTime: Date;
  grouping: number;
}

interface CongestionData {
  time: string;
  congestion: number;
  alarm: number;
  startTime?: Date;
  endTime?: Date;
}

const fillZero: (n: number) => string = n => ('0' + n).slice(-2);
const getHoursFromDate: (date: Date) => string = date => date.getHours() + ':' + fillZero(date.getMinutes());
const getDateFromDate: (date: Date) => string = date => date.getFullYear() + '-' + fillZero(date.getMonth() + 1) + '-' + fillZero(date.getDate());
const getShortDateFromDate: (date: Date) => string = date => date.getFullYear() + '-' + fillZero(date.getMonth() + 1) + '-' + fillZero(date.getDate()) + ' ' + getHoursFromDate(date);

interface TooltipPayload {
  dataKey: string;
  value: number;
  payload: HistogramData;
}

interface CustomTooltipProps {
  active?: boolean;
  payload?: TooltipPayload[];
  label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const total = payload.reduce((v: number, p: any) => v + p.value, 0);
    const startTime = payload[0].payload.startTime;
    const endTime = payload[0].payload.endTime;
    return (
      <Box sx={{ background: 'white', border: '1px solid black', borderRadius: '3px', padding: '0.25rem' }}>
        <Typography sx={{ fontSize: 'small', lineHeight: 1 }}>{getDateFromDate(startTime)}</Typography>
        <Typography sx={{ fontSize: 'small', lineHeight: 1 }}>{getHoursFromDate(startTime)} &ndash; {getHoursFromDate(endTime)}</Typography>
        <Typography sx={{ fontSize: 'small', lineHeight: 1 }}>Total: {total}</Typography>
        {payload.map(
          p => <Typography
            key={p.dataKey}
            sx={{ fontSize: 'small', lineHeight: 1 , color: VEHICLE_CLASS_COLORS[p.dataKey as keyof typeof VEHICLE_CLASS_COLORS] }}
          >
            {p.dataKey}: {p.value}
          </Typography>
        )}
      </Box>
    );
  }
  return null;
};


const Histogram: React.FC<HistogramProps> = ({
  detections,
  startTime,
  endTime,
  interval,
  width,
  height,
  selectedTime,
  onClick,
  onReset
}) => {
  const [focusIndex, setFocusIndex] = useState<number | null>(null);

  const startValue = startTime.valueOf();
  const endValue = endTime.valueOf();
  let currentValue = startValue;

  const data: {[key: number]: HistogramData} = {};
  const congestion: {[key: number]: CongestionData} = {};

  let i = 0;
  while (currentValue < endValue) {
    let currentTime = new Date(currentValue);
    const time = getHoursFromDate(currentTime);
    const fullName = getShortDateFromDate(currentTime);
    data[i] = {
      time,
      fullName,
      startTime: currentTime,
      endTime: new Date(currentValue + interval * 1000),
      grouping: i,
      ...getDefaultVehicleCounts()
    };
    congestion[i] = {
      time,
      congestion: 0,
      alarm: 0
    };
    ++i;
    currentValue += interval * 1000;
  }
  const counts = getDefaultVehicleCounts();
  detections.forEach(
    d => {
      const grouping = Math.floor((d.start_time.valueOf() - startValue) / 1000 / interval);
      if (data[grouping]) {
        Object.keys(VEHICLE_CLASS_COLORS).forEach(
          vc => {
            if (d.vehicle_class === vc) {
              data[grouping][vc as keyof VehicleCounts] += 1;
              counts[vc as keyof VehicleCounts] += 1;
              return;
            }
          }
        );
      }
      if (congestion[grouping]) {
        if (d.incident) {
          congestion[grouping].congestion += 1;
        }
        if (d.alarm) {
          congestion[grouping].alarm += 1;
        }
      }
    }
  );

  const total = counts.Truck + counts.Car + counts.Bus + counts.Motorcycle;

  const onMouseMove = (state: any) => {
    if (state.isTooltipActive) {
      setFocusIndex(state.activeTooltipIndex);
    } else {
      setFocusIndex(null);
    }
  };

  const handleClick = () => {
    if (onClick && focusIndex !== null &&!!data[focusIndex]) {
      onClick(data[focusIndex].startTime);
    }
  };

  const Background = (props:any) => {
    if (selectedTime && selectedTime >= props.startTime && selectedTime <= props.endTime) {
      return <Rectangle {...props} />;
    }
    return null;
  }
  return (
    <React.Fragment>
      <Typography>
        {Object.keys(VEHICLE_CLASS_COLORS).filter(vc => vc !== 'Vehicle').map(
          vc => <span key={vc} style={{ marginRight: '1rem', color: VEHICLE_CLASS_COLORS[vc as keyof typeof VEHICLE_CLASS_COLORS] }}>{vc}: {counts[vc as keyof typeof VEHICLE_CLASS_COLORS]}</span>
        )}
        Total: {total}
      </Typography>
      <BarChart
        width={width}
        height={20}
        data={Object.values(congestion)}
        margin={{ left: 65, right: 5, bottom: 0, top: 0 }}
        barGap={0}
        barCategoryGap={0}
      >
        <Bar
          dataKey="congestion"
          fill="green"
          legendType="none"
          shape={({x, y, width, height, fill}) => <Rectangle x={x} width={width * 2} height={height} y={y + height / 2 - 10} fill={fill} />}
        />
        <Bar
          dataKey="alarm"
          fill="red"
          legendType="none"
          shape={({x, y, width, height, fill}) => <Rectangle x={x - width} width={width * 2} height={height} y={y + height / 2 - 10} fill={fill} />}
        />
      </BarChart>
      <BarChart
        width={width}
        height={height}
        data={Object.values(data)}
        onMouseMove={onMouseMove}
        onClick={handleClick}
      >
        <XAxis dataKey="time" />	  
        <YAxis allowDecimals={false} />
        <Tooltip content={<CustomTooltip />} />
        {Object.keys(VEHICLE_CLASS_COLORS).filter(vc => vc !== 'Vehicle').map(
          (vc, i) => <Bar
            key={vc}
            dataKey={vc}
            stackId="grouping"
            background={i === 0 && <Background />}
            fill={VEHICLE_CLASS_COLORS[vc as keyof typeof VEHICLE_CLASS_COLORS]}
          />
        )}
      </BarChart>
    </React.Fragment>
  );
};

export default Histogram;

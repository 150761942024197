import React from 'react';

import Box from '@mui/material/Box';

import Processors from '../views/Processors';
import Cameras from '../views/Cameras';
import Lanes from '../views/Lanes';
import Processes from '../views/NewProcesses';
import ViewConfigs from '../views/ViewConfigs';

import { isSuperuser } from '../utils/auth';

interface Props {
	siteId: number
}

const SetupView: React.FC<Props> = ({ siteId }) => {
	return (
		<>
      <Box sx={{ flex: '1 1 0', minWidth: '40%' }}>
			  <Processors siteId={siteId} />
      </Box>
      <Box sx={{ flex: '1 1 0', minWidth: '40%' }}>
			  <Cameras siteId={siteId} />
      </Box>
      <Box sx={{ flex: '1 1 0', minWidth: '40%' }}>
			  <Lanes siteId={siteId} />
      </Box>
      <Box sx={{ flex: '1 1 0', minWidth: '40%' }}>
			  <Processes siteId={siteId} />
      </Box>
      {isSuperuser() && <Box sx={{ flex: '1 1 0', minWidth: '100%' }}>
			  <ViewConfigs siteId={siteId} view_type="live" />
      </Box>}
      {isSuperuser() && <Box sx={{ flex: '1 1 0', minWidth: '100%' }}>
			  <ViewConfigs siteId={siteId} view_type="recorded" />
      </Box>}
		</>
	);
};

export default SetupView;

import React from 'react';
import { useQuery } from "react-query";
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { Site } from "../types";
import { getSite } from "../utils/api";

interface VehicleSubClassSelectProps {
  siteId: number;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const VehicleSubClassSelect: React.FC<VehicleSubClassSelectProps> = ({ siteId, value, onChange }) => {
  const { isLoading, isError, data: site } = useQuery<Site, Error>(
    ['sites', siteId],
    () => getSite(siteId),
    { enabled: siteId !== -1 }
  );

  const hasVehicleSubClass = site && Array.isArray(site.vehicle_subclasses) && site.vehicle_subclasses.length > 0;
  const subclassNames = hasVehicleSubClass ? ["0", ...site.vehicle_subclasses.map((subclass) => subclass.name)] : [];

  return (
    <TextField
      select
      label="Toll Class"
      value={value}
      onChange={onChange}
      sx={{ minWidth: '10rem', mr: '0.5rem' }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
              overflow: 'auto',
            },
          },
        },
      }}
    >
      <MenuItem value="all">All</MenuItem>
      {subclassNames.map((subclassName) => (
        <MenuItem key={subclassName} value={subclassName}>
          {subclassName}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default VehicleSubClassSelect;

// export const BASE_URL: string = 'http://axles.aerialmachinelearningsystems.com';
export const BASE_URL: string = 'https://vms.avenewai.com';
export const BACKEND_URL: string = BASE_URL + '/api/v1';
export const ASSET_URL = 'https://s3.ca-central-1.amazonaws.com/axle-images.aerialmachinelearningsystems.com';

export const VEHICLE_CLASS_COLORS = {
  Truck: '#f48727',
  Car: '#1a611a',
  Bus: '#0097a7',
  Motorcycle: '#0e3c4d',
  Vehicle: '#040c0f'
};


export const COLOR_AXLE_MISMATCH = '#B0FAB4';
export const COLOR_NO_DETECTION = '#FFBCCB';
export const COLOR_NO_OBSERVATION = 'lightblue';
export const COLOR_VERIFIED_DETECTION = '#8DFD8D';
export const COLOR_UNVERIFIED_DETECTION = '#FFD966';
export const COLOR_MISSED_DETECTION = '#F77C7C';
export const COLOR_CONFIDENCE_PERCENTAGE_INVALID = 'black';
export const COLOR_CONFIDENCE_PERCENTAGE_UNDER_90 = '#6495ED';
export const COLOR_CONFIDENCE_PERCENTAGE_UNDER_95 = '#E6A800';
export const COLOR_CONFIDENCE_PERCENTAGE_OTHERS = '#4CAF50';


export const LIVE_VIEW_REFRESH_INTERVAL = 5 * 1000;
export const PRODUCTION_VIEW_REFRESH_INTERVAL = 60 * 1000;

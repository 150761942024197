import React from 'react';

import { CameraConfig } from '../types';

interface CameraConfigDisplayProps {
	config: CameraConfig;
}

const CameraConfigDisplay: React.FC<CameraConfigDisplayProps> = ({ config }) => {
	if (!config) {
		return null;
	}
	return (
	  <React.Fragment>
	  	{config.x_resolution}x{config.y_resolution}&nbsp;({config.fps}fps)
	  </React.Fragment>
	);
};

export default CameraConfigDisplay;

import React, { useEffect, useRef, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Download from '@mui/icons-material/Download';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import NextFrame from '@mui/icons-material/FastForward';
import PrevFrame from '@mui/icons-material/FastRewind';
import Slider from '@mui/material/Slider';

interface Props {
  url: string;
  rawUrl?: string;
  trainingUrl?: string;
  fps: number;
  frame: number;
  width?: number;
  height?: number;
  getDownloadFileName?: (time: number) => string;
  getTrainingFileName?: () => string;
}

const DEFAULT_WIDTH = 640;
const DEFAULT_HEIGHT = 360;

const VideoPlayer: React.FC<Props> = ({ url, frame, fps, rawUrl, trainingUrl, width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT, getDownloadFileName, getTrainingFileName }) => {
  const playerEl = useRef<HTMLVideoElement>(null);
  const [percentDone, setPercentDone] = useState<number>(0);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  useEffect(() => {
    playerEl.current?.load();
  }, [url]);

  useEffect(() => {
    playerEl.current!.currentTime = frame / fps;
  }, [frame, fps]);

  const handleProgress = (e: Event) => {
    const target = e.currentTarget! as HTMLVideoElement;
    const duration = target.duration;
    const time = target.currentTime;
    if (duration) {
      const percentage = (100 / duration) * time;
      setPercentDone(percentage);
      if (time === duration) {
        setPlaying(false);
      }
    }
  };

  useEffect(() => {
    const current = playerEl.current!;
    current.addEventListener('timeupdate', handleProgress);
    return () => current.removeEventListener('timeupdate', handleProgress);
  }, []);

  useEffect(() => {
    isPlaying ? playerEl.current!.play() : playerEl.current!.pause();
  }, [isPlaying]);

  const handleClickPlay = () => {
    setPlaying(true);
  };

  const handleClickPause = () => {
    setPlaying(false);
  };

  const handleClickPlayer = () => {
    setPlaying(!isPlaying);
  };

  const handleClickNextFrame = () => {
    const frameTime = 1 / fps;
    playerEl.current!.currentTime = Math.min(
      playerEl.current!.duration,
      playerEl.current!.currentTime + frameTime
    );
  };

  const handleClickPreviousFrame = () => {
    const frameTime = 1 / fps;
    playerEl.current!.currentTime = Math.max(
      0,
      playerEl.current!.currentTime - frameTime
    );
  };

  const handleClickProgress = (_: any, value: any) => {
    setPercentDone(value as number);
    playerEl.current!.currentTime = ((value as number) / 100) * playerEl.current!.duration;
  };

  const handleClickDownloadTraining = () => {
    if (!!trainingUrl) {
      const a = document.createElement('a');
      const filename = getTrainingFileName ? getTrainingFileName() : 'training.tgz';
      a.setAttribute('href', trainingUrl);
      a.setAttribute('download', filename);
      a.click();
    }
  };

  const handleClickDownloadFrame = () => {
    const time = playerEl.current!.currentTime;
    const video = document.createElement('video');
    video.width = playerEl.current!.clientWidth;
    video.height = playerEl.current!.clientHeight;
    video.crossOrigin = 'anonymous';
    const handleDownload = () => {
      video.removeEventListener('canplaythrough', handleDownload);
      const canvas = document.createElement('canvas');
      canvas.width = video.width;
      canvas.height = video.height;
      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const frameUrl = canvas.toDataURL('image/jpeg');
        const a = document.createElement('a');
        const filename = getDownloadFileName ? getDownloadFileName(time) : 'test.jpg';
        console.log(filename);
        a.setAttribute('href', frameUrl);
        a.setAttribute('download', filename);
        a.click();
      }
    }
    video.addEventListener('seeked', handleDownload);
    video.src = rawUrl ?? url;
    if (video.currentTime === time) {
      handleDownload();
    } else {
      video.currentTime = time;
    }
  };

  const handleClickDownload = () => {
    if (!!trainingUrl) {
      handleClickDownloadTraining();
    } else if (!!rawUrl) {
      handleClickDownloadFrame();
    }
  };

  return (
    <div style={{ width }}>
      <video
        ref={playerEl}
        onClick={handleClickPlayer}
        width={width}
        height={height}
      >
        <source src={url} type="video/mp4" />
      </video>
      <div style={{ display: 'flex' }}>
        {isPlaying && <IconButton onClick={handleClickPause}><Pause /></IconButton>}
        {!isPlaying && <IconButton onClick={handleClickPlay}><PlayArrow /></IconButton>}
        <IconButton onClick={handleClickPreviousFrame}><PrevFrame /></IconButton>
        <IconButton onClick={handleClickNextFrame}><NextFrame /></IconButton>
        <IconButton style={{ marginRight: '1rem', color: !!trainingUrl ? '#1976d2' : undefined }} disabled={isPlaying} onClick={handleClickDownload}><Download /></IconButton>
        <Slider min={0} max={100} value={percentDone} onChange={handleClickProgress} />
      </div>
    </div>
  );
};

export default VideoPlayer;

import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

import SiteList from '../components/SiteList';

import { createSite } from '../utils/api';
import { SiteType } from '../types';

const Sites = () => {
	const [adding, setAdding] = useState<boolean>(false);
	const [name, setName] = useState<string>('');
	const [siteType, setSiteType] = useState<SiteType>('ADS');
	const queryClient = useQueryClient();
	const addSite = useMutation(createSite, {
		onSuccess: () => {
			queryClient.invalidateQueries('sites');
		}
	});

	const handleClickAdd = () => {
		setAdding(true);
		setName('');
	};

	const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value);
	const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => setSiteType('ADS' === event.target.value ? 'ADS' : 'TFM');
	const handleCreate = () => {
		addSite.mutate({ name, type: siteType, timezone: 'America/Halifax' });
		setAdding(false);
	}

	return (
		<Card>
			<CardHeader
				title={adding ? "Create Site" : "Sites"}
				action={
					!adding && <IconButton aria-label="Create Site" onClick={handleClickAdd}>
						<AddIcon />
					</IconButton>
				}
			/>
			<CardContent>
				{adding ?
					<React.Fragment>
						<div>
							<TextField variant="standard" label="Site Name" onChange={handleChangeName} value={name} />
						</div>
						<div>
							<TextField select variant="standard" onChange={handleChangeType} value={siteType}>
								<MenuItem value="ADS">ADS</MenuItem>
								<MenuItem value="TFM">TFM</MenuItem>
							</TextField>
						</div>
					</React.Fragment>
				 :
				<SiteList />}
      			</CardContent>
			{adding && <CardActions>
        			<Button onClick={handleCreate}>Create</Button>
				<Button onClick={() => setAdding(false)}>Cancel</Button>
			</CardActions>}
		</Card>
	);
};

export default Sites;

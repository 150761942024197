import React, { useState} from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import QrCode from 'react-qr-code';

import Header from '../views/Header';

import { setupMFA } from '../utils/auth';
import { APIError } from '../utils/api';

const theme = createTheme();

const MultifactorAuthenticationSetup: React.FC<{}> = () => {
  const [done, setDone] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [mfaUrl, setMfaUrl] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    const data = new FormData(event.currentTarget);
    try {
      const result = await setupMFA(data.get('password') as string);
      if (result) {
        setDone(true);
        setMfaUrl(result);
      } else {
        setDone(false);
        setMfaUrl('');
      }
    } catch (err) {
      setDone(false);
      if (err instanceof APIError) {
        setError(err.details.detail);
      } else if (err instanceof Error) {
        setError(err.message);
      } else {
        setError(err as string);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header>Multi-Factor Authentication</Header>
      {done ?
      <Container>
        <Box>
          <Typography sx={{ mt: 2, mb: 2 }}>
            Please scan the QR code in your authenticator app.
            If you previously had MFR set up on your account, it has now been replaced by this one.
          </Typography>
          <Box>
            <QrCode value={mfaUrl} size={200} />
          </Box>
        </Box>
      </Container> :
      <Container>
        <Box>
          <Typography sx={{ mt: 2, mb: 2 }}>
            Please enter your password to setup or change the MFA for your account
          </Typography>
          {error && <Alert sx={{ mb: 2 }}>{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>}
    </ThemeProvider>
  );
};

export default MultifactorAuthenticationSetup;

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';

import VideoPlayer from '../VideoPlayer';
import DetectionCard from '../DetectionCard';
import { ViewDetection, Detection, Direction } from '../../types';
import { getRecentDetectionsByView } from '../../utils/api';
import { getDefaultInterval, getDuration, displayDuration, formatDate } from '../../utils';

import Histogram from './Histogram';
import Stats from './Stats';

import { ASSET_URL } from '../../config';


interface ViewProps {
  view: ViewDetection;
  direction: Direction;
  duration: number;
  interval: number; // in seconds
  width: number;
  height: number;
  endTime?: Date;
}

const getBaseEndTime: (interval: number, utcOffset?: number, endTime?: Date) => Date = (interval, utcOffset = 0, endTime) => {
  const now = new Date();
  return new Date(Math.ceil(((endTime ?? now).valueOf() + utcOffset * 1000 + (endTime ?? now).getTimezoneOffset() * 60000) / interval / 1000) * interval * 1000);
};

const REFRESH_INTERVAL = 5000; // in ms

const View: React.FC<ViewProps> = ({
  view,
  direction,
  duration,
  interval,
  width,
  height,
  endTime
}) => {
  const [until, setUntil] = useState<Date>(getBaseEndTime(interval, view.utc_offset, endTime));
  const [since, setSince] = useState<Date>(new Date(until.valueOf() - duration * 60 * 60 * 1000));
  const [start, setStart] = useState<Date>(since);
  const [end, setEnd] = useState<Date>(until);
  const [detections, setDetections] = useState<Detection[]>([]);
  const [detection, setDetection] = useState<Detection | null>(null);
  const [currentInterval, setCurrentInterval] = useState<number>(interval);

  const lastStartTime = detections.length > 0 ? detections[detections.length - 1].start_time : since;

  const { data } = useQuery<Detection[], Error>(
    ['recent', view.id, lastStartTime.valueOf(), endTime?.valueOf() ?? ''],
    () => {
      const recentSince = lastStartTime === since ? (duration < 24 ? (new Date(until.valueOf() - duration * 60 * 60 * 1000)) : since) : lastStartTime;
      return getRecentDetectionsByView(view.id, recentSince, endTime);
    },
    {
      refetchInterval: !!endTime ? false : REFRESH_INTERVAL
    }
  );

  useEffect(() => {
    const newUntil = getBaseEndTime(interval, view.utc_offset, endTime);
    setUntil(newUntil);
    setSince(new Date(newUntil.valueOf() - duration * 60 * 60 * 1000));
    setCurrentInterval(interval);
  }, [duration, interval, view.utc_offset, endTime]);

  useEffect(() => {
    if (data && data.length) {
      const newDetections = data.filter(d => d.start_time > lastStartTime);
      if (newDetections.length) {
        const lastDetection = newDetections[newDetections.length - 1];
        if (lastDetection.start_time > until) {
            const newUntil = new Date(Math.ceil(lastDetection.start_time.valueOf() / interval / 1000) * interval * 1000);
            setUntil(newUntil);
            // TODO: Adjust start and end to track until if not zoomed
        }
        setDetections(detections.concat(newDetections));
        if (!detection && data.length) {
          setDetection(data[data.length - 1]); 
        }
      }
    }
  }, [data, detection, detections, interval, lastStartTime, until]);

  const title = `${direction}: ${view.name} - ${view.camera_name} - ${view.lane_name}`;

  const onClick: (date: Date) => void = (date) => {
    const currentDuration = end.valueOf() - start.valueOf();
    // If we are working with 1 hour, then select a vehicle
    if (currentDuration <= 3600000) {
      const newEnd = new Date(date.valueOf() + currentInterval * 1000);
      const selected = detections.filter(d => d.start_time >= date && d.start_time <= newEnd)[0];
      if (selected) {
        setDetection(selected);
      }
    } else if (currentDuration > 24 * 60 * 60 * 1000) {
      // new duration = 24 hours
      let newEnd = new Date(date.valueOf() + 24 * 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 24 * 60 * 60 * 1000);
      const newInterval = Math.min(currentInterval, getDefaultInterval(24));
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    } else {
      // new duration = 1 hour
      let newEnd = new Date(date.valueOf() + 60 * 60 * 1000);
      if (newEnd > until) {
        newEnd = until;
      }
      const newStart = new Date(newEnd.valueOf() - 60 * 60 * 1000);
      const newInterval = 60;
      setStart(newStart);
      setEnd(newEnd);
      setCurrentInterval(newInterval);
    }
  };

  const onReset: () => void = () => {
    setEnd(until);
    setStart(new Date(until.valueOf() - duration * 60 * 60 * 1000));
    setCurrentInterval(interval);
  };

  const onPreviousDetection = () => {
    if (!!detection) {
      const index = detections.indexOf(detection);
      if (index > 0) {
        const newDetection = detections[index - 1];
        if (newDetection) {
          if (newDetection.start_time < start) {
            let newStart = new Date(Math.floor(newDetection.start_time.valueOf() / currentInterval / 1000) * currentInterval * 1000);
            if (newStart < since ) {
              newStart = since;
            }
            let newEnd = new Date(newStart.valueOf() + (end.valueOf() - start.valueOf()));
            setStart(newStart);
            setEnd(newEnd);
          }
          setDetection(newDetection);
        }
      }
    }
  };
  const onNextDetection = () => {
    if (!!detection) {
      const index = detections.indexOf(detection);
      if (index > -1 && index < detections.length - 1) {
        const newDetection = detections[index + 1];
        if (newDetection) {
          if (newDetection.start_time > end) {
            let newEnd = new Date(Math.ceil(newDetection.start_time.valueOf() / currentInterval / 1000) * currentInterval * 1000);
            if (newEnd > until) {
              newEnd = until;
            }
            let newStart = new Date(newEnd.valueOf() - (end.valueOf() - start.valueOf()));
            setStart(newStart);
            setEnd(newEnd);
          }
          setDetection(newDetection);
        }
      }
    }
  };

  const isZoomed = interval !== currentInterval || start.valueOf() !== since.valueOf() || end.valueOf() !== until.valueOf();
  const hasPreviousDetection = !!detection && detections.indexOf(detection) > 0;
  const hasNextDetection = !!detection && detections.indexOf(detection) < (detections.length - 1);

  return (
    <Card>
      <CardHeader title={title} />
      <CardContent>
        {detections.length > 0 &&  <Typography>
          Most Recent Wait Time: {displayDuration(getDuration(detections[detections.length - 1]))} at {formatDate(detections[detections.length - 1].start_time)}
        </Typography>}
        {detection ?
          <Grid container>
            <Grid item sx={{ m: 1 }}>
              <VideoPlayer
                key={detection.id}
                url={`${ASSET_URL}/${detection.process_id}/snippet-${detection.vehicle_number}.mp4`}
                rawUrl={`${ASSET_URL}/${detection.process_id}/raw-snippet-${detection.vehicle_number}.mp4`}
                trainingUrl={!!detection.has_training_images ? `${ASSET_URL}/${detection.process_id}/training-${detection.vehicle_number}.tgz` : undefined}
                fps={30}
                frame={0}
                width={320}
                height={180}
              />
            </Grid>
            <Grid item sx={{ m: 1 }}>
              <DetectionCard detection={detection} showVehicleNumber={true} />
            </Grid>
            <Grid item sx={{ m: 1 }}>
              <Stats detections={detections} currentTime={until} longestDetection={view.longest_detection} stats={view.stats} />
            </Grid>
          </Grid> :
          <Typography>No detection selected</Typography>}
        <Box>
          {isZoomed && <IconButton aria-label="Reset" onClick={onReset}><CancelIcon /></IconButton>}
          {hasPreviousDetection && <Button onClick={onPreviousDetection}>Previous</Button>}
          {hasNextDetection && <Button onClick={onNextDetection}>Next</Button>}
        </Box>
        <Histogram
          detections={Object.values(detections).filter(d => d.start_time >= start && d.start_time < end)}
          width={width}
          height={height}
          interval={currentInterval}
          startTime={start}
          endTime={end}
          selectedTime={detection ? detection.start_time : undefined}
          onClick={onClick}
        />
      </CardContent>
    </Card>
  );
};

export default View;

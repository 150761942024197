import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import Stack from '@mui/material/Stack';

import { Detection, ViewDetection, EdgeProcessConfig } from '../types';
import { getLiveDetections } from '../utils/api';

import ViewDetectionDetail from '../components/ViewDetectionDetail';

import useInterval from '../hooks/useInterval';

import { LIVE_VIEW_REFRESH_INTERVAL } from '../config';

interface Props {
	siteId: number;
  processConfigs: {[id: number]: EdgeProcessConfig };
}

interface ExpandedDetections {
  [id: number]: boolean;
}

const LiveView: React.FC<Props> = ({ siteId, processConfigs }) => {
  const [expanded, setExpanded] = useState<ExpandedDetections>({} as ExpandedDetections);
	const queryClient = useQueryClient();
	const { data: views } = useQuery<ViewDetection[], Error>(['view_detections', siteId], () => {
		return getLiveDetections(siteId)
  });

  const onExpanded: (d: Detection, ex: boolean) => void = (d, ex) => {
    setExpanded({ ...expanded, [d.id]: ex });
  }

	// invalidate query every 30 seconds
	useInterval(() => {
    if (!Object.values(expanded).some(x => x)) {
      queryClient.invalidateQueries(['view_detections', siteId])
    }
  }, LIVE_VIEW_REFRESH_INTERVAL);

	return (
		<Stack spacing={1}>
			{views && views.sort((a, b) => a.name.localeCompare(b.name)).map(v => <ViewDetectionDetail key={v.id} view={v} processConfigs={processConfigs} onExpanded={onExpanded} />)}
		</Stack>
	);
};

export default LiveView;

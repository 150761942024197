import React from 'react';
import { useHref, useLocation, useNavigate } from 'react-router-dom';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Detection, ViewStats } from '../../types';
import { formatDate, formatAsDate, getDuration, displayDuration } from '../../utils';

interface StatsProps {
  detections: Detection[];
  currentTime: Date;
  longestDetection?: Detection;
  stats?: ViewStats;
}

const getDurationMode: (detections: Detection[]) => [number, number] = detections => {
  let mode = 0;
  let modeCount = 0;

  let curValue = 0;
  let curCount = 0;
  detections.forEach(
    d => {
      let duration = getDuration(d);
      if (duration === curValue) {
        curCount++;
      } else {
        if (curCount > modeCount) {
          mode = curValue;
          modeCount = curCount;
	}
        curValue = duration;
        curCount = 1;
      }
    }
  );
  if (curCount > modeCount) {
    mode = curValue;
    modeCount = curCount;
  }
  return [mode, modeCount];
};

const getDetectionPercentile: (detections: Detection[], percentile: number) => Detection | undefined = (detections, percentile) => {
  if (detections.length === 0) {
    return undefined;
  }
  const detection = detections[Math.min(Math.ceil(percentile * detections.length), detections.length - 1)];
  if (detection) {
    return detection;
  }
  return undefined;
};


const Stats: React.FC<StatsProps> = ({
  detections,
  currentTime,
  longestDetection,
  stats
}) => {
  const location = useLocation();
  const href = useHref(location);
  const navigate = useNavigate();
  if (!stats) {
    return null;
  }

  const startTimeMidnight = new Date(formatAsDate(currentTime) + ' 00:00:00');
  const startTime24hour = new Date(currentTime.valueOf() - 24 * 60 * 60 * 1000);
  const startTime12hour = new Date(currentTime.valueOf() - 12 * 60 * 60 * 1000);
  const startTime1hour = new Date(currentTime.valueOf() - 60 * 60 * 1000);
  const startTime30minutes = new Date(currentTime.valueOf() - 30 * 60 * 1000);

  const detectionsMidnight = detections.filter(d => d.start_time >= startTimeMidnight);
  const detections24hour = detections.filter(d => d.start_time >= startTime24hour);
  const detections12hour = detections.filter(d => d.start_time >= startTime12hour);
  const detections1hour = detections.filter(d => d.start_time >= startTime1hour);
  const detections30minutes = detections.filter(d => d.start_time >= startTime30minutes);

  const averageMidnight = detectionsMidnight.length > 0 ? detectionsMidnight.reduce((total, d) => total + getDuration(d), 0) / detectionsMidnight.length : 0;
  const average24hour = detections24hour.length > 0 ? detections24hour.reduce((total, d) => total + getDuration(d), 0) / detections24hour.length : 0;
  const average12hour = detections12hour.length > 0 ? detections12hour.reduce((total, d) => total + getDuration(d), 0) / detections12hour.length : 0;
  const average1hour = detections1hour.length > 0 ? detections1hour.reduce((total, d) => total + getDuration(d), 0) / detections1hour.length : 0;
  const average30minutes = detections30minutes.length > 0 ? detections30minutes.reduce((total, d) => total + getDuration(d), 0) / detections30minutes.length : 0;

  detectionsMidnight.sort((a, b) => getDuration(b) - getDuration(a));
  detections24hour.sort((a, b) => getDuration(b) - getDuration(a));
  detections12hour.sort((a, b) => getDuration(b) - getDuration(a));
  detections1hour.sort((a, b) => getDuration(b) - getDuration(a));
  detections30minutes.sort((a, b) => getDuration(b) - getDuration(a));

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const [modeMidnight, modeCountMidnight] = getDurationMode(detectionsMidnight);
  const [mode24hour, modeCount24hour] = getDurationMode(detections24hour);
  const [mode12hour, modeCount12hour] = getDurationMode(detections12hour);
  const [mode1hour, modeCount1hour] = getDurationMode(detections1hour);
  const [mode30minutes, modeCount30minutes] = getDurationMode(detections30minutes);
  /* eslint-enable */

  const percentile90_Midnight = getDetectionPercentile(detectionsMidnight, 0.1);
  const percentile90_24hour = getDetectionPercentile(detections24hour, 0.1);
  const percentile90_12hour = getDetectionPercentile(detections12hour, 0.1);
  const percentile90_1hour = getDetectionPercentile(detections1hour, 0.1);
  const percentile90_30minutes = getDetectionPercentile(detections30minutes, 0.1);

  const onClick: (detection?: Detection) => () => void = detection => () => {
    if (detection) {
      navigate(`/detections/${detection.id}?return=${encodeURIComponent(href + '#detection-' + detection.id)}`);
    }
  };
  return (
    <Table size="small" sx={{
     '& td': { fontSize: '0.5em', padding: '0 0.25rem' },
     '& th': { fontSize: '0.5em', padding: '0 0.25rem' }
    }}>
      <TableHead>
        <TableRow>
          <TableCell>&nbsp;</TableCell>
          <TableCell>Longest Wait Time</TableCell>
          <TableCell>90th Percentile Longest Wait Time</TableCell>
          <TableCell>Average Wait Time</TableCell>
          <TableCell>Shortest Wait Time</TableCell>
          <TableCell>Mode Time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell rowSpan={2}>All Time</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(stats.longest)}>{stats.longest && displayDuration(getDuration(stats.longest))}</TableCell>
          <TableCell>{displayDuration(stats.percentile_90)}</TableCell>
          <TableCell>{displayDuration(stats.mean)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(stats.shortest)}>{stats.shortest && displayDuration(getDuration(stats.shortest))}</TableCell>
          <TableCell>{displayDuration(stats.mode)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(stats.longest)}>{stats.longest && formatDate(stats.longest.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(stats.shortest)}>{stats.shortest && formatDate(stats.shortest.start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2}>Current Day (Since Midnight)</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detectionsMidnight[0])}>{detectionsMidnight[0] && displayDuration(getDuration(detectionsMidnight[0]))}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_Midnight)}>{percentile90_Midnight && displayDuration(getDuration(percentile90_Midnight))}</TableCell>
          <TableCell>{displayDuration(averageMidnight)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detectionsMidnight[detectionsMidnight.length - 1])}>{detectionsMidnight[0] && displayDuration(getDuration(detectionsMidnight[detectionsMidnight.length - 1]))}</TableCell>
          <TableCell>{displayDuration(modeMidnight)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detectionsMidnight[0])}>{detectionsMidnight[0] && formatDate(detectionsMidnight[0].start_time)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_Midnight)}>{percentile90_Midnight && formatDate(percentile90_Midnight.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detectionsMidnight[detectionsMidnight.length - 1])}>{detectionsMidnight[0] && formatDate(detectionsMidnight[detectionsMidnight.length - 1].start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2}>Current Day (Past 24 Hours)</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections24hour[0])}>{detections24hour[0] && displayDuration(getDuration(detections24hour[0]))}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_24hour)}>{percentile90_24hour && displayDuration(getDuration(percentile90_24hour))}</TableCell>
          <TableCell>{displayDuration(average24hour)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections24hour[detections24hour.length - 1])}>{detections24hour[0] && displayDuration(getDuration(detections24hour[detections24hour.length - 1]))}</TableCell>
          <TableCell>{displayDuration(mode24hour)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections24hour[0])}>{detections24hour[0] && formatDate(detections24hour[0].start_time)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_24hour)}>{percentile90_24hour && formatDate(percentile90_24hour.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections24hour[detections24hour.length - 1])}>{detections24hour[0] && formatDate(detections24hour[detections24hour.length - 1].start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2}>Current Day (Past 12 Hours)</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections12hour[0])}>{detections12hour[0] && displayDuration(getDuration(detections12hour[0]))}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_12hour)}>{percentile90_12hour && displayDuration(getDuration(percentile90_12hour))}</TableCell>
          <TableCell>{displayDuration(average12hour)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections12hour[detections12hour.length - 1])}>{detections12hour[0] && displayDuration(getDuration(detections12hour[detections12hour.length - 1]))}</TableCell>
          <TableCell>{displayDuration(mode12hour)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections12hour[0])}>{detections12hour[0] && formatDate(detections12hour[0].start_time)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_12hour)}>{percentile90_12hour && formatDate(percentile90_12hour.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections12hour[detections12hour.length - 1])}>{detections12hour[0] && formatDate(detections12hour[detections12hour.length - 1].start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2}>Current Day (Past 1 Hour)</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections1hour[0])}>{detections1hour[0] && displayDuration(getDuration(detections1hour[0]))}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_1hour)}>{percentile90_1hour && displayDuration(getDuration(percentile90_1hour))}</TableCell>
          <TableCell>{displayDuration(average1hour)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections1hour[detections1hour.length - 1])}>{detections1hour[0] && displayDuration(getDuration(detections1hour[detections1hour.length - 1]))}</TableCell>
          <TableCell>{displayDuration(mode1hour)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections1hour[0])}>{detections1hour[0] && formatDate(detections1hour[0].start_time)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_1hour)}>{percentile90_1hour && formatDate(percentile90_1hour.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections1hour[detections1hour.length - 1])}>{detections1hour[0] && formatDate(detections1hour[detections1hour.length - 1].start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        <TableRow>
          <TableCell rowSpan={2}>Current Day (Past 30 Minutes)</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections30minutes[0])}>{detections30minutes[0] && displayDuration(getDuration(detections30minutes[0]))}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_30minutes)}>{percentile90_30minutes && displayDuration(getDuration(percentile90_30minutes))}</TableCell>
          <TableCell>{displayDuration(average30minutes)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections30minutes[detections30minutes.length - 1])}>{detections30minutes[0] && displayDuration(getDuration(detections30minutes[detections30minutes.length - 1]))}</TableCell>
          <TableCell>{displayDuration(mode30minutes)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections30minutes[0])}>{detections30minutes[0] && formatDate(detections30minutes[0].start_time)}</TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(percentile90_30minutes)}>{percentile90_30minutes && formatDate(percentile90_30minutes.start_time)}</TableCell>
          <TableCell></TableCell>
          <TableCell sx={{ cursor: 'pointer', color: '#0097a7' }} onClick={onClick(detections30minutes[detections30minutes.length - 1])}>{detections30minutes[0] && formatDate(detections30minutes[detections30minutes.length - 1].start_time)}</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default Stats;

import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField, { TextFieldProps } from '@mui/material/TextField';

type Props = TextFieldProps & {
  label: string;
}

const ScoreCategorySelect: React.FC<Props> = ({ label, ...props}: Props) => {
  return (
    <TextField select label={label} sx={{ minWidth: '10rem', mr: '0.5rem' }} {...props}>
      <MenuItem value="any">Any</MenuItem>
      <MenuItem value="low">Low (&lt;= 90%) </MenuItem>
      <MenuItem value="medium">Medium (&gt; 90% to 95%)</MenuItem>
      <MenuItem value="high">High (&gt; 95%)</MenuItem>
    </TextField>
  );
};

export default ScoreCategorySelect;
